import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function updateInput({ name, value }) {
  return {
    type: constants.UPDATE_INPUT,
    name,
    value
  };
}

export function getPengumuman(id_pengumuman) {
  return {
    type: constants.GET_PENGUMUMAN,
    id_pengumuman
  };
}

export function getPengumumanFail(error) {
  return {
    type: constants.GET_PENGUMUMAN_FAIL,
    error
  };
}

export function getPengumumanSuccess(results) {
  console.log("pengumuman-detail:", results);
  return {
    type: constants.GET_PENGUMUMAN_SUCCESS,
    results
  };
}

export function submitPengumuman() {
  return {
    type: constants.SUBMIT_PENGUMUMAN
  };
}

export function submitPengumumanFail(error) {
  return {
    type: constants.SUBMIT_PENGUMUMAN_FAIL,
    error
  };
}

export function submitPengumumanSuccess(results) {
  console.log("sub-peg:", results);
  return {
    type: constants.SUBMIT_PENGUMUMAN_SUCCESS,
    results
  };
}

export function delSavedDetail(idx) {
  return {
    type: constants.DEL_SAVED_DETAIL,
    idx
  };
}

export function setSavedDetail() {
  return {
    type: constants.SET_SAVED_DETAIL
  };
}