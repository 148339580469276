export const DEFAULT_ACTION = 'screen/WasteAcceptanceFormDetailScreen/DEFAULT_ACTION';
export const UPDATE_INPUT = 'screen/WasteAcceptanceFormDetailScreen/UPDATE_INPUT';

export const GET_DETAIL = 'screen/WasteAcceptanceFormDetailScreen/GET_DETAIL';
export const GET_DETAIL_LOADING = 'screen/WasteAcceptanceFormDetailScreen/GET_DETAIL_LOADING';
export const GET_DETAIL_SUCCESS = 'screen/WasteAcceptanceFormDetailScreen/GET_DETAIL_SUCCESS';
export const GET_DETAIL_FAIL = 'screen/WasteAcceptanceFormDetailScreen/GET_DETAIL_FAIL';

export const ADD_IMAGE = 'screen/WasteAcceptanceFormDetailScreen/ADD_IMAGE';
export const DEL_IMAGE = 'screen/WasteAcceptanceFormDetailScreen/DEL_IMAGE';

export const SUBMIT_PENERIMAAN = 'screen/WasteAcceptanceFormDetailScreen/SUBMIT_PENERIMAAN';
export const SUBMIT_PENERIMAAN_LOADING = 'screen/WasteAcceptanceFormDetailScreen/SUBMIT_PENERIMAAN_LOADING';
export const SUBMIT_PENERIMAAN_SUCCESS = 'screen/WasteAcceptanceFormDetailScreen/SUBMIT_PENERIMAAN_SUCCESS';
export const SUBMIT_PENERIMAAN_FAIL = 'screen/WasteAcceptanceFormDetailScreen/SUBMIT_PENERIMAAN_FAIL';