/*
 *
 * UnitAnnounceFormScreen reducer
 *
 */
import {produce} from 'immer';
import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export const initialState = {
    error: 0,
    error_msg: '',
    loading: false,
    description: '',
    notes: '',
    id_pengumuman: 0,
    id_pengumuman_req: '',
    code_pengumuman: '',
    id_unit: '',
    id_lokasi: '',
    assets:[],
    saved_details: [],
    unit_announce: null,
};

/* eslint-disable default-case, no-param-reassign */
const UnitAnnounceFormScreenReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case DEFAULT_ACTION:
      console.log("reset state");
      return {...initialState};
      case constants.UPDATE_INPUT:
        draft[action.name] = action.value;
        break;
      case constants.SUBMIT_UNIT_ANNOUNCE:
        draft.loading = true;
        break;
      case constants.SUBMIT_UNIT_ANNOUNCE_FAIL:
        draft.error = true;
        draft.loading = false;
        draft.error_msg= 'Maaf, Hasil pencarian belum ditemukan.';
        break;
      case constants.SUBMIT_UNIT_ANNOUNCE_SUCCESS:
        draft.id_pengumuman = action.results.id_pengumuman;
        draft.loading = false;
        draft.error = false;
        draft.description = "";
        draft.notes = "";
        draft.assets = [];
        break;
      case constants.ADD_IMAGE:{
        let cur_arr = draft.assets;
        const image = action.asset;
        cur_arr.push({path: image});
        draft.assets = cur_arr;
      }
        break;
      case constants.DEL_IMAGE:
        draft.assets = [...draft.assets.slice(0, action.idx), ...draft.assets.slice(action.idx + 1)];
        break;
      case constants.GET_PENGUMUMAN_DETAIL:
        draft.loading = true;
        break;
      case constants.GET_PENGUMUMAN_DETAIL_FAIL:
        draft.error = true;
        draft.loading = false;
        draft.error_msg= 'Maaf, Gagal mengambil data pengumuman.';
        draft.results = [];
        break;
      case constants.GET_PENGUMUMAN_DETAIL_SUCCESS: {
        const {dtPengumumanUnit} = action.results;
        draft.unit_announce = dtPengumumanUnit;
        draft.notes = dtPengumumanUnit.noted;
        draft.id_unit = dtPengumumanUnit.id_unit;
        draft.code_pengumuman = dtPengumumanUnit?.code;

        let cur_saved_detail = [];
        dtPengumumanUnit?.pengumuman_unit_detal.map((raw, i) => {
          cur_saved_detail.push({
            id_jenis_limbah: raw.id_jenis_limbah,
            code_limbah: raw.code_limbah,
            nama_limbah: raw.ms_jns_limbah?.nama,
            jenis_limbah: raw.ms_jns_limbah,
            volume: raw.volume,
            berat: raw.berat,
            attachment: raw.peng_unit_attech,
            note: raw.note,
            status: raw.status
          });
        });
        draft.saved_details = cur_saved_detail;

        draft.loading = false;
        }
        break;
      case constants.DEL_SAVED_DETAIL:
        draft.saved_details = [...draft.saved_details.slice(0, action.idx), ...draft.saved_details.slice(action.idx + 1)];
        break;
    }
  });

export default UnitAnnounceFormScreenReducer;
