import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the WasteAcceptanceFormDetailScreen state domain
 */

const selectWasteAcceptanceFormDetailScreenDomain = state =>
  state.wasteAcceptanceFormDetailScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by WasteAcceptanceFormDetailScreen
 */

const makeSelectWasteAcceptanceFormDetailScreen = () =>
  createSelector(
    selectWasteAcceptanceFormDetailScreenDomain,
    substate => substate,
  );

export default makeSelectWasteAcceptanceFormDetailScreen;
export { selectWasteAcceptanceFormDetailScreenDomain };
