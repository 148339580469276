/*
 *
 * PostAnnounceFormScreen reducer
 *
 */
import {produce} from 'immer';
import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';
import moment from 'moment';

export const initialState = {
    error: 0,
    error_msg: '',
    loading: false,
    loading_submit: false,
    isChecked: false,
    notes: '',
    results: null,
    id_pengumuman: 0,
    post_date: new Date(),
    expired_date: new Date(),
    saved_details: [],
    post_announce: null,
};

/* eslint-disable default-case, no-param-reassign */
const PostAnnounceFormVendorScreenReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case DEFAULT_ACTION:
        return {...initialState};
      case constants.UPDATE_INPUT:
        draft[action.name] = action.value;
        break;
      case constants.GET_PENGUMUMAN:
        draft.loading = true;
        break;
      case constants.GET_PENGUMUMAN_FAIL:
        draft.error = true;
        draft.loading = false;
        draft.results = null;
        break;
      case constants.GET_PENGUMUMAN_SUCCESS:{
        draft.post_announce = action.results;
        draft.error = false;
        draft.expired_date = moment(action.results?.tgl_expired).toDate();
        draft.post_date = moment(action.results?.tgl_pengumuman).toDate();
        draft.notes = action.results?.noted;
        draft.loading = false;

        let cur_saved_detail = [];
        action.results?.peng_req_det.map((raw, i) => {
          cur_saved_detail.push({
            id_unit: raw.id_unit,
            nm_unit: raw.ms_unit_tujuan?.nama,
            id_lokasi: raw.id_lokasi,
            nm_lokasi: raw.lokasi?.nama,
            noted: raw.noted
          });
        });
        draft.saved_details = cur_saved_detail;
      }
        break;
      case constants.SUBMIT_PENGUMUMAN:
        draft.loading = true;
        draft.isChecked = false;
        break;
      case constants.SUBMIT_PENGUMUMAN_FAIL:
        draft.loading = false;
        draft.isChecked = true;
        draft.error = true;
        break;
      case constants.SUBMIT_PENGUMUMAN_SUCCESS:
        draft.loading = false;
        draft.isChecked = true;
        draft.error = false;
        break;
      case constants.DEL_SAVED_DETAIL:
        draft.saved_details = [...draft.saved_details.slice(0, action.idx), ...draft.saved_details.slice(action.idx + 1)];
        break;
    }
  });

export default PostAnnounceFormVendorScreenReducer;
