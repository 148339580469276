export const DEFAULT_ACTION = 'screen/UnitAnnounceFormDetailScreen/DEFAULT_ACTION';
export const UPDATE_INPUT = 'screen/UnitAnnounceFormDetailScreen/UPDATE_INPUT';

export const GET_DETAIL_TODO = 'screen/UnitAnnounceFormDetailScreen/GET_DETAIL_TODO';
export const GET_DETAIL_TODO_LOADING = 'screen/UnitAnnounceFormDetailScreen/GET_DETAIL_TODO_LOADING';
export const GET_DETAIL_TODO_SUCCESS = 'screen/UnitAnnounceFormDetailScreen/GET_DETAIL_TODO_SUCCESS';
export const GET_DETAIL_TODO_FAIL = 'screen/UnitAnnounceFormDetailScreen/GET_DETAIL_TODO_FAIL';

export const ADD_IMAGE = 'screen/UnitAnnounceFormDetailScreen/ADD_IMAGE';
export const DEL_IMAGE = 'screen/UnitAnnounceFormDetailScreen/DEL_IMAGE';

export const SUBMIT_DETAIL = 'screen/UnitAnnounceFormDetailScreen/SUBMIT_DETAIL';
export const SUBMIT_DETAIL_LOADING = 'screen/UnitAnnounceFormDetailScreen/SUBMIT_DETAIL_LOADING';
export const SUBMIT_DETAIL_SUCCESS = 'screen/UnitAnnounceFormDetailScreen/SUBMIT_DETAIL_SUCCESS';
export const SUBMIT_DETAIL_FAIL = 'screen/UnitAnnounceFormDetailScreen/SUBMIT_DETAIL_FAIL';

export const SET_SAVED_DETAILS = 'screen/UnitAnnounceFormDetailScreen/SET_SAVED_DETAILS';