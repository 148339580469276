import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function updateInput({ name, value }) {
  return {
    type: constants.UPDATE_INPUT,
    name,
    value
  };
}

export function getUnitAnnounces() {
  return {
    type: constants.GET_UNIT_ANNOUNCE
  };
}

export function getUnitAnnouncesLoading() {
  return {
    type: constants.GET_UNIT_ANNOUNCE_LOADING
  };
}

export function getUnitAnnouncesFail(error) {
  return {
    type: constants.GET_UNIT_ANNOUNCE_FAIL,
    error
  };
}

export function getUnitAnnouncesSuccess(results) {
  return {
    type: constants.GET_UNIT_ANNOUNCE_SUCCESS,
    results
  };
}

export function getMoreUnitAnnounces() {
  return {
    type: constants.GET_MORE_UNIT_ANNOUNCE
  };
}

export function getMoreUnitAnnouncesLoading() {
  return {
    type: constants.GET_MORE_UNIT_ANNOUNCE_LOADING
  };
}

export function getMoreUnitAnnouncesFail(error) {
  return {
    type: constants.GET_MORE_UNIT_ANNOUNCE_FAIL,
    error
  };
}

export function getMoreUnitAnnouncesSuccess(results) {
  return {
    type: constants.GET_MORE_UNIT_ANNOUNCE_SUCCESS,
    results
  };
}

export function delUnitAnnounce(id_pengumuman) {
  return {
    type: constants.DELETE_UNIT_ANNOUNCE,
    id_pengumuman
  };
}

export function delUnitAnnounceLoading() {
  return {
    type: constants.DELETE_UNIT_ANNOUNCE_LOADING
  };
}

export function delUnitAnnounceFail(error) {
  return {
    type: constants.DELETE_UNIT_ANNOUNCE_FAIL,
    error
  };
}

export function delUnitAnnounceSuccess(results) {
  return {
    type: constants.DELETE_UNIT_ANNOUNCE_SUCCESS,
    results
  };
}