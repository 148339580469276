import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import makeSelectPostAnnounceFormDetailScreen from './selector';
import reducer from './reducer';
import saga from './saga';

// import {ScrollView, RefreshControl, TouchableWithoutFeedback, PermissionsAndroid } from 'react-native';
import {
  AspectRatio,
  Icon,
  HStack, 
  Avatar, 
  Heading, 
  VStack, 
  Box, 
  Center,
  Text,
  Image,
  Pressable,
  Divider,
  Checkbox,
  FormControl,
  TextArea,
  Select, 
  Flex,
  CheckIcon,
  ScrollView,
  Modal, 
  Button,
  Input} from 'native-base';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import Files from 'react-files';
import 'react-html5-camera-photo/build/css/index.css';
import {MdAddAPhoto,MdDelete } from 'react-icons/md';
import {FaCheckCircle } from 'react-icons/fa';
import { ContainerPage } from '../../components/ContainerPage';
import { commonStyle } from '../../styles';
import { addImage, defaultAction, delImage, getDetailTodo, setSavedDetails, submitDetail, submitPreventif, updateInput } from './actions';
import api from '../../util/api';
import helper from '../../util/helper';
import moment from 'moment';
import makeSelectGlobalVar from '../../util/selector';
import MySelect2 from '../../components/MySelect2';

export function UnitAnnounceFormDetailScreen({
  dispatch,
  location,
  navigation,
  postAnnounceFormDetailScreen,
  globalVar,
  onUpdateInput,
  onGetDetailTodo,
  onAddImage,
  onDelImage,
  onSubmitDetail,
  onSetSavedDetails
}){
  const[userData, setUserData] = useState();
  const [kerusakan, setKerusakan] = useState();
  const [openCamera, setOpenCamera] = useState(false);
  const [openModalCamera, setOpenModalCamera] = useState(false);
  const {new_data, readOnly, idx_detail, id_pengumuman} = location.state;

  useEffect(() => {
    // reset params
    dispatch(defaultAction());
    const curdataDetail = api.getFromLocalDB('postAnnounceDetail');
    curdataDetail.then((val) => {
      console.log('saved_details', val);
      if(val?.length > 0){
        onSetSavedDetails(idx_detail, val);
      }
        
    }).catch(err => {
      console.log('err-localdb:', err)
    });
  },[]);

  const handleGetData = () => {
    // onGetDetailTodo(id_realisasi_preventif, id_aset);
  }

  // const handleChoosePhoto = async () => {
  //   if(postAnnounceFormDetailScreen.assets.length == 4){
  //     helper.infoToast("Anda melebihi batas maksimum yakni 4 foto.");
  //     return;
  //   }

  //   try {
  //     const granted = await PermissionsAndroid.request(
  //       PermissionsAndroid.PERMISSIONS.CAMERA,
  //       {
  //         title: "App Camera Permission",
  //         message:"App needs access to your camera",
  //         buttonNeutral: "Ask Me Later",
  //         buttonNegative: "Cancel",
  //         buttonPositive: "OK"
  //       }
  //     );
  //     if (granted === PermissionsAndroid.RESULTS.GRANTED) {
  //       console.log("Camera permission given");
  //       launchCamera({ 
  //         mediaType: 'photo',
  //         maxWidth: 800,
  //         maxHeight: 800,
  //         includeBase64: true,
  //         cameraType: 'back'
  //       }, (response) => {
  //         console.log(response);
  //         if (response) {
  //           if(!helper.isEmpty(response.assets)) onAddImage(response.assets[0]);
  //         }
  //       });
  //     } else {
  //       console.log("Camera permission denied");
  //     }
  //   } catch (err) {
  //     console.warn(err);
  //   }
  // };

  const handleChoosePhoto = async (data) => {
    setOpenCamera(false);
    console.log("img:", data);
    setTimeout(() => {
      setOpenModalCamera(false);
    }, 300);
    if(postAnnounceFormDetailScreen.assets.length == 4){
      helper.infoToast("Anda melebihi batas maksimum yakni 4 foto.");
      return;
    }

    try {
      if(!helper.isEmpty(data)) onAddImage(data);
    } catch (err) {
      console.warn(err);
    }
  };

  const handleSubmit = () => {
    const {saved_details, id_unit, tmp_id_unit, id_lokasi, tmp_id_lokasi} = postAnnounceFormDetailScreen;
    // if(helper.isEmpty(postAnnounceFormDetailScreen.noted) || postAnnounceFormDetailScreen.noted.length < 10){
    //   helper.warningToast("Isikan deskripsi kerusakan, Minimal 10 karakter.");
    //   return;
    // }

    const idxLok = postAnnounceFormDetailScreen.saved_details.findIndex(raw => raw.id_unit === id_unit);
    // console.log('id_unit:', id_unit);
    // console.log('tmp_id_unit:', tmp_id_unit);
    // console.log('id_lokasi:', id_lokasi);
    // console.log('tmp_id_lokasi:', tmp_id_lokasi);
    
    if(idxLok >= 0 && (id_unit != tmp_id_unit)){
      helper.warningToast("Pengumuman untuk unit tersebut sudah ada. Silahkan gunakan pilihan lain.");
      return;
    }

    // if(postAnnounceFormDetailScreen.assets.length == 0){
    //   helper.warningToast("Bukti foto diperlukan min 1 foto.");
    //   return;
    // }

    onSubmitDetail(idx_detail);
    // setSuccess(true);
  }

  const handleCameraOpen = () => {
    setOpenModalCamera(true);
    setTimeout(() => {
      setOpenCamera(true);
    }, 300);
  }

  function handleTakePhotoAnimationDone (dataUri) {
    // Do stuff with the photo...
    // console.log('takePhoto');
  }

  function handleCameraError (error) {
    // console.log('handleCameraError', error);
  }

  function handleCameraStart (stream) {
    // console.log('handleCameraStart : ', stream);
  }

  function handleCameraStop () {
    // console.log('handleCameraStop');
  }

  const getBase64 = file => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };

  const handleFileChange = (files) => {
    console.log(files);
    const file = files[0];
    getBase64(file)
      .then(result => {
        file["base64"] = result;
        console.log("File Is", file);
        const params = {
          base64: result,
          type: file.type,
          name: file.name,
        }

        onAddImage(params.base64);
      })
      .catch(err => {
        console.log(err);
      });
  }

  const handleFileError = (error, file) => {
    console.log('error code ' + error.code + ': ' + error.message)
    helper.errorToast(error.message);
  }

  return (
        <ContainerPage options={{statusBar: true, prevPage: 'post-announce-form', title: 'Pengumuman', params:{}}}>
          <Flex flex={1}>
            <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
              <VStack space={7} mt={2} mb={35} mx={3}>
                {/* <Box mt={5}>
                  <Center>
                    <HStack space={2} justifyContent="center" alignItems="center">
                      <Icon as={<Box><FaCheckCircle/></Box>} size="8" color="success.700" />
                      <Text color="darkText" fontWeight="bold" isTruncated maxWidth="90%" fontSize="lg">
                        text here
                      </Text>
                    </HStack>
                  </Center>
                </Box> 
                */}
                
                
                <Box p={5} borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor}>
                    <VStack>
                      <Box>
                      <FormControl mb="1" isRequired>
                        <FormControl.Label>Unit</FormControl.Label>
                        {globalVar.list_units && 
                              <MySelect2
                                key="opt-unit"
                                isClearable
                                defaultValue="0"
                                placeholder="Pilih Unit"
                                menuPortalTarget={document.body}
                                getOptionLabel={option => `${option.nama}`}
                                getOptionValue={option => option.id}
                                options={globalVar.list_units}
                                value={globalVar.list_units.filter(({id}) => id === postAnnounceFormDetailScreen.id_unit)}
                                onChange={(selectedOption) => onUpdateInput('id_unit',(selectedOption==null) ? 0 : selectedOption.id)}
                              />
                            }
                        </FormControl>
                      </Box>
                      <Box>
                      <FormControl mb="1" isRequired>
                        <FormControl.Label>Lokasi</FormControl.Label>
                        {globalVar.list_lokasi && 
                              <MySelect2
                                key="opt-unit"
                                isClearable
                                defaultValue="0"
                                placeholder="Pilih Lokasi"
                                menuPortalTarget={document.body}
                                getOptionLabel={option => `${option.nama}`}
                                getOptionValue={option => option.id}
                                options={globalVar.list_lokasi}
                                value={globalVar.list_lokasi.filter(({id}) => id === postAnnounceFormDetailScreen.id_lokasi)}
                                onChange={(selectedOption) => onUpdateInput('id_lokasi',(selectedOption==null) ? 0 : selectedOption.id)}
                              />
                            }
                          </FormControl>
                      </Box>
                      <Box mb={2}>
                        <FormControl mb="1">
                          <FormControl.Label>Catatan</FormControl.Label>
                          <TextArea spellCheck={false} h={40} placeholder="Deskripsi" value={postAnnounceFormDetailScreen.noted} onChange={ txt => onUpdateInput('noted', txt.currentTarget.value)} borderRadius="lg" w="100%" />
                        </FormControl>
                      </Box>
                    </VStack>
                </Box>
                
                {!readOnly &&
                <Box>
                  <Button isLoading={postAnnounceFormDetailScreen.loading_submit} spinnerPlacement="end" isLoadingText="Submitting..." colorScheme="primary" onPress={() => handleSubmit()} shadow={3}>Simpan</Button>
                </Box>
                }
              </VStack>
            </ScrollView>
          </Flex>
        </ContainerPage>
      );
    
}

const styles = {
  errorTextStyle: {
    color: 'red',
    fontStyle: 'italic',
    fontWeight: 'bold',
    alignSelf: 'center'
  }
};

const mapStateToProps = createStructuredSelector({
  postAnnounceFormDetailScreen: makeSelectPostAnnounceFormDetailScreen(),
  globalVar: makeSelectGlobalVar()
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onGetDetailTodo: (id_realisasi_preventif, id_inventaris) => dispatch(getDetailTodo(id_realisasi_preventif, id_inventaris)),
    onAddImage: asset => dispatch(addImage(asset)),
    onDelImage: idx => dispatch(delImage(idx)),
    onSubmitDetail: (idxDetail) => dispatch(submitDetail(idxDetail)),
    onSetSavedDetails: (idx_detail, details) => dispatch(setSavedDetails(idx_detail, details)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(UnitAnnounceFormDetailScreen);