/*
 *
 * WasteTransferFormDetailScreen reducer
 *
 */
import {produce} from 'immer';
import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';
import helper from '../../util/helper';

export const initialState = {
    error: 0,
    error_msg: '',
    loading: false,
    loading_submit: false,
    isSuccess: false,
    id_detail: '',
    dtTransferDetail: null,
    assets:[],
    id_approval: '',
    id_transfer: '',
    jenis_limbah: null,
    id_jenis_limbah: '',
    id_approval_detail: '',
    volume:'',
    berat:'',
    no_kendaraan:'',
    description:'',
    code:'',
    locationState: null
};

/* eslint-disable default-case, no-param-reassign */
const WasteTransferFormDetailScreenReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case DEFAULT_ACTION:
      return {...initialState};
      case constants.UPDATE_INPUT:
        draft[action.name] = action.value;
        break;
      
      case constants.GET_DETAIL:
        draft.loading = true;
        break;
      case constants.GET_DETAIL_FAIL:
        draft.error = true;
        draft.loading = false;
        draft.dtTransferDetail = null;
        draft.error_msg= 'Maaf, Hasil pencarian belum ditemukan.';
        break;
      case constants.GET_DETAIL_SUCCESS:
        if(!helper.isEmpty(action.results)){
          const objDetail = action.results;
          draft.dtTransferDetail = objDetail;

          if(!helper.isEmpty(objDetail.dtTrfLimbah)){
            draft.code = objDetail.dtTrfLimbah.code;
            
            const idxDetail = objDetail.dtTrfLimbah.transfer_limbah_detail.findIndex(
              result => result.id === draft.id_detail,
            );

            if(idxDetail >= 0){
              const objRawDetail = objDetail.dtTrfLimbah.transfer_limbah_detail[idxDetail];
              draft.assets = objRawDetail.tf_det_attach;
              draft.id_jenis_limbah = objRawDetail.id_jenis_limbah;
              draft.volume = objRawDetail.volume;
              draft.berat = objRawDetail.berat;
              draft.description = objRawDetail.noted;
              draft.no_kendaraan = objDetail.dtTrfLimbah.no_kendaraan;
              draft.id_approval_detail = objRawDetail.id_approval_detail;
            }else{
              const idxDetailApv1 = objDetail.dtApproval.approvalkasie_detail.findIndex(
                result => result.id === draft.id_detail,
              );
  
              if(idxDetailApv1 >= 0){
                const objRawDetailApv1 = objDetail.dtApproval.approvalkasie_detail[idxDetailApv1];
                draft.id_jenis_limbah = objRawDetailApv1.approval_request?.id_jenis_limbah;
                draft.volume = objRawDetailApv1.approval_request?.volume;
                draft.berat = objRawDetailApv1.approval_request?.berat;
                draft.description = objRawDetailApv1.noted;
                draft.no_kendaraan = objDetail.dtTrfLimbah.no_kendaraan;
              }
            }

          } else  {
            const idxDetailApv = objDetail.dtApproval.approvalkasie_detail.findIndex(
              result => result.id === draft.id_detail,
            );

            if(idxDetailApv >= 0){
              const objRawDetailApv = objDetail.dtApproval.approvalkasie_detail[idxDetailApv];
              draft.id_jenis_limbah = objRawDetailApv.approval_request?.id_jenis_limbah;
              draft.volume = objRawDetailApv.approval_request?.volume;
              draft.berat = objRawDetailApv.approval_request?.berat;
              draft.description = objRawDetailApv.noted;
              draft.no_kendaraan = objDetail.dtTrfLimbah.no_kendaraan;
            }
          }
        
        }
        draft.error = false;
        draft.loading = false;
        break;
      case constants.ADD_IMAGE: {
        let cur_arr = draft.assets;
        const image = action.asset;
        cur_arr.push({path: image, status: true});
        draft.assets = cur_arr;
        }
        break;
      case constants.DEL_IMAGE:
        draft.assets = [...draft.assets.slice(0, action.idx), ...draft.assets.slice(action.idx + 1)];
        break;
      case constants.SUBMIT_TRANSFER:
        draft.loading_submit = true;
        break;
      case constants.SUBMIT_TRANSFER_FAIL:
        draft.error = true;
        draft.loading_submit = false;
        draft.error_msg= 'Maaf, Hasil pencarian belum ditemukan.';
        break;
      case constants.SUBMIT_TRANSFER_SUCCESS:
        draft.preventif = action.results;
        draft.loading_submit = false;
        draft.isSuccess = true;
        draft.error = false;
        break;
    }
  });

export default WasteTransferFormDetailScreenReducer;
