export const DEFAULT_ACTION = 'screen/UnitRequestFormDetailScreen/DEFAULT_ACTION';
export const UPDATE_INPUT = 'screen/UnitRequestFormDetailScreen/UPDATE_INPUT';

export const GET_DETAIL_TODO = 'screen/UnitRequestFormDetailScreen/GET_DETAIL_TODO';
export const GET_DETAIL_TODO_LOADING = 'screen/UnitRequestFormDetailScreen/GET_DETAIL_TODO_LOADING';
export const GET_DETAIL_TODO_SUCCESS = 'screen/UnitRequestFormDetailScreen/GET_DETAIL_TODO_SUCCESS';
export const GET_DETAIL_TODO_FAIL = 'screen/UnitRequestFormDetailScreen/GET_DETAIL_TODO_FAIL';

export const ADD_IMAGE = 'screen/UnitRequestFormDetailScreen/ADD_IMAGE';
export const DEL_IMAGE = 'screen/UnitRequestFormDetailScreen/DEL_IMAGE';

export const SUBMIT_DETAIL = 'screen/UnitRequestFormDetailScreen/SUBMIT_DETAIL';
export const SUBMIT_DETAIL_LOADING = 'screen/UnitRequestFormDetailScreen/SUBMIT_DETAIL_LOADING';
export const SUBMIT_DETAIL_SUCCESS = 'screen/UnitRequestFormDetailScreen/SUBMIT_DETAIL_SUCCESS';
export const SUBMIT_DETAIL_FAIL = 'screen/UnitRequestFormDetailScreen/SUBMIT_DETAIL_FAIL';

export const SET_SAVED_DETAILS = 'screen/UnitRequestFormDetailScreen/SET_SAVED_DETAILS';