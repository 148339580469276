import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
 
  import * as constants from './constants';
  import moment from 'moment';
  import api from '../../util/api';
  import helper from '../../util/helper';
  
  import makeSelectUnitAnnounceScreen from './selector';
  import { delUnitAnnounceFail, delUnitAnnounceSuccess, getMoreUnitAnnouncesFail, getMoreUnitAnnouncesSuccess, getMoreWOReportFail, getMoreWOReportSuccess, getUnitAnnounces, getUnitAnnouncesFail, getUnitAnnouncesSuccess, getWorkOrderReportFail, getWorkOrderReportSuccess } from './actions';
import makeSelectUnitAnnnounceScreen from './selector';
  
  export function* doGetListUnitAnnounce(action) {
    const dataset = yield select(makeSelectUnitAnnounceScreen());
    try {
      const tglAwal = moment(dataset.start_dt).format("YYYY-MM-DD");
      const tglAkhir = moment(dataset.end_dt).format("YYYY-MM-DD HH:mm:ss");
      
      const params = {
        tgl_awal: tglAwal+' 00:00:00',
        tgl_akhir: tglAkhir,
        search: dataset.q,
        status: dataset.filter_status
      };

      if(helper.isEmpty(dataset.start_dt)) delete params.tgl_awal;
      if(helper.isEmpty(dataset.end_dt)) delete params.tgl_akhir;
      if(helper.isEmpty(dataset.q)) delete params.search;
      if(helper.isEmpty(dataset.filter_status)) delete params.status;

      const response = yield call(api.unit_announce.getRequestList, params, dataset.page);
      const {success} = response.data;
      console.log("res:", success);
      if(dataset.page == 1){
        yield put(
          getUnitAnnouncesSuccess((helper.isEmpty(success) ? [] : success)),
        );
      } else {
        yield put(
          getMoreUnitAnnouncesSuccess((helper.isEmpty(success) ? [] : success)),
        );
      }
    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      if(dataset.page == 1){
        yield put(getUnitAnnouncesFail(err));
      } else {
        yield put(getMoreUnitAnnouncesFail(err));
      }
    }
  }

  export function* doDeleteListUnitAnnounce(action) {
    const {id_pengumuman} = action;
    const dataset = yield select(makeSelectUnitAnnnounceScreen());
    try {

      const params = {
        id_pengumuman: id_pengumuman,
      };

      const response = yield call(api.unit_announce.deleteRequest, params);
      const {success} = response.data;
      console.log("res:", success);
      helper.successToast("Pengumuman berhasil dihapus");
      yield put(
        delUnitAnnounceSuccess((helper.isEmpty(success) ? [] : success)),
      );
    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      yield put(delUnitAnnounceFail(err));
    }
  }

  // Individual exports for testing
  export default function* unitAnnounceScreenSaga() {
    yield all([
      takeLatest(constants.GET_UNIT_ANNOUNCE, doGetListUnitAnnounce),
      takeLatest(constants.GET_MORE_UNIT_ANNOUNCE, doGetListUnitAnnounce),
      takeLatest(constants.DELETE_UNIT_ANNOUNCE, doDeleteListUnitAnnounce),
      takeLatest(constants.DELETE_UNIT_ANNOUNCE_SUCCESS, doGetListUnitAnnounce),
    ]);
  }
  