import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import makeSelectApprovalFormScreen from './selector';
import reducer from './reducer';
import saga from './saga';

// import {ScrollView,RefreshControl, BackHandler, PermissionsAndroid, StyleSheet, View, Dimensions } from 'react-native';
import {
  AspectRatio,
  Icon,
  HStack, 
  Avatar, 
  Heading, 
  VStack, 
  Box, 
  Center,
  Text,
  Pressable,
  TextArea,
  FormControl,
  Divider,
  Image,
  Modal,
  Flex,
  ScrollView, 
  Button,
  Select,
  CheckIcon,
  Stack,
  Menu,
  Fab,
  Input} from 'native-base';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import Files from 'react-files';
import 'react-html5-camera-photo/build/css/index.css';
import {FaCalendarAlt, FaCheckCircle} from 'react-icons/fa';
import {MdAddAPhoto, MdDelete, MdDownload, MdFileCopy, MdModeEdit, MdMoreVert, MdRemoveRedEye} from 'react-icons/md';
import { ContainerPage } from '../../components/ContainerPage';
import { commonStyle } from '../../styles';
import { addImage, defaultAction, delImage, getApprovalDetail, getPelaporan, submitApproval, submitPelaporan, updateInput } from './actions';
import api from '../../util/api';
import helper from '../../util/helper';
import BadgeStatus from '../../components/BadgeStatus';
import ImageSlider from '../../components/ImageSlider';
import { AiOutlinePlus } from 'react-icons/ai';
import history from '../../util/history';
import makeSelectGlobalVar from '../../util/selector';
import MySelect2 from '../../components/MySelect2';
import MyAlert from '../../components/MyAlert';
import LoadingOverlay from 'react-loading-overlay';
import MyAlertDialog from '../../components/MyAlertDialog';

export function ApprovalFormScreen({
  dispatch,
  location,
  navigation,
  approvalFormScreen,
  globalVar,
  onUpdateInput,
  onAddImage,
  onDelImage,
  onSubmitApproval,
  onGetApprovalDetail
}){
  const {id_approval_request, jns_approval_code, id_approval, origin_screen, viewOnly} = location.state;
  const[userData, setUserData] = useState();
  const [photo, setPhoto] = useState(null);
  const [openCamera, setOpenCamera] = useState(false);
  const [openModalCamera, setOpenModalCamera] = useState(false);
  const [isOpenDialogSubmit, setOpenDialogSubmit] = useState(false);

  const imageList = [
    {path: '../../images/pintu.jpg'},
    {path: '../../images/pintu.jpg'},
    {path: '../../images/pintu.jpg'},
  ];

  useEffect(() => {
    // reset params
    dispatch(defaultAction());

    onUpdateInput('id_approval', id_approval);
    onUpdateInput('id_approval_request', id_approval_request);
    onUpdateInput('jns_approval_code', jns_approval_code);
    
    onGetApprovalDetail();
    console.log("id_l", id_approval);
  },[]);
  

  const handleChoosePhoto = async (data) => {
    setOpenCamera(false);
    console.log("img:", data);
    setTimeout(() => {
      setOpenModalCamera(false);
    }, 300);
    if(approvalFormScreen.assets.length == 4){
      helper.infoToast("Anda melebihi batas maksimum yakni 4 foto.");
      return;
    }

    try {
      if(!helper.isEmpty(data)) onAddImage(data);
      // const granted = await PermissionsAndroid.request(
      //   PermissionsAndroid.PERMISSIONS.CAMERA,
      //   {
      //     title: "App Camera Permission",
      //     message:"App needs access to your camera",
      //     buttonNeutral: "Ask Me Later",
      //     buttonNegative: "Cancel",
      //     buttonPositive: "OK"
      //   }
      // );
      // if (granted === PermissionsAndroid.RESULTS.GRANTED) {
      //   console.log("Camera permission given");
      //   launchCamera({ 
      //     mediaType: 'photo',
      //     maxWidth: 800,
      //     maxHeight: 800,
      //     includeBase64: true,
      //     cameraType: 'back'
      //   }, (response) => {
      //     console.log(response);
      //     if (response) {
      //       if(!helper.isEmpty(response.assets)) onAddImage(response.assets[0]);
      //     }
      //   });
      // } else {
      //   console.log("Camera permission denied");
      // }
    } catch (err) {
      console.warn(err);
    }
  };

  const handleSubmit = () => {
    setOpenDialogSubmit(false);
    // if(helper.isEmpty(approvalFormScreen.notes) || approvalFormScreen.notes.length < 10){
    //   helper.warningToast("Isikan deskripsi, Minimal 10 karakter.");
    //   return;
    // }
    onSubmitApproval();
  }

  const handleGetData = () => {
    if(id_approval > 0){
      onGetApprovalDetail(id_approval);
    }
  }

  const handleCameraOpen = () => {
    setOpenModalCamera(true);
    setTimeout(() => {
      setOpenCamera(true);
    }, 300);
  }

  function handleTakePhotoAnimationDone (dataUri) {
    // Do stuff with the photo...
    // console.log('takePhoto');
  }

  function handleCameraError (error) {
    // console.log('handleCameraError', error);
  }

  function handleCameraStart (stream) {
    // console.log('handleCameraStart : ', stream);
  }

  function handleCameraStop () {
    // console.log('handleCameraStop');
  }

  const getBase64 = file => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };

  const handleFileChange = (files) => {
    console.log(files);
    const file = files[0];
    getBase64(file)
      .then(result => {
        file["base64"] = result;
        console.log("File Is", file);
        const params = {
          base64: result,
          type: file.type,
          name: file.name,
        }

        onAddImage(params.base64);
      })
      .catch(err => {
        console.log(err);
      });
  }

  const handleFileError = (error, file) => {
    console.log('error code ' + error.code + ': ' + error.message)
    helper.errorToast(error.message);
  }

  return (
        <ContainerPage options={{statusBar: true, prevPage: 'approval', title: 'Aproval', params:{id_approval: id_approval, id_approval_request: id_approval_request, new_data: true}}}>
          <Flex flex={1} minW="300px">
            <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
              <LoadingOverlay
                active={approvalFormScreen.loading}
                spinner
                text='Loading...'
                >
              <VStack space={7} mt={2} mb={35} mx={3}>
              {approvalFormScreen.code && 
                  <Box mt={5}>
                    <Center>
                      <HStack space={2} justifyContent="center" alignItems="center">
                        <Icon as={<Box><FaCheckCircle/></Box>} size="8" color="success.700" />
                        <Text color="darkText" fontWeight="bold" isTruncated maxWidth="90%" fontSize="lg">
                          {approvalFormScreen.code}
                        </Text>
                      </HStack>
                    </Center>
                  </Box>
                }
                <Box p={5} borderRadius="lg" shadow={3} backgroundColor="white">
                    <VStack>
                      <Box>
                        <FormControl mb="1" isRequired>
                          <FormControl.Label>Unit</FormControl.Label>
                          {globalVar.list_units && 
                          <MySelect2
                            key="opt-gedung"
                            isClearable
                            isDisabled
                            defaultValue="0"
                            placeholder="Pilih Unit"
                            menuPortalTarget={document.body}
                            getOptionLabel={option => `${option.nama}`}
                            getOptionValue={option => option.id}
                            options={globalVar.list_units}
                            value={globalVar.list_units.filter(({id}) => id === approvalFormScreen.id_unit)}
                            onChange={(selectedOption) => onUpdateInput('id_unit',(selectedOption==null) ? 0 : selectedOption.id)}
                          />
                          }
                        </FormControl>
                      </Box>
                      {/* <Box>
                        <FormControl mb="1" isRequired>
                          <FormControl.Label>Kode</FormControl.Label>
                          <Input borderRadius="lg" placeholder='Input kode terlebih dahulu' />
                        </FormControl>
                      </Box> */}
                      {jns_approval_code == 6 &&
                      <Box>
                        <FormControl mb="1">
                          <FormControl.Label>Vendor</FormControl.Label>
                          {globalVar.list_vendor && 
                              <MySelect2
                                key="opt-unit"
                                isClearable
                                defaultValue="0"
                                placeholder="Pilih Vendor"
                                menuPortalTarget={document.body}
                                getOptionLabel={option => `${option.nama}`}
                                getOptionValue={option => option.id}
                                options={globalVar.list_vendor}
                                value={globalVar.list_vendor.filter(({id}) => id === approvalFormScreen.id_vendor)}
                                onChange={(selectedOption) => onUpdateInput('id_vendor',(selectedOption==null) ? 0 : selectedOption.id)}
                              />
                            }
                        </FormControl>
                      </Box>
                      }
                      <Box>
                        <FormControl mb="1" isRequired>
                          <FormControl.Label>Lokasi Tujuan</FormControl.Label>
                          {globalVar.list_lokasi && 
                              <MySelect2
                                key="opt-unit"
                                isClearable
                                isDisabled={jns_approval_code == 6}
                                defaultValue="0"
                                placeholder="Pilih Lokasi"
                                menuPortalTarget={document.body}
                                getOptionLabel={option => `${option.nama}`}
                                getOptionValue={option => option.id}
                                options={globalVar.list_lokasi}
                                value={globalVar.list_lokasi.filter(({id}) => id === approvalFormScreen.id_lokasi_tujuan)}
                                onChange={(selectedOption) => onUpdateInput('id_lokasi_tujuan',(selectedOption==null) ? 0 : selectedOption.id)}
                              />
                            }
                        </FormControl>
                      </Box>
                      <Box>
                        <FormControl mb="1" isRequired>
                          <FormControl.Label>Unit Tujuan</FormControl.Label>
                          {globalVar.list_units && 
                          <MySelect2
                            key="opt-gedung"
                            isClearable
                            defaultValue="0"
                            placeholder="Pilih Unit"
                            menuPortalTarget={document.body}
                            getOptionLabel={option => `${option.nama}`}
                            getOptionValue={option => option.id}
                            options={globalVar.list_units}
                            value={globalVar.list_units.filter(({id}) => id === approvalFormScreen.id_unit_tujuan)}
                            onChange={(selectedOption) => onUpdateInput('id_unit_tujuan',(selectedOption==null) ? 0 : selectedOption.id)}
                          />
                          }
                        </FormControl>
                      </Box>
                      <Box>
                        <FormControl mb="1" isRequired>
                          <FormControl.Label>Petugas Lapangan</FormControl.Label>
                          {globalVar.list_pegawai && 
                          <MySelect2
                            key="opt-gedung"
                            isClearable
                            defaultValue="0"
                            placeholder="Pilih Pegawai"
                            menuPortalTarget={document.body}
                            getOptionLabel={option => `${option.name_employee}`}
                            getOptionValue={option => option.id}
                            options={globalVar.list_pegawai}
                            value={globalVar.list_pegawai.filter(({id}) => id === approvalFormScreen.id_pegawai)}
                            onChange={(selectedOption) => onUpdateInput('id_pegawai',(selectedOption==null) ? 0 : selectedOption.id)}
                          />
                          }
                        </FormControl>
                      </Box>
                      <Box mb={2}>
                        <FormControl mb="1">
                          <FormControl.Label>Catatan</FormControl.Label>
                          <TextArea spellCheck={false} value={approvalFormScreen.notes} onChangeText={txt => onUpdateInput('notes', txt)} h={40} placeholder="Catatan jika dibutuhkan" borderRadius="lg" w="100%" />
                        </FormControl>
                      </Box>
                    </VStack>
                </Box>
                  {approvalFormScreen.approval_details.length == 0 && 
                  <Box p={3} borderRadius="lg" shadow={3} backgroundColor="white">
                    <MyAlert status="info" title="Detail kosong!"/>
                  </Box>
                  }
                  {approvalFormScreen.approval_details.length > 0 &&
                  <Box>
                    <Accordion>
                    {approvalFormScreen.approval_details.map((raw, i) => (
                      <AccordionItem key={i} header={
                        <Box px={5} py={3} backgroundColor={commonStyle.cardBaseHeaderBg}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                          <Box>
                            <Icon color="darkText" as={<Box><MdFileCopy/></Box>} size={5}></Icon>
                          </Box>
                          <Box flex={1} ml={1} alignItems="start">
                            <Text color="darkText" fontWeight={500} >
                              {/* {moment(data.date_report, "YYYY-MM-DD HH:mm:ss").format("DD MMM YYYY HH:mm")} */}
                              Detail Permintaan
                            </Text>
                          </Box>
                        </Stack>
                      </Box>
                      } initialEntered>
                        <Flex m={3}>
                          <ImageSlider key={i} images={raw.attachment}/>
                        </Flex>
                        <VStack backgroundColor="white" p={3}>
                          <FormControl mb="1">
                            <FormControl.Label>Jenis Limbah</FormControl.Label>
                            <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                              <Text textAlign="left">{raw.ms_jns_limbah?.nama}</Text>
                            </Box>
                          </FormControl>
                          <FormControl mb="1">
                            <FormControl.Label>Volume (L)</FormControl.Label>
                            <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                              <Text textAlign="right">{raw.volume}</Text>
                            </Box>
                          </FormControl>
                          <FormControl mb="1">
                            <FormControl.Label>Berat (kg)</FormControl.Label>
                            <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                              <Text textAlign="right">{raw.berat}</Text>
                            </Box>
                          </FormControl>
                          <FormControl mb="1">
                            <FormControl.Label>Noted</FormControl.Label>
                            <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                              <Text>{raw.note}</Text>
                            </Box>
                          </FormControl>
                        </VStack>
                      </AccordionItem>
                    ))}
                    </Accordion>
                  </Box>
                  }
                {!viewOnly &&
                <HStack justifyContent="space-around" mb="32">
                    <Button w="1/3" isLoading={approvalFormScreen.loading} spinnerPlacement="end" isLoadingText="Submitting..." colorScheme="primary" onPress={() => setOpenDialogSubmit(true)}>Aprove</Button>
                    <Button onPress={() =>  history.replace('approval')} variant="link" colorScheme="primary">Cancel</Button>
                </HStack>
                }
              </VStack>
              </LoadingOverlay>
            </ScrollView>
          </Flex>

          <MyAlertDialog 
            alertTitle="Aproval Kasie" 
            alertDesc={`Apakah data sudah benar ?`}
            alertNoLabel="Cancel"
            alertYesLabel="Aprove"
            colorScheme="success"
            alertIsOpen={isOpenDialogSubmit}
            alertOnClose={() => setOpenDialogSubmit(false)}
            alertOnConfirm={() => handleSubmit()}
          />

        </ContainerPage>
      );
    
}

const mapStateToProps = createStructuredSelector({
  approvalFormScreen: makeSelectApprovalFormScreen(),
  globalVar: makeSelectGlobalVar(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onAddImage: asset => dispatch(addImage(asset)),
    onDelImage: idx => dispatch(delImage(idx)),
    onSubmitApproval: () => dispatch(submitApproval()),
    onGetApprovalDetail: () => dispatch(getApprovalDetail()),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(ApprovalFormScreen);