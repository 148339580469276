import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export function defaultAction() {
  console.log("reset state action");
  return {
    type: DEFAULT_ACTION,
  };
}

export function updateInput({ name, value }) {
  return {
    type: constants.UPDATE_INPUT,
    name,
    value
  };
}

export function submitPenerimaan(id_branch, origin_screen) {
  return {
    type: constants.SUBMIT_PENERIMAAN,
    id_branch,
    origin_screen
  };
}

export function submitPenerimaanFail(error) {
  return {
    type: constants.SUBMIT_PENERIMAAN_FAIL,
    error
  };
}

export function submitPenerimaanSuccess(results, id_aset) {
  return {
    type: constants.SUBMIT_PENERIMAAN_SUCCESS,
    results
  };
}

export function addImage(asset) {
  return {
    type: constants.ADD_IMAGE,
    asset
  };
}

export function delImage(idx) {
  return {
    type: constants.DEL_IMAGE,
    idx
  };
}

export function getPenerimaanDetail(id_transfer, id_penerimaan) {
  return {
    type: constants.GET_PENERIMAAN_DETAIl,
    id_transfer,
    id_penerimaan
  };
}

export function getPenerimaanDetailFail(error) {
  return {
    type: constants.GET_PENERIMAAN_DETAIl_FAIL,
    error
  };
}

export function getPenerimaanDetailSuccess(result_transfer, result_penerimaan) {
  return {
    type: constants.GET_PENERIMAAN_DETAIl_SUCCESS,
    result_transfer,
    result_penerimaan
  };
}