import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export function defaultAction() {
  console.log("reset state action");
  return {
    type: DEFAULT_ACTION,
  };
}

export function updateInput({ name, value }) {
  return {
    type: constants.UPDATE_INPUT,
    name,
    value
  };
}

export function submitPelaporan(id_branch, origin_screen) {
  return {
    type: constants.SUBMIT_PELAPORAN,
    id_branch,
    origin_screen
  };
}

export function submitPelaporanFail(error) {
  return {
    type: constants.SUBMIT_PELAPORAN_FAIL,
    error
  };
}

export function submitPelaporanSuccess(results, id_aset) {
  return {
    type: constants.SUBMIT_PELAPORAN_SUCCESS,
    results
  };
}

export function addImage(asset) {
  return {
    type: constants.ADD_IMAGE,
    asset
  };
}

export function delImage(idx) {
  return {
    type: constants.DEL_IMAGE,
    idx
  };
}

export function getDetail(id_ba) {
  return {
    type: constants.GET_DETAIL,
    id_ba
  };
}

export function getDetailFail(error) {
  return {
    type: constants.GET_DETAIL_FAIL,
    error
  };
}

export function getDetailSuccess(results) {
  return {
    type: constants.GET_DETAIL_SUCCESS,
    results
  };
}