export const DEFAULT_ACTION = 'screen/WasteAcceptanceFormScreen/DEFAULT_ACTION';
export const UPDATE_INPUT = 'screen/WasteAcceptanceFormScreen/UPDATE_INPUT';

export const SUBMIT_PENERIMAAN = 'screen/WasteAcceptanceFormScreen/SUBMIT_PENERIMAAN';
export const SUBMIT_PENERIMAAN_LOADING = 'screen/WasteAcceptanceFormScreen/SUBMIT_PENERIMAAN_LOADING';
export const SUBMIT_PENERIMAAN_SUCCESS = 'screen/WasteAcceptanceFormScreen/SUBMIT_PENERIMAAN_SUCCESS';
export const SUBMIT_PENERIMAAN_FAIL = 'screen/WasteAcceptanceFormScreen/SUBMIT_PENERIMAAN_FAIL';

export const ADD_IMAGE = 'screen/WasteAcceptanceFormScreen/ADD_IMAGE';
export const DEL_IMAGE = 'screen/WasteAcceptanceFormScreen/DEL_IMAGE';

export const GET_PENERIMAAN_DETAIl = 'screen/WasteAcceptanceFormScreen/GET_PENERIMAAN_DETAIl';
export const GET_PENERIMAAN_DETAIl_LOADING = 'screen/WasteAcceptanceFormScreen/GET_PENERIMAAN_DETAIl_LOADING';
export const GET_PENERIMAAN_DETAIl_SUCCESS = 'screen/WasteAcceptanceFormScreen/GET_PENERIMAAN_DETAIl_SUCCESS';
export const GET_PENERIMAAN_DETAIl_FAIL = 'screen/WasteAcceptanceFormScreen/GET_PENERIMAAN_DETAIl_FAIL';