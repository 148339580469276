import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the WastePickupFormScreen state domain
 */

const selectWastePickupFormScreenDomain = state =>
  state.wastePickupFormScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by WastePickupFormScreen
 */

const makeSelectWastePickupFormScreen = () =>
  createSelector(
    selectWastePickupFormScreenDomain,
    substate => substate,
  );

export default makeSelectWastePickupFormScreen;
export { selectWastePickupFormScreenDomain };
