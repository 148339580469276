import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the PostAnnounceFormScreen state domain
 */

const selectPostAnnounceFormScreenDomain = state =>
  state.postAnnounceFormScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by PostAnnounceFormScreen
 */

const makeSelectPostAnnounceFormScreen = () =>
  createSelector(
    selectPostAnnounceFormScreenDomain,
    substate => substate,
  );

export default makeSelectPostAnnounceFormScreen;
export { selectPostAnnounceFormScreenDomain };
