import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import makeSelectManifestDetailScreen from './selector';
import reducer from './reducer';
import saga from './saga';

// import {ScrollView,RefreshControl, BackHandler, PermissionsAndroid, StyleSheet, View, Dimensions } from 'react-native';
import {
  AspectRatio,
  Icon,
  HStack, 
  Avatar, 
  Heading, 
  VStack, 
  Box, 
  Center,
  Text,
  Pressable,
  TextArea,
  FormControl,
  Divider,
  Image,
  Modal,
  Flex,
  ScrollView, 
  Button,
  Select,
  CheckIcon,
  Stack,
  Menu,
  Fab} from 'native-base';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import Files from 'react-files';
import 'react-html5-camera-photo/build/css/index.css';
import {FaBuilding, FaCalendarAlt, FaCheckCircle, FaHourglassEnd, FaTimesCircle} from 'react-icons/fa';
import {MdAddAPhoto, MdDelete, MdDownload, MdFileCopy, MdModeEdit, MdMoreVert, MdRemoveRedEye} from 'react-icons/md';
import { ContainerPage } from '../../components/ContainerPage';
import { commonStyle } from '../../styles';
import { addImage, defaultAction, delImage, getDetail, getPelaporan, submitPelaporan, updateInput } from './actions';
import api from '../../util/api';
import helper from '../../util/helper';
import BadgeStatus from '../../components/BadgeStatus';
import ImageSlider from '../../components/ImageSlider';
import { AiOutlinePlus } from 'react-icons/ai';
import history from '../../util/history';
import moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import { saveAs } from 'file-saver';

export function ManifestDetailScreen({
  dispatch,
  location,
  navigation,
  manifestDetailScreen,
  onUpdateInput,
  onAddImage,
  onDelImage,
  onSubmitPelaporan,
  onGetDetail
}){
  const {id_ba, dtManifest} = location.state;
  const[userData, setUserData] = useState();
  const [photo, setPhoto] = useState(null);
  const [openCamera, setOpenCamera] = useState(false);
  const [openModalCamera, setOpenModalCamera] = useState(false);

  const imageList = [
    {path: '../../images/pintu.jpg'},
    {path: '../../images/pintu.jpg'},
    {path: '../../images/pintu.jpg'},
  ];

  useEffect(() => {
    // reset params
    dispatch(defaultAction());

    console.log(dtManifest);

    onUpdateInput('id_ba', id_ba);
    onUpdateInput('dtManifest', dtManifest);
    if(id_ba > 0){
      onGetDetail(id_ba);
    }
    console.log("id_l", id_ba);
  },[]);
  

  const handleChoosePhoto = async (data) => {
    setOpenCamera(false);
    console.log("img:", data);
    setTimeout(() => {
      setOpenModalCamera(false);
    }, 300);
    if(manifestDetailScreen.assets.length == 4){
      helper.infoToast("Anda melebihi batas maksimum yakni 4 foto.");
      return;
    }

    try {
      if(!helper.isEmpty(data)) onAddImage(data);
      // const granted = await PermissionsAndroid.request(
      //   PermissionsAndroid.PERMISSIONS.CAMERA,
      //   {
      //     title: "App Camera Permission",
      //     message:"App needs access to your camera",
      //     buttonNeutral: "Ask Me Later",
      //     buttonNegative: "Cancel",
      //     buttonPositive: "OK"
      //   }
      // );
      // if (granted === PermissionsAndroid.RESULTS.GRANTED) {
      //   console.log("Camera permission given");
      //   launchCamera({ 
      //     mediaType: 'photo',
      //     maxWidth: 800,
      //     maxHeight: 800,
      //     includeBase64: true,
      //     cameraType: 'back'
      //   }, (response) => {
      //     console.log(response);
      //     if (response) {
      //       if(!helper.isEmpty(response.assets)) onAddImage(response.assets[0]);
      //     }
      //   });
      // } else {
      //   console.log("Camera permission denied");
      // }
    } catch (err) {
      console.warn(err);
    }
  };

  const handleSubmit = () => {
    if(helper.isEmpty(manifestDetailScreen.description) || manifestDetailScreen.description.length < 10){
      helper.warningToast("Isikan deskripsi kerusakan, Minimal 10 karakter.");
      return;
    }
    if(manifestDetailScreen.assets.length == 0){
      helper.warningToast("Bukti foto diperlukan min 1 foto.");
      return;
    }

    console.log('desc:',manifestDetailScreen.description.length);

    onSubmitPelaporan();
  }

  const handleCameraOpen = () => {
    setOpenModalCamera(true);
    setTimeout(() => {
      setOpenCamera(true);
    }, 300);
  }

  function handleTakePhotoAnimationDone (dataUri) {
    // Do stuff with the photo...
    // console.log('takePhoto');
  }

  function handleCameraError (error) {
    // console.log('handleCameraError', error);
  }

  function handleCameraStart (stream) {
    // console.log('handleCameraStart : ', stream);
  }

  function handleCameraStop () {
    // console.log('handleCameraStop');
  }

  const getBase64 = file => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };

  const handleFileChange = (files) => {
    console.log(files);
    const file = files[0];
    getBase64(file)
      .then(result => {
        file["base64"] = result;
        console.log("File Is", file);
        const params = {
          base64: result,
          type: file.type,
          name: file.name,
        }

        onAddImage(params.base64);
      })
      .catch(err => {
        console.log(err);
      });
  }

  const handleFileError = (error, file) => {
    console.log('error code ' + error.code + ': ' + error.message)
    helper.errorToast(error.message);
  }

  const handleDownloadBA = () => {
    if(manifestDetailScreen.dtManifest?.pathBA){
      window.open(manifestDetailScreen.dtManifest?.pathBA, '_blank');
    } else {
      helper.infoToast('File belum tersedia');
    }
  }

  const handleDownloadManifest = async () => {
    if(manifestDetailScreen.dtManifest?.pathManifest){
      // window.open(manifestDetailScreen.dtManifest?.pathManifest, '_blank');
      await saveAs(manifestDetailScreen.dtManifest?.pathManifest, `file_${manifestDetailScreen.dtManifest?.code_manifest?.replaceAll("/", "_")}`);
      return;
    } else {
      helper.infoToast('File belum tersedia');
    }
  }

  return (
        <ContainerPage options={{statusBar: true, prevPage: 'manifest', title: 'BA & Manifest', params:{}}}>
          <Flex flex={1} minW="300px">
            <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
            <LoadingOverlay
                active={manifestDetailScreen.loading}
                spinner
                text='Loading...'
                >
              {manifestDetailScreen.dtManifest &&
              <Box mt={5}>
                <Center>
                  <HStack space={2} justifyContent="center" alignItems="center">
                    <Icon as={<Box><FaCheckCircle/></Box>} size="8" color="success.700" />
                    <Text color="darkText" fontWeight="bold" isTruncated maxWidth="90%" fontSize="lg">
                      {manifestDetailScreen.dtManifest?.nm_unit}
                    </Text>
                  </HStack>
                </Center>
              </Box>
              }
              <Box p={5} borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor} mx={3} mt={7}>
                <VStack>
                  <Center>
                    <Avatar size="lg" bg="primary.900" mb={4}>
                      {helper.getInitials(manifestDetailScreen.dtManifest?.nm_pegawai)}
                    </Avatar>
                  </Center>
                  <Center>
                    <Text color="darkText" isTruncated maxWidth="90%" fontSize="md" fontWeight="bold">
                      {manifestDetailScreen.dtManifest?.nm_pegawai}
                    </Text>
                  </Center>
                  <Center>
                    <Text color="muted.400" isTruncated maxWidth="90%" fontSize="sm">
                      Kasie Approval
                    </Text>
                  </Center>
                  <Box mt={2}>
                    <VStack>
                      <Text fontSize="xs" color="muted.600">No BA</Text>
                      <Text color="darkText" bold>
                        {manifestDetailScreen.dtManifest?.code_ba}
                      </Text>
                    </VStack>
                  </Box>
                  <Box mt={2}>
                    <VStack>
                      <Text fontSize="xs" color="muted.600">Tanggal Approval</Text>
                      <Text color="darkText" bold>
                        {moment(manifestDetailScreen.dtManifest?.tgl_ba, "YYYY-MM-DD HH:mm:ss").format("DD MMMM YYYY")}
                      </Text>
                    </VStack>
                  </Box>
                </VStack>
              </Box>
              <VStack space={7} mt={3} mb={35} mx={3}>
                <Box>
                  <Accordion>
                  {manifestDetailScreen.dtBaManifest?.ba_detail.map((raw, i) => (
                    <AccordionItem key={i} header={
                      <Box px={5} py={3} backgroundColor={commonStyle.cardBaseHeaderBg}>
                      <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Box>
                          <Icon color="darkText" as={<Box><MdFileCopy/></Box>} size={5}></Icon>
                        </Box>
                        <Box flex={1} ml={1} alignItems="start">
                          <Text color="darkText" fontWeight={500} >
                            Detail BA
                          </Text>
                        </Box>
                        <Box ml={2} alignItems="center" justifyContent="center" width={8} height={8}>
                          <Menu mt={2} py={2} placement="bottom right" trigger={triggerProps => {
                            return <Pressable accessibilityLabel="More options menu" {...triggerProps}>
                                    <Icon as={<Box><MdMoreVert/></Box>} size={8} color="gray.600" />
                                  </Pressable>;
                          }}>
                            {/* {access.includes('05:VIEW') &&  */}
                            <Menu.Item p={1} onPress={() => handleDownloadBA()}>
                              <HStack space={2} justifyContent="center" alignItems="center">
                                <Icon as={<Box><MdDownload/></Box>} size="md" color="info.700" />
                                <Text color="darkText" fontSize="md">
                                  Download BA
                                </Text>
                              </HStack>
                            </Menu.Item>
                            {/* } */}
                            {/* {access.includes('05:EDIT') &&  */}
                            <Menu.Item p={1} onPress={() => handleDownloadManifest()}>
                              <HStack space={2} justifyContent="center" alignItems="center">
                                <Icon as={<Box><MdDownload/></Box>} size="md" color="info.700" />
                                <Text color="darkText" fontSize="md">
                                  Download Manifest
                                </Text>
                              </HStack>
                            </Menu.Item>
                            {/* } */}
                          </Menu>
                        </Box>
                      </Stack>
                    </Box>
                    } initialEntered>
                      {raw.tf_detail.length > 0 &&
                      <Flex m={3}>
                        <ImageSlider key={i} images={raw.tf_detail[0]?.tf_det_attach}/>
                      </Flex>
                      }
                      <VStack backgroundColor="white" p={3}>
                        <FormControl mb="1">
                          <FormControl.Label>Jenis Limbah</FormControl.Label>
                          <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                            <Text textAlign="left">{raw.ms_jns_limbah?.nama}</Text>
                          </Box>
                        </FormControl>
                        <FormControl mb="1">
                          <FormControl.Label>Volume (L)</FormControl.Label>
                          <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                            <Text textAlign="right">
                              {raw.volume_real ? raw.volume_real : raw.volume}
                            </Text>
                          </Box>
                        </FormControl>
                        <FormControl mb="1">
                          <FormControl.Label>Berat (kg)</FormControl.Label>
                          <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                            <Text textAlign="right">
                              {raw.berat_real ? raw.berat_real : raw.berat}
                            </Text>
                          </Box>
                        </FormControl>
                        <FormControl mb="3">
                          <FormControl.Label>Noted</FormControl.Label>
                          <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                          {raw.tf_detail.length > 0 &&
                            <Text>
                              {raw.tf_detail[0]?.noted}
                            </Text>
                          }

                          {(raw.tf_detail.length == 0 && raw.ambil_detail.length > 0) &&
                            <Text>
                              {raw.ambil_detail[0]?.noted}
                            </Text>
                          }
                          </Box>
                        </FormControl>
                        </VStack>
                    </AccordionItem>
                  ))}
                  </Accordion>
                </Box>
              </VStack>
              </LoadingOverlay>
            </ScrollView>
          </Flex>
        </ContainerPage>
      );
    
}

const mapStateToProps = createStructuredSelector({
  manifestDetailScreen: makeSelectManifestDetailScreen(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onAddImage: asset => dispatch(addImage(asset)),
    onDelImage: idx => dispatch(delImage(idx)),
    onSubmitPelaporan: (id_branch, origin_screen) => dispatch(submitPelaporan(id_branch, origin_screen)),
    onGetDetail: (id_ba) => dispatch(getDetail(id_ba)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(ManifestDetailScreen);