import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function updateInput({ name, value }) {
  return {
    type: constants.UPDATE_INPUT,
    name,
    value
  };
}

export function getPostAnnounce() {
  return {
    type: constants.GET_POST_ANNOUNCE
  };
}

export function getPostAnnounceLoading() {
  return {
    type: constants.GET_POST_ANNOUNCE_LOADING
  };
}

export function getPostAnnounceFail(error) {
  return {
    type: constants.GET_POST_ANNOUNCE_FAIL,
    error
  };
}

export function getPostAnnounceSuccess(results) {
  return {
    type: constants.GET_POST_ANNOUNCE_SUCCESS,
    results
  };
}

export function getMorePostAnnounce() {
  return {
    type: constants.GET_MORE_POST_ANNOUNCE
  };
}

export function getMorePostAnnounceLoading() {
  return {
    type: constants.GET_MORE_POST_ANNOUNCE_LOADING
  };
}

export function getMorePostAnnounceFail(error) {
  return {
    type: constants.GET_MORE_POST_ANNOUNCE_FAIL,
    error
  };
}

export function getMorePostAnnounceSuccess(results) {
  return {
    type: constants.GET_MORE_POST_ANNOUNCE_SUCCESS,
    results
  };
}

export function delPostAnnnounce(id_pengumuman) {
  return {
    type: constants.DELETE_PENGUMUMAN,
    id_pengumuman
  };
}

export function delPostAnnnounceLoading() {
  return {
    type: constants.DELETE_PENGUMUMAN_LOADING
  };
}

export function delPostAnnnounceFail(error) {
  return {
    type: constants.DELETE_PENGUMUMAN_FAIL,
    error
  };
}

export function delPostAnnnounceSuccess(results) {
  return {
    type: constants.DELETE_PENGUMUMAN_SUCCESS,
    results
  };
}