import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
import api from '../../util/api';
import helper from '../../util/helper';
import history from '../../util/history';
// import * as RootNavigation from '../../../RootNavigation';
import { getPelaporanFail, getPelaporanSuccess, getUnitAnnounceDetailFail, getUnitAnnounceDetailSuccess, submitPelaporanFail, submitPelaporanSuccess, submitUnitAnnounceFail, submitUnitAnnounceSuccess } from './actions';

  import * as constants from './constants';
  
  import makeSelectUnitAnnounceFormScreen from './selector';
  
  export function* doSubmitUnitAnnounce(action) {
    const {id_branch, origin_screen} = action;
    const dataset = yield select(makeSelectUnitAnnounceFormScreen());
    try {
      const params = {
          id_pengumuman_req: dataset.id_pengumuman_req,
          id_unit: dataset.id_unit,
          id_lokasi: dataset.id_lokasi,
          id: dataset.id_pengumuman,
          noted: dataset.notes,
          pengumuman_detail: JSON.stringify(dataset.saved_details),
      };

      // console.log(params);
      if(helper.isEmpty(params.noted)) delete params.noted;

      const response = yield call(api.unit_announce.submitRequest, params);
      yield put(
        submitUnitAnnounceSuccess(response.data),
      );
      helper.successToast("Data berhasil disimpan");
      console.log("res:", response.data);

      setTimeout(() => {
        api.clearItemLocalDB('unitAnnounceDetail');
        api.clearItemLocalDB('unitAnnounce');
        history.replace('unit-announce');
      });
      
  
    } catch (err) {
      console.log(err);
      helper.errorToast("Gagal Menyimpan data, silahkan hub admin");
      yield put(submitUnitAnnounceFail(err));
    }
  }

  export function* doGetPengumumanDetail(action) {
    const dataset = yield select(makeSelectUnitAnnounceFormScreen());
    try {

      const params = {
        id_pengumuman: action.id_pengumuman
      };

      const response = yield call(api.unit_announce.getRequestDetail, params);
      const {success} = response.data;
  
      yield put(
        getUnitAnnounceDetailSuccess(success),
      );
    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      yield put(getUnitAnnounceDetailFail(err));
    }
  }

  export function* doSaveDetail(action) {
    const {idx_detail} = action;
    const dataset = yield select(makeSelectUnitAnnounceFormScreen());
    try {
      const cur_details = dataset.saved_details;
      console.log('saga_cur_details', cur_details)
      api.saveToLocalDB('unitAnnounceDetail', [...cur_details]);
  
    } catch (err) {
      console.log(err);
      helper.errorToast("Gagal Menyimpan data, silahkan hub admin");
    }
  }

  export function* doSetEditData(action) {
    const {idx_detail} = action;
    const dataset = yield select(makeSelectUnitAnnounceFormScreen());
    try {
      const cur_details = dataset.saved_details;
      console.log('saga_cur_details', cur_details)
      api.saveToLocalDB('unitAnnounceDetail', [...cur_details]);
      api.saveToLocalDB('unitAnnounce', {
        id_pengumuman: dataset.id_pengumuman,
        id_unit: dataset.id_unit,
        id_lokasi: dataset.id_lokasi,
        id_pengumuman_req: dataset.id_pengumuman_req,
        notes: dataset.notes,
        code_pengumuman: dataset.code_pengumuman,
      });
  
    } catch (err) {
      console.log(err);
      helper.errorToast("Gagal Menyimpan data, silahkan hub admin");
    }
  }

  // Individual exports for testing
  export default function* unitAnnounceFormScreenSaga() {
    yield all([
      takeLatest(constants.SUBMIT_UNIT_ANNOUNCE, doSubmitUnitAnnounce),
      takeLatest(constants.GET_PENGUMUMAN_DETAIL, doGetPengumumanDetail),
      takeLatest(constants.DEL_SAVED_DETAIL, doSaveDetail),
      takeLatest(constants.GET_PENGUMUMAN_DETAIL_SUCCESS, doSetEditData),
      takeLatest(constants.SET_SAVED_DETAIL, doSetEditData),
    ]);
  }
  