import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function updateInput({ name, value }) {
  return {
    type: constants.UPDATE_INPUT,
    name,
    value
  };
}

export function getTransferWaste() {
  return {
    type: constants.GET_TRANSFER_WASTE
  };
}

export function getTransferWasteLoading() {
  return {
    type: constants.GET_TRANSFER_WASTE_LOADING
  };
}

export function getTransferWasteFail(error) {
  return {
    type: constants.GET_TRANSFER_WASTE_FAIL,
    error
  };
}

export function getTransferWasteSuccess(results) {
  return {
    type: constants.GET_TRANSFER_WASTE_SUCCESS,
    results
  };
}

export function getMoreTransferWaste() {
  return {
    type: constants.GET_MORE_TRANSFER_WASTE
  };
}

export function getMoreTransferWasteLoading() {
  return {
    type: constants.GET_MORE_TRANSFER_WASTE_LOADING
  };
}

export function getMoreTransferWasteFail(error) {
  return {
    type: constants.GET_MORE_TRANSFER_WASTE_FAIL,
    error
  };
}

export function getMoreTransferWasteSuccess(results) {
  return {
    type: constants.GET_MORE_TRANSFER_WASTE_SUCCESS,
    results
  };
}

export function delWasteTransfer(id_transfer) {
  return {
    type: constants.DELETE_WASTE_TRANSFER,
    id_transfer
  };
}

export function delWasteTransferLoading() {
  return {
    type: constants.DELETE_WASTE_TRANSFER_LOADING
  };
}

export function delWasteTransferFail(error) {
  return {
    type: constants.DELETE_WASTE_TRANSFER_FAIL,
    error
  };
}

export function delWasteTransferSuccess(results) {
  return {
    type: constants.DELETE_WASTE_TRANSFER_SUCCESS,
    results
  };
}