import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the WasteTransferFormScreen state domain
 */

const selectWasteTransferFormScreenDomain = state =>
  state.wasteTransferFormScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by WasteTransferFormScreen
 */

const makeSelectWasteTransferFormScreen = () =>
  createSelector(
    selectWasteTransferFormScreenDomain,
    substate => substate,
  );

export default makeSelectWasteTransferFormScreen;
export { selectWasteTransferFormScreenDomain };
