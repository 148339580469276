import * as React from 'react';
import { Badge, Box, Flex, VStack, Image, Text, AspectRatio, Center, Icon } from 'native-base';
import {FaChevronRight, FaChevronLeft} from 'react-icons/fa';
// import Carousel, {Pagination} from 'react-native-snap-carousel';
import Carousel from "react-multi-carousel";
import { commonStyle } from '../styles';

export default class ImageSlider extends React.Component {

    constructor(props){
        super(props);
        this.state = {
          activeIndex:0,
      }
    }

    _renderItem({item, index}){
        return (
          <Center key={`imgx-${index}`}>
            <Box flex={1} width={200} p={1} backgroundColor={commonStyle.baseColor} borderColor="muted.400" borderWidth={1} borderRadius="md">
              <Flex justifyContent="center" alignItems="center">
                {/* <AspectRatio 
                ratio={{
                  base: 3 / 4,
                }} height={{
                  base: 200,
                }}> */}
                  <Image height={300} width={200} source={{uri: item.path}} fallbackSource={require('../images/no-image.jpg')}   alt='Room' resizeMode='contain' />
                {/* </AspectRatio> */}
              </Flex>
            </Box>
          </Center>
        )
    }

  //   get pagination () {
  //     const { activeIndex } = this.state;
  //     const { images } = this.props;
  //     return (
  //         <Pagination
  //           dotsLength={images.length}
  //           activeDotIndex={activeIndex}
  //           containerStyle={{ paddingTop:10, paddingBottom:10}}
  //           dotStyle={{
  //               width: 10,
  //               height: 10,
  //               borderRadius: 5,
  //               backgroundColor: commonStyle.primaryColor
  //           }}
  //           inactiveDotStyle={{
  //               // Define styles for inactive dots here
  //           }}
  //           inactiveDotOpacity={0.4}
  //           inactiveDotScale={1}
  //         />
  //     );
  // }

    render() {
        const responsiveSlider = {
          desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
          },
          tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
          },
          mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
          }
        };

        const arrowStyle = {
          background: "transparent",
          border: 0,
          color: "#aeaeae",
          fontSize: "80px",
        };

        const CustomRight = ({ onClick }) => (
          <button className="slider-arrow ctrl-right" onClick={onClick} style={arrowStyle}>
            <FaChevronRight style={{ fontSize: "20px" }} />
          </button>
        );
        const CustomLeft = ({ onClick }) => (
          <button className="slider-arrow ctrl-left" onClick={onClick} style={arrowStyle}>
            <FaChevronLeft style={{ fontSize: "20px" }} />
          </button>
        );

        return (
          // <SafeAreaView style={{flex: 1, paddingTop: 10 }}>
          //   <View style={{ flex: 1, flexDirection:'row', justifyContent: 'center'}}>
          //       <Carousel
          //         layout={"stack"}
          //         ref={ref => this.carousel = ref}
          //         data={this.props.images}
          //         sliderWidth={350}
          //         itemWidth={250}
          //         renderItem={this._renderItem}
          //         onSnapToItem = { index => this.setState({activeIndex:index}) } />
          //   </View>
          //   <View>
          //     { this.pagination }
          //   </View>
          // </SafeAreaView>
          <React.StrictMode>
            <Carousel
                swipeable={true}
                draggable={false}
                showDots={false}
                responsive={responsiveSlider}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlaySpeed={1000}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={500}
                containerClass="carousel-container"
                arrows={true}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
                customLeftArrow={<CustomLeft/>}
                customRightArrow={<CustomRight/>}
              >
                {this.props.images.map((item, index) => this._renderItem({item, index}))}
            </Carousel>
          </React.StrictMode>
        );
    }
}