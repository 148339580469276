import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the WasteAcceptanceScreen state domain
 */

const selectWasteAcceptanceScreenDomain = state =>
  state.wasteAcceptanceScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by WasteAcceptanceScreen
 */

const makeSelectWasteAcceptanceScreen = () =>
  createSelector(
    selectWasteAcceptanceScreenDomain,
    substate => substate,
  );

export default makeSelectWasteAcceptanceScreen;
export { selectWasteAcceptanceScreenDomain };
