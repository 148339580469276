export const DEFAULT_ACTION = 'screen/WastePickupScreen/DEFAULT_ACTION';
export const UPDATE_INPUT = 'screen/WastePickupScreen/UPDATE_INPUT';

export const GET_WASTE_PICKUP = 'screen/WastePickupScreen/GET_WASTE_PICKUP';
export const GET_WASTE_PICKUP_LOADING = 'screen/WastePickupScreen/GET_WASTE_PICKUP_LOADING';
export const GET_WASTE_PICKUP_SUCCESS = 'screen/WastePickupScreen/GET_WASTE_PICKUP_SUCCESS';
export const GET_WASTE_PICKUP_FAIL = 'screen/WastePickupScreen/GET_WASTE_PICKUP_FAIL';

export const GET_MORE_WASTE_PICKUP = 'screen/WastePickupScreen/GET_MORE_WASTE_PICKUP';
export const GET_MORE_WASTE_PICKUP_LOADING = 'screen/WastePickupScreen/GET_MORE_WASTE_PICKUP_LOADING';
export const GET_MORE_WASTE_PICKUP_SUCCESS = 'screen/WastePickupScreen/GET_MORE_WASTE_PICKUP_SUCCESS';
export const GET_MORE_WASTE_PICKUP_FAIL = 'screen/WastePickupScreen/GET_MORE_WASTE_PICKUP_FAIL';

export const DEL_WASTE_PICKUP = 'screen/WastePickupScreen/DEL_WASTE_PICKUP';
export const DEL_WASTE_PICKUP_LOADING = 'screen/WastePickupScreen/DEL_WASTE_PICKUP_LOADING';
export const DEL_WASTE_PICKUP_SUCCESS = 'screen/WastePickupScreen/DEL_WASTE_PICKUP_SUCCESS';
export const DEL_WASTE_PICKUP_FAIL = 'screen/WastePickupScreen/DEL_WASTE_PICKUP_FAIL';