import React from "react";
import { commonStyle } from "../styles";
import {
    Icon,
    HStack, 
    Avatar, 
    Heading, 
    VStack, 
    Box, 
    Center,
    Stack,
    Badge,
    Input,
    Image,
    IconButton,
    Pressable,
    Divider,
    HamburgerIcon,
    Menu,
    Text, 
    Flex } from 'native-base';
import Skeleton from 'react-loading-skeleton';

const CardListPreloader = (props) => {
  const {data} = props;
  
  return (
    <Box width="100%" minW="300px" backgroundColor="white" opacity={0.8} borderRadius="lg">
      <VStack>
        <Box p={5}>
          <VStack space={3}>
            <Skeleton height={30} />
            <Skeleton height={30} />
            <Skeleton height={30} />
            <HStack space={3}>
              <Box>
                <Skeleton
                    circle
                    style={{
                      height:'55px',
                      width:'55px',
                    }}
                />
              </Box>
              <Box flex={1} justifyContent="center">
                <Skeleton style={{height: '40px'}} />
              </Box>
            </HStack>
          </VStack>
        </Box>
      </VStack>
  </Box>
  );
}

export default CardListPreloader;