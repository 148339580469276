import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function updateInput({ name, value }) {
  return {
    type: constants.UPDATE_INPUT,
    name,
    value
  };
}

export function getLimbahUnit() {
  return {
    type: constants.GET_LIMBAH_UNIT
  };
}

export function getLimbahUnitLoading() {
  return {
    type: constants.GET_LIMBAH_UNIT_LOADING
  };
}

export function getLimbahUnitFail(error) {
  return {
    type: constants.GET_LIMBAH_UNIT_FAIL,
    error
  };
}

export function getLimbahUnitSuccess(results) {
  return {
    type: constants.GET_LIMBAH_UNIT_SUCCESS,
    results
  };
}

export function getMoreLimbahUnit() {
  return {
    type: constants.GET_MORE_LIMBAH_UNIT
  };
}

export function getMoreLimbahUnitLoading() {
  return {
    type: constants.GET_MORE_LIMBAH_UNIT_LOADING
  };
}

export function getMoreLimbahUnitFail(error) {
  return {
    type: constants.GET_MORE_LIMBAH_UNIT_FAIL,
    error
  };
}

export function getMoreLimbahUnitSuccess(results) {
  return {
    type: constants.GET_MORE_LIMBAH_UNIT_SUCCESS,
    results
  };
}