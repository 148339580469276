import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the ApprovalScreen state domain
 */

const selectApprovalScreenDomain = state =>
  state.approvalScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by ApprovalScreen
 */

const makeSelectApprovalScreen = () =>
  createSelector(
    selectApprovalScreenDomain,
    substate => substate,
  );

export default makeSelectApprovalScreen;
export { selectApprovalScreenDomain };
