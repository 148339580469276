import React, { Component, useState, useEffect, forwardRef } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import makeSelectPostAnnounceFormScreen from './selector';
import reducer from './reducer';
import saga from './saga';

import {
  AspectRatio,
  Icon,
  HStack, 
  Avatar, 
  Heading, 
  FormControl,
  Input,
  TextArea,
  VStack, 
  Box, 
  Center,
  Text,
  Image, 
  Flex,
  ScrollView, 
  Button,
  CheckIcon,
  Select,
  Stack,
  Menu,
  Pressable,
  Fab} from 'native-base';
import {FaInfoCircle, FaUserCircle} from 'react-icons/fa';
import { ContainerPage } from '../../components/ContainerPage';
import { commonStyle } from '../../styles';
import { defaultAction, delSavedDetail, getPegawai, getPengumuman, setSavedDetail, submitPegawai, submitPengumuman, updateInput } from './actions';
import api from '../../util/api';
import helper from '../../util/helper';
import { MdCalendarToday, MdDelete, MdFileCopy, MdModeEdit, MdMoreVert, MdRemoveRedEye } from 'react-icons/md';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import BadgeStatus from '../../components/BadgeStatus';
import ImageSlider from '../../components/ImageSlider';
import { AiOutlinePlus } from 'react-icons/ai';
import history from '../../util/history';
import MyAlertDialog from '../../components/MyAlertDialog';
import MyAlert from '../../components/MyAlert';
import LoadingOverlay from 'react-loading-overlay';

export function PostAnnounceFormScreen({
  dispatch,
  location,
  postAnnounceFormScreen,
  onUpdateInput,
  onGetPengumuman,
  onSubmitPengumuman,
  onSetDetail,
  onDelDetail
}){
  const {id_pengumuman, viewOnly, new_data} = location.state;
  const[userData, setUserData] = useState();
  const [screenAccess, setScreenAccess] = useState([]);
  const [isOpenDialog, setOpenDialog] = useState(false);
  const [isOpenDialogSubmit, setOpenDialogSubmit] = useState(false);
  const[idxDetail, setIdxDetail] = useState();

  const imageList = [
    {path: '../../images/pintu.jpg'},
    {path: '../../images/pintu.jpg'},
    {path: '../../images/pintu.jpg'},
  ];

  useEffect(() => {
    const menus = api.getUserPrivileges();
    const allAccess = helper.generatePrivileges(menus);
    setScreenAccess(allAccess);
    // reset params
    dispatch(defaultAction());
    //EDIT STATE
    if(!helper.isEmpty(id_pengumuman)){
      onUpdateInput('id_pengumuman', id_pengumuman);
      const curdataReq = api.getFromLocalDB('postAnnounce');
      curdataReq.then((val) => {
        console.log('saved_permintaan', val);
        if(helper.isEmpty(val) || id_pengumuman != val?.id_pengumuman){
          // get data if localDB is empty
          onGetPengumuman(id_pengumuman);
        } else {
          // get data from localDB
          onUpdateInput('post_date', val.post_date);
          onUpdateInput('expired_date', val.expired_date);
          onUpdateInput('notes', val.notes);
        }
      }).catch(err => {
        console.log('err-localdb:', err)
      });

      const curdataDetail = api.getFromLocalDB('postAnnounceDetail');
      curdataDetail.then((val) => {
        console.log('new_saved_details', val);
        if(val?.length > 0) onUpdateInput('saved_details',val);
      }).catch(err => {
        console.log('err-localdb:', err)
      });

    //ADD STATE
    } else {
      if(new_data){
        // clear for fresh data
        api.clearItemLocalDB('postAnnounceDetail');
        api.clearItemLocalDB('postAnnounce');
      } else {
        // get data from localDB
        const curdataReq = api.getFromLocalDB('postAnnounce');
        curdataReq.then((val) => {
            // get data from localDB
            if(!helper.isEmpty(val)){
              onUpdateInput('post_date', val.post_date);
              onUpdateInput('expired_date', val.expired_date);
              onUpdateInput('notes', val.notes);
            } 
        }).catch(err => {
          console.log('err-localdb:', err)
        });

        const curdata = api.getFromLocalDB('postAnnounceDetail');
        curdata.then((val) => {
          console.log('new_saved_details', val);
          if(val?.length > 0) onUpdateInput('saved_details',val);
        }).catch(err => {
          console.log('err-localdb:', err)
        });
      } 
      
    }
  },[]);

  const InputDate = forwardRef(({ value, onClick }, ref) => (
    <Input 
      value={moment(postAnnounceFormScreen.post_date).format("DD/MM/YYYY HH:mm")} 
      isReadOnly="true"
      onFocus={onClick} 
      borderRadius="lg" 
      variant="filled"
      ref={ref} 
      borderColor="primary.900" 
      InputRightElement={<Icon as={<Box><MdCalendarToday/></Box>} size={5} mr="2" color="muted.400" />} 
      />
  ));

  const InputDateExp = forwardRef(({ value, onClick }, ref) => (
    <Input 
      value={moment(postAnnounceFormScreen.expired_date).format("DD/MM/YYYY HH:mm")} 
      isReadOnly="true"
      onFocus={onClick} 
      borderRadius="lg" 
      variant="filled"
      ref={ref} 
      borderColor="primary.900" 
      InputRightElement={<Icon as={<Box><MdCalendarToday/></Box>} size={5} mr="2" color="muted.400" />} 
      />
  ));

  const onChangeDt = (name_field, selectedDate) => {
    const currentDate = selectedDate;
    onUpdateInput(name_field, currentDate);
  };

  const handleSubmit = () => {
    // if(helper.isEmpty(postAnnounceFormScreen.notes) || postAnnounceFormScreen.notes.length < 10){
    //   helper.warningToast("Isikan deskripsi permintaan, Minimal 10 karakter.");
    //   return;
    // }

    if(helper.isEmpty(postAnnounceFormScreen.saved_details) || postAnnounceFormScreen.saved_details.length == 0){
      helper.warningToast("Isikan detail permintaan, Minimal 1.");
      return;
    }

    setOpenDialogSubmit(false);
    onSubmitPengumuman();
  }

  const handleOnDeleteDetail = (idx) => {
    console.log('idx-detail:', idx);
    setIdxDetail(idx);
    setOpenDialog(true);
  }

  const handleDoDeleteDetail = () => {
    onDelDetail(idxDetail);
    setOpenDialog(false);
  }

  const handleGotDetail = (act, params) => {
    onSetDetail();
    history.push('post-announce-form-detail', params);
  }

  return (
        <ContainerPage options={{statusBar: true, prevPage: 'post-announce', title: 'Kirim Pengumuman'}}>
          <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
          <LoadingOverlay
                active={postAnnounceFormScreen.loading}
                spinner
                text='Loading...'
                >
            <Flex>
              {/* <Box mt={4}>
                <Flex flexDirection="row" justifyContent="center" alignItems="center">
                  <Box shadow={3} borderColor="amber.100" borderRadius={20}>
                    <Image source={{
      uri: "https://images.unsplash.com/photo-1603415526960-f7e0328c63b1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
    }} borderRadius={20} alt='Room' size="lg"  />
                  </Box>
                  <Box ml={3}>
                    <Button variant="outline" colorScheme="primary" leftIcon={<Icon as={MaterialIcons} name="add-a-photo" size="sm" />}>Ganti Foto Profil</Button>
                  </Box>
                </Flex>
              </Box> */}
              <Box mx={3} mt={5} p={4} backgroundColor="white" shadow={3} borderRadius="lg">
                <HStack space={2}>
                  <Icon as={<Box><FaInfoCircle/></Box>} size="5" mt="0.5" color="muted.400" />
                  <Text color="darkText" fontSize="md">
                    Form Pengumuman
                  </Text>
                </HStack>
                <Box>
                  <VStack space={2} mt={2}>
                    <Box>
                        <FormControl mb="1" isRequired>
                          <FormControl.Label>Tanggal Pengumuman</FormControl.Label>
                          <DatePicker showTimeSelect customInput={<InputDate/>} portalId='root-portal' className='input-datepicker' popperClassName='pooper-datepicker' selected={postAnnounceFormScreen.post_date} onChange={(date) => onChangeDt('post_date', date)} />
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl mb="1" isRequired>
                          <FormControl.Label>Tanggal Expired</FormControl.Label>
                          <DatePicker showTimeSelect customInput={<InputDateExp/>} portalId='root-portal' className='input-datepicker' popperClassName='pooper-datepicker' selected={postAnnounceFormScreen.expired_date} onChange={(date) => onChangeDt('expired_date', date)} />
                        </FormControl>
                    </Box>
                    <Box>
                      <FormControl mb="1">
                        <FormControl.Label>Deskripsi</FormControl.Label>
                        <TextArea spellCheck={false} h="56" value={postAnnounceFormScreen.notes} onChangeText={txt => onUpdateInput('notes', txt)} placeholder="Isi Pengumuman" borderRadius="lg" w="100%" />
                      </FormControl>
                    </Box>
                  </VStack>
                </Box>
              </Box>
              {postAnnounceFormScreen.saved_details.length == 0 && 
                <Box m={3} p={4} borderRadius="lg" shadow={3} backgroundColor="white">
                  <MyAlert status="info" title="Detail kosong, Tambahkan detail permintaan!"/>
                </Box>
              }
              {postAnnounceFormScreen.saved_details.length > 0 &&
              <Box mx={3} mt={5}>
                  <Accordion>
                  {postAnnounceFormScreen.saved_details.map((raw, i) => (
                    <AccordionItem key={i} header={
                      <Box px={5} py={3} backgroundColor={commonStyle.cardBaseHeaderBg}>
                      <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Box>
                          <Icon color="darkText" as={<Box><MdFileCopy/></Box>} size={5}></Icon>
                        </Box>
                        <Box flex={1} ml={1} alignItems="start">
                          <Text color="darkText" fontWeight={500} >
                            {/* {moment(data.date_report, "YYYY-MM-DD HH:mm:ss").format("DD MMM YYYY HH:mm")} */}
                            Detail Permintaan
                          </Text>
                        </Box>
                        <Box ml={3}>
                          <BadgeStatus status="Created"/>
                        </Box>
                        {!viewOnly &&
                        <Box ml={2} alignItems="center" justifyContent="center" width={8} height={8}>
                          <Menu mt={2} py={2} placement="bottom right" trigger={triggerProps => {
                            return <Pressable accessibilityLabel="More options menu" {...triggerProps}>
                                    <Icon as={<Box><MdMoreVert/></Box>} size={8} color="gray.600" />
                                  </Pressable>;
                          }}>
                            <Menu.Item p={1} onPress={() => handleGotDetail('view', {readOnly: true, idx_detail: i, id_pengumuman: postAnnounceFormScreen.id_pengumuman})}>
                              <HStack space={2} justifyContent="center" alignItems="center">
                                <Icon as={<Box><MdRemoveRedEye/></Box>} size="md" color="info.700" />
                                <Text color="darkText" fontSize="md">
                                  View
                                </Text>
                              </HStack>
                            </Menu.Item>
                            <Menu.Item p={1} onPress={() => handleGotDetail('edit', {readOnly: false, idx_detail: i, id_pengumuman: postAnnounceFormScreen.id_pengumuman})}>
                              <HStack space={2} justifyContent="center" alignItems="center">
                                <Icon as={<Box><MdModeEdit/></Box>} size="md" color="info.700" />
                                <Text color="darkText" fontSize="md">
                                  Update Permintaan
                                </Text>
                              </HStack>
                            </Menu.Item>
                            <Menu.Item p={1} onPress={() => handleOnDeleteDetail(i)}>
                              <HStack space={2} justifyContent="center" alignItems="center">
                                <Icon as={<Box><MdDelete/></Box>} size="md" color="info.700" />
                                <Text color="darkText" fontSize="md">
                                  Hapus Permintaan
                                </Text>
                              </HStack>
                            </Menu.Item>
                          </Menu>
                        </Box>
                        }
                      </Stack>
                    </Box>
                    } initialEntered>
                      <VStack backgroundColor="white" p={3}>
                        <FormControl mb="1">
                          <FormControl.Label>Unit</FormControl.Label>
                          <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                            <Text textAlign="left">{raw.nm_unit}</Text>
                          </Box>
                        </FormControl>
                        <FormControl mb="1">
                          <FormControl.Label>Lokasi</FormControl.Label>
                          <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                            <Text textAlign="left">{raw.nm_lokasi}</Text>
                          </Box>
                        </FormControl>
                        <FormControl mb="1">
                          <FormControl.Label>Noted</FormControl.Label>
                          <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                            <Text>{raw.noted}</Text>
                          </Box>
                        </FormControl>
                      </VStack>
                    </AccordionItem>
                  ))}
                  </Accordion>
                </Box>
              }
              {!viewOnly &&
              <HStack justifyContent="space-around" mb="32">
                  <Button w="1/3" isLoading={postAnnounceFormScreen.loading} spinnerPlacement="end" isLoadingText="Submitting..." colorScheme="primary" onPress={() => setOpenDialogSubmit(true)}>Kirim</Button>
                  <Button variant="link" onPress={() =>  history.replace('post-announce')} colorScheme="primary">Cancel</Button>
              </HStack>
              }
            </Flex>
            </LoadingOverlay>
          </ScrollView>

          <MyAlertDialog 
            alertTitle="Detail Permintaan" 
            alertDesc={`Apakah anda ingin menghapus permintaan ini ?`}
            alertNoLabel="Tidak"
            alertYesLabel="Hapus"
            colorScheme="danger"
            alertIsOpen={isOpenDialog}
            alertOnClose={() => setOpenDialog(false)}
            alertOnConfirm={() => handleDoDeleteDetail()}
          />

          <MyAlertDialog 
            alertTitle="Kirim Pengumuman" 
            alertDesc={`Apakah data sudah benar ?`}
            alertNoLabel="Tidak"
            alertYesLabel="Kirim"
            colorScheme="success"
            alertIsOpen={isOpenDialogSubmit}
            alertOnClose={() => setOpenDialogSubmit(false)}
            alertOnConfirm={() => handleSubmit()}
          />

          {(screenAccess.includes("8:ADD") && !viewOnly) &&
            <Fab onPress={() => handleGotDetail('add','')} renderInPortal={false} shadow={2} size="lg" icon={<Icon color="white" as={<Box><AiOutlinePlus/></Box>} size="lg" />} />
          }
        </ContainerPage>
      );
    
}

const styles = {
  errorTextStyle: {
    color: 'red',
    fontStyle: 'italic',
    fontWeight: 'bold',
    alignSelf: 'center'
  }
};

const mapStateToProps = createStructuredSelector({
  postAnnounceFormScreen: makeSelectPostAnnounceFormScreen(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onGetPengumuman: id => dispatch(getPengumuman(id)),
    onSubmitPengumuman: () => dispatch(submitPengumuman()),
    onSetDetail: () => dispatch(setSavedDetail()),
    onDelDetail: idx => dispatch(delSavedDetail(idx)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(PostAnnounceFormScreen);