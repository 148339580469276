export const DEFAULT_ACTION = 'screen/UnitRequestFormScreen/DEFAULT_ACTION';
export const UPDATE_INPUT = 'screen/UnitRequestFormScreen/UPDATE_INPUT';

export const SUBMIT_PERMINTAAN = 'screen/UnitRequestFormScreen/SUBMIT_PERMINTAAN';
export const SUBMIT_PERMINTAAN_LOADING = 'screen/UnitRequestFormScreen/SUBMIT_PERMINTAAN_LOADING';
export const SUBMIT_PERMINTAAN_SUCCESS = 'screen/UnitRequestFormScreen/SUBMIT_PERMINTAAN_SUCCESS';
export const SUBMIT_PERMINTAAN_FAIL = 'screen/UnitRequestFormScreen/SUBMIT_PERMINTAAN_FAIL';

export const ADD_IMAGE = 'screen/UnitRequestFormScreen/ADD_IMAGE';
export const DEL_IMAGE = 'screen/UnitRequestFormScreen/DEL_IMAGE';

export const GET_PERMINTAAN = 'screen/UnitRequestFormScreen/GET_PERMINTAAN';
export const GET_PERMINTAAN_LOADING = 'screen/UnitRequestFormScreen/GET_PERMINTAAN_LOADING';
export const GET_PERMINTAAN_SUCCESS = 'screen/UnitRequestFormScreen/GET_PERMINTAAN_SUCCESS';
export const GET_PERMINTAAN_FAIL = 'screen/UnitRequestFormScreen/GET_PERMINTAAN_FAIL';

export const DEL_SAVED_DETAIL = 'screen/UnitRequestFormScreen/DEL_SAVED_DETAIL';
export const SET_SAVED_DETAIL = 'screen/UnitRequestFormScreen/SET_SAVED_DETAIL';