/*
 *
 * WasteAcceptanceFormDetailScreen reducer
 *
 */
import {produce} from 'immer';
import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';
import helper from '../../util/helper';

export const initialState = {
    error: 0,
    error_msg: '',
    loading: false,
    loading_submit: false,
    isSuccess: false,
    dtPenerimaan: {
      transfer_limbah_detail:[]
    },
    dtTransfer: {
      transfer_limbah_detail:[]
    },
    id_detail:'',
    dtDetail: null,
    id_penerimaan: 0,
    code:'',
    noted:'',
    id_transfer: '',
    jenis_limbah: null,
    id_jenis_limbah: '',
    id_approval_detail: '',
    volume:'',
    berat:'',
    no_kendaraan:'',
    assets:[],
    locationState: null
};

/* eslint-disable default-case, no-param-reassign */
const WasteAcceptanceFormDetailScreenReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case DEFAULT_ACTION:
      return {...initialState};
      case constants.UPDATE_INPUT:
        draft[action.name] = action.value;
        break;
      
      case constants.GET_DETAIL:
        draft.loading = true;
        break;
      case constants.GET_DETAIL_FAIL:
        draft.error = true;
        draft.loading = false;
        draft.dtTodo = null;
        draft.error_msg= 'Maaf, Hasil pencarian belum ditemukan.';
        break;
      case constants.GET_DETAIL_SUCCESS:
        if(!helper.isEmpty(action.results)){
          const objDetail = action.results;
          draft.dtPenerimaan = objDetail.dtPenerimaanLimbah;
          draft.dtTransfer = objDetail.dtTransfer;
          
          if(!helper.isEmpty(objDetail.dtPenerimaanLimbah)){
            draft.code = objDetail.dtPenerimaanLimbah.code;
            const idxDetail = objDetail.dtPenerimaanLimbah?.penerimaan_limbah_detail.findIndex(
              result => result.id_transfer_limbah_detail === draft.id_detail,
            );

            if(idxDetail >= 0){
              const objRawDetail = objDetail.dtPenerimaanLimbah.penerimaan_limbah_detail[idxDetail];
              draft.assets = objRawDetail.pen_det_attach;
              draft.dtDetail = objRawDetail;
              draft.id_jenis_limbah = objRawDetail.id_jenis_limbah;
              draft.volume = objRawDetail.volume;
              draft.berat = objRawDetail.berat;
              draft.noted = objRawDetail.noted;
              draft.no_kendaraan = objDetail.dtTransfer.no_kendaraan;
            }else{
              const idxDetailTrf__ = objDetail.dtTransfer.transfer_limbah_detail.findIndex(
                result => result.id === draft.id_detail,
              );
  
              if(idxDetailTrf__ >= 0){
                const objRawDetaiTrf__ = objDetail.dtTransfer.transfer_limbah_detail[idxDetailTrf__];
                draft.dtDetail = objRawDetaiTrf__;
                draft.id_jenis_limbah = objRawDetaiTrf__.id_jenis_limbah;
                draft.volume = objRawDetaiTrf__.volume;
                draft.berat = objRawDetaiTrf__.berat;
                draft.description = objRawDetaiTrf__.noted;
                draft.no_kendaraan = objDetail.dtTransfer.no_kendaraan;
              }
            }

            console.log('idx-first', draft.id_detail, idxDetail);

          } else  {
            draft.code = objDetail.dtTransfer.code;
            const idxDetailTrf_ = objDetail.dtTransfer?.transfer_limbah_detail.findIndex(
              result => result.id === draft.id_detail,
            );

            if(idxDetailTrf_ >= 0){
              const objRawDetailTrf = objDetail.dtTransfer?.transfer_limbah_detail[idxDetailTrf_];
              draft.dtDetail = objRawDetailTrf;
              draft.id_jenis_limbah = objRawDetailTrf.id_jenis_limbah;
              draft.volume = objRawDetailTrf.volume;
              draft.berat = objRawDetailTrf.berat;
              draft.description = objRawDetailTrf.noted;
              draft.no_kendaraan = objDetail.dtTransfer.no_kendaraan;
            }
          }
        }
        draft.error = false;
        draft.loading = false;
        break;
      case constants.ADD_IMAGE: {
        let cur_arr = draft.assets;
        const image = action.asset;
        cur_arr.push({path: image, status: true});
        draft.assets = cur_arr;
        }
        break;
      case constants.DEL_IMAGE:
        draft.assets = [...draft.assets.slice(0, action.idx), ...draft.assets.slice(action.idx + 1)];
        break;
      case constants.SUBMIT_PENERIMAAN:
        draft.loading_submit = true;
        break;
      case constants.SUBMIT_PENERIMAAN_FAIL:
        draft.error = true;
        draft.loading_submit = false;
        draft.error_msg= 'Maaf, Hasil pencarian belum ditemukan.';
        break;
      case constants.SUBMIT_PENERIMAAN_SUCCESS:
        draft.preventif = action.results;
        draft.loading_submit = false;
        draft.isSuccess = true;
        draft.error = false;
        break;
    }
  });

export default WasteAcceptanceFormDetailScreenReducer;
