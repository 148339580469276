import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function updateInput({ name, value }) {
  return {
    type: constants.UPDATE_INPUT,
    name,
    value
  };
}

export function getDetail(id_transfer, id_approval, approval_code) {
  return {
    type: constants.GET_DETAIL,
    id_transfer,
    id_approval, 
    approval_code
  };
}

export function getDetailLoading() {
  return {
    type: constants.GET_DETAIL_LOADING
  };
}

export function getDetailFail(error) {
  return {
    type: constants.GET_DETAIL_FAIL,
    error
  };
}

export function getDetailSuccess(results) {
  return {
    type: constants.GET_DETAIL_SUCCESS,
    results
  };
}

export function addImage(asset) {
  return {
    type: constants.ADD_IMAGE,
    asset
  };
}

export function delImage(idx) {
  return {
    type: constants.DEL_IMAGE,
    idx
  };
}

export function submitTransfer() {
  return {
    type: constants.SUBMIT_TRANSFER,
  };
}

export function submitTransferFail(error) {
  return {
    type: constants.SUBMIT_TRANSFER_FAIL,
    error
  };
}

export function submitTransferSuccess(results) {
  return {
    type: constants.SUBMIT_TRANSFER_SUCCESS,
    results
  };
}