export const DEFAULT_ACTION = 'screen/WasteUnitScreen/DEFAULT_ACTION';
export const UPDATE_INPUT = 'screen/WasteUnitScreen/UPDATE_INPUT';

export const GET_LIMBAH_UNIT = 'screen/WasteUnitScreen/GET_LIMBAH_UNIT';
export const GET_LIMBAH_UNIT_LOADING = 'screen/WasteUnitScreen/GET_LIMBAH_UNIT_LOADING';
export const GET_LIMBAH_UNIT_SUCCESS = 'screen/WasteUnitScreen/GET_LIMBAH_UNIT_SUCCESS';
export const GET_LIMBAH_UNIT_FAIL = 'screen/WasteUnitScreen/GET_LIMBAH_UNIT_FAIL';

export const GET_MORE_LIMBAH_UNIT = 'screen/WasteUnitScreen/GET_MORE_LIMBAH_UNIT';
export const GET_MORE_LIMBAH_UNIT_LOADING = 'screen/WasteUnitScreen/GET_MORE_LIMBAH_UNIT_LOADING';
export const GET_MORE_LIMBAH_UNIT_SUCCESS = 'screen/WasteUnitScreen/GET_MORE_LIMBAH_UNIT_SUCCESS';
export const GET_MORE_LIMBAH_UNIT_FAIL = 'screen/WasteUnitScreen/GET_MORE_LIMBAH_UNIT_FAIL';