import React from 'react';
import { Flex, Box,HStack, Pressable,Center,Icon,Text,ScrollView } from 'native-base';
import {FaHome, FaHistory, FaTasks, FaUserCircle} from 'react-icons/fa';
import history from '../util/history';
import StatusBar from './StatusBar';
import api from '../util/api';
import helper from '../util/helper';

export const ContainerPage = (props) => {
    // const selected = 0;
    const { pathname } = window.location;
    const splitLocation = pathname.split("/");
    const opt = props.options;
    const menus = api.getUserPrivileges();
    const screenAccess = helper.generatePrivileges(menus);
    
    return (
      <Flex w={{
        sm: '100%',
        md: '100%',
        lg: 500,
        xl: 500,
      }} flexDirection="column" h="100vh">
        {(opt && opt.statusBar) && <StatusBar prevPage={opt.prevPage} params={opt.params} title={opt.title} enableAct={opt.enableAct} onPressAct={opt.onPressAction}/>}
        <Box p={3} flex={1}>
          {props.children}
        </Box>
        <Box bg="white" safeAreaTop width="100%" alignSelf="center" backgroundColor="#E4F4F0">
          <HStack bg="#f9fafb" alignItems="center" safeAreaBottom shadow={6} borderTopLeftRadius={20} borderTopRightRadius={20}>
            <Pressable onPress={() => history.push('home')} cursor="pointer" opacity={!["waste-unit","profile"].includes(splitLocation[1]) ? 1 : 0.5} py="3" flex={1}>
              <Center>
                <Icon mb="1" as={<Box><FaHome/></Box>} color="info.800" size="sm" />
                <Text color="info.800" fontSize="12">
                  Home
                </Text>
              </Center>
            </Pressable>
            {screenAccess.includes('10:ENB') && 
            <Pressable onPress={() => history.push('waste-unit')} cursor="pointer" opacity={["waste-unit"].includes(splitLocation[1]) ? 1 : 0.5} py="2" flex={1}>
              <Center>
                <Icon mb="1" as={<Box><FaHistory/></Box>} color="info.800" size="sm" />
                <Text color="info.800" fontSize="12">
                  Limbah Unit
                </Text>
              </Center>
            </Pressable>
            }
            {/* {screenAccess.includes('08:ENB') &&  */}
            {/* <Pressable onPress={() => history.push('work-order')} cursor="pointer" opacity={["work-order"].includes(splitLocation[1]) ? 1 : 0.6} py="2" flex={1}>
              <Center>
                <Icon mb="1" as={<Box><FaTasks/></Box>} color="info.800" size="sm" />
                <Text color="info.800" fontSize="12">
                  Work Order
                </Text>
              </Center>
            </Pressable> */}
            {/* } */}
            <Pressable onPress={() => history.push('profile')} cursor="pointer" opacity={["profile"].includes(splitLocation[1]) ? 1 : 0.5} py="2" flex={1}>
              <Center>
                <Icon mb="1" as={<Box><FaUserCircle/></Box>} color="info.800" size="sm" />
                <Text color="info.800" fontSize="12">
                  Profile
                </Text>
              </Center>
            </Pressable>
          </HStack>
        </Box>
      </Flex>
    );
}

const style = {
  containerStyle: {
    padding: 15,
    width: '100%',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  cardStyle: {
    flex: 1
  },
  linearGradient: {
    flex: 1,
    paddingLeft: 15,
    paddingRight: 15,
  },
  paddingPage:{
    flex: 1,
    paddingLeft: 15,
    paddingRight: 15,
  },
  fullPage:{
    flex: 1
  },
  linearGradientFull: {
    flex: 1
  },
  backgroundImage: {
    flex: 1,
    resizeMode: 'repeat',
  }
}