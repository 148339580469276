import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the WasteUnitScreen state domain
 */

const selectWasteUnitScreenDomain = state =>
  state.wasteUnitScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by WasteUnitScreen
 */

const makeSelectWasteUnitScreen = () =>
  createSelector(
    selectWasteUnitScreenDomain,
    substate => substate,
  );

export default makeSelectWasteUnitScreen;
export { selectWasteUnitScreenDomain };
