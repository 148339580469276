import React, {useState, forwardRef} from "react";
import { Badge, VStack, Box, Button, Icon, Center, Text, Pressable, Flex, HStack, Stack, Input } from 'native-base';
import { commonStyle } from "../styles";
import moment from "moment";
import DatePicker from "react-datepicker";
import {FaRegFolder, FaArrowLeft, FaFilter, FaGripHorizontal} from 'react-icons/fa';
import history from "../util/history";
import helper from "../util/helper";
import { MdCalendarToday, MdOutlineFilterList } from "react-icons/md";

const RangeDateCustom = (props) => {
  const {changeStartDt, changeEndDt, startDt, endDt} = props;

  const InputStartDate = forwardRef(({ value, onClick }, ref) => (
    <Input 
      value={moment(startDt).format("DD/MM/YYYY")} 
      isReadOnly="true"
      onFocus={onClick} 
      borderRadius="lg" 
      variant="filled"
      ref={ref} 
      borderColor="primary.900" 
      InputLeftElement={<Icon as={<Box><MdCalendarToday/></Box>} size={5} ml="2" color="muted.400" />} 
      />
  ));

  const InputEndDate = forwardRef(({ value, onClick }, ref) => (
    <Input 
      value={moment(endDt).format("DD/MM/YYYY")} 
      isReadOnly="true"
      onFocus={onClick} 
      borderRadius="lg" 
      variant="filled"
      ref={ref} 
      borderColor="primary.900" 
      InputLeftElement={<Icon as={<Box><MdCalendarToday/></Box>} size={5} ml="2" color="muted.400" />} 
      />
  ));

  const [filterDtType, setFilterDtType] = useState();
  const handleOnChangeFilterDtType = (type) => {
    console.log('filterDtType', type);
    const curdate = new Date();
    let start_dt = moment(new Date(Date.now() - 1 * 24 * 60 * 60 * 1000)).toDate();
    let end_dt = moment(curdate).toDate();

    switch (type) {
      case 'all':
        start_dt = '';
        end_dt = '';
        break;
      case '7d':
        start_dt = moment(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)).toDate();
        break;
      case '30d':
        start_dt = moment(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)).toDate();
        break;
      case '90d':
        start_dt = moment(new Date(Date.now() - 90 * 24 * 60 * 60 * 1000)).toDate();
        break;
      default:
        break;
    }

    changeStartDt(start_dt);
    changeEndDt(end_dt);
    setFilterDtType(type);
  }

  return (
    <VStack space={3}>
      <Button.Group isAttached width="full">
        {/* <Button size="sm" onPress={() => handleOnChangeFilterDtType('all')} variant={filterDtType == 'all' ? 'solid': 'outline'} colorScheme="primary">All</Button> */}
        <Button size="sm" onPress={() => handleOnChangeFilterDtType('7d')} variant={filterDtType == '7d' ? 'solid': 'outline'} colorScheme="primary">7 Day</Button>
        <Button size="sm" onPress={() => handleOnChangeFilterDtType('30d')} variant={filterDtType == '30d' ? 'solid': 'outline'} colorScheme="primary">30 Day</Button>
        <Button size="sm" onPress={() => handleOnChangeFilterDtType('90d')} variant={filterDtType == '90d' ? 'solid': 'outline'} colorScheme="primary">90 Day</Button>
        <Button size="sm" onPress={() => handleOnChangeFilterDtType('custom')} variant={filterDtType == 'custom' ? 'solid': 'outline'} colorScheme="primary">Custom</Button>
      </Button.Group>
      {filterDtType == 'custom' && 
      <Stack direction="row" justifyContent="space-between">
        <Box flex={1}>
          <DatePicker customInput={<InputStartDate/>} portalId='root-portal' className='input-datepicker' popperClassName='pooper-datepicker' selected={startDt} onChange={(date) => changeStartDt(date)} maxDate={endDt} />
        </Box>
        <Text fontWeight="bold" mx={2} alignSelf="center">s.d</Text>
        <Box flex={1}>
          <DatePicker customInput={<InputEndDate/>} portalId='root-portal' className='input-datepicker' popperClassName='pooper-datepicker' selected={endDt} onChange={(date) => changeEndDt(date)} minDate={startDt} />
        </Box>
      </Stack>
      }
    </VStack>
  );
}

export default RangeDateCustom;