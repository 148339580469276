import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
 
  import * as constants from './constants';
  import api from '../../util/api';
  import helper from '../../util/helper';
  import moment from 'moment';
  
  import makeSelectLoginScreen from './selector';
import { getDashboardFail, getDashboardSuccess, getTasksFail, getTasksSuccess } from './actions';

  export function* doGetDashboard(action) {
    const dataset = yield select(makeSelectLoginScreen());
    try {
      const tglAwal = moment().startOf('year').format("YYYY-MM-DD");
      const tglAkhir = moment().format("YYYY-MM-DD HH:mm:ss");
      
      const params = {
        tgl_awal: tglAwal+' 00:00:00',
        tgl_akhir: tglAkhir
      };
      const response = yield call(api.user.getDashboard, params);
      const {success} = response.data;
      console.log("res:", success);
      yield put(
        getDashboardSuccess((helper.isEmpty(success) ? [] : success)),
      );
    } catch (err) {
      console.log(err);
      helper.errorToast("Gagal mengambil data task");
      yield put(getDashboardFail(err));
    }
  }
  
  // Individual exports for testing
  export default function* loginScreenSaga() {
    yield all([
      takeLatest(constants.GET_DASHBOARD, doGetDashboard),
    ]);
  }
  