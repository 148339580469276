import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the WastePickupScreen state domain
 */

const selectWastePickupScreenDomain = state =>
  state.wastePickupScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by WastePickupScreen
 */

const makeSelectWastePickupScreen = () =>
  createSelector(
    selectWastePickupScreenDomain,
    substate => substate,
  );

export default makeSelectWastePickupScreen;
export { selectWastePickupScreenDomain };
