export const DEFAULT_ACTION = 'util/GLOBAL_VAR/DEFAULT_ACTION';
export const UPDATE_INPUT = 'util/GLOBAL_VAR/UPDATE_INPUT';

export const GET_WASTE_TYPE = 'util/GLOBAL_VAR/GET_WASTE_TYPE';
export const GET_WASTE_TYPE_LOADING = 'util/GLOBAL_VAR/GET_WASTE_TYPE_LOADING';
export const GET_WASTE_TYPE_SUCCESS = 'util/GLOBAL_VAR/GET_WASTE_TYPE_SUCCESS';
export const GET_WASTE_TYPE_FAIL = 'util/GLOBAL_VAR/GET_WASTE_TYPE_FAIL';

export const GET_LIST_UNIT = 'util/GLOBAL_VAR/GET_LIST_UNIT';
export const GET_LIST_UNIT_LOADING = 'util/GLOBAL_VAR/GET_LIST_UNIT_LOADING';
export const GET_LIST_UNIT_SUCCESS = 'util/GLOBAL_VAR/GET_LIST_UNIT_SUCCESS';
export const GET_LIST_UNIT_FAIL = 'util/GLOBAL_VAR/GET_LIST_UNIT_FAIL';

export const GET_PEGAWAI = 'util/GLOBAL_VAR/GET_PEGAWAI';
export const GET_PEGAWAI_LOADING = 'util/GLOBAL_VAR/GET_PEGAWAI_LOADING';
export const GET_PEGAWAI_SUCCESS = 'util/GLOBAL_VAR/GET_PEGAWAI_SUCCESS';
export const GET_PEGAWAI_FAIL = 'util/GLOBAL_VAR/GET_PEGAWAI_FAIL';

export const GET_VENDOR = 'util/GLOBAL_VAR/GET_VENDOR';
export const GET_VENDOR_LOADING = 'util/GLOBAL_VAR/GET_VENDOR_LOADING';
export const GET_VENDOR_SUCCESS = 'util/GLOBAL_VAR/GET_VENDOR_SUCCESS';
export const GET_VENDOR_FAIL = 'util/GLOBAL_VAR/GET_VENDOR_FAIL';

export const GET_LOKASI = 'util/GLOBAL_VAR/GET_LOKASI';
export const GET_LOKASI_LOADING = 'util/GLOBAL_VAR/GET_LOKASI_LOADING';
export const GET_LOKASI_SUCCESS = 'util/GLOBAL_VAR/GET_LOKASI_SUCCESS';
export const GET_LOKASI_FAIL = 'util/GLOBAL_VAR/GET_LOKASI_FAIL';