import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export function defaultAction() {
  console.log("reset state action");
  return {
    type: DEFAULT_ACTION,
  };
}

export function updateInput({ name, value }) {
  return {
    type: constants.UPDATE_INPUT,
    name,
    value
  };
}

export function submitUnitAnnounce() {
  return {
    type: constants.SUBMIT_UNIT_ANNOUNCE
  };
}

export function submitUnitAnnounceFail(error) {
  return {
    type: constants.SUBMIT_UNIT_ANNOUNCE_FAIL,
    error
  };
}

export function submitUnitAnnounceSuccess(results) {
  return {
    type: constants.SUBMIT_UNIT_ANNOUNCE_SUCCESS,
    results
  };
}

export function addImage(asset) {
  return {
    type: constants.ADD_IMAGE,
    asset
  };
}

export function delImage(idx) {
  return {
    type: constants.DEL_IMAGE,
    idx
  };
}

export function getUnitAnnounceDetail(id_pengumuman) {
  return {
    type: constants.GET_PENGUMUMAN_DETAIL,
    id_pengumuman
  };
}

export function getUnitAnnounceDetailFail(error) {
  return {
    type: constants.GET_PENGUMUMAN_DETAIL_FAIL,
    error
  };
}

export function getUnitAnnounceDetailSuccess(results) {
  return {
    type: constants.GET_PENGUMUMAN_DETAIL_SUCCESS,
    results
  };
}

export function delSavedDetail(idx) {
  return {
    type: constants.DEL_SAVED_DETAIL,
    idx
  };
}

export function setSavedDetail() {
  return {
    type: constants.SET_SAVED_DETAIL
  };
}