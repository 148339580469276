export const DEFAULT_ACTION = 'screen/WasteAcceptanceScreen/DEFAULT_ACTION';
export const UPDATE_INPUT = 'screen/WasteAcceptanceScreen/UPDATE_INPUT';

export const GET_PENERIMAAN_LIMBAH = 'screen/WasteAcceptanceScreen/GET_PENERIMAAN_LIMBAH';
export const GET_PENERIMAAN_LIMBAH_LOADING = 'screen/WasteAcceptanceScreen/GET_PENERIMAAN_LIMBAH_LOADING';
export const GET_PENERIMAAN_LIMBAH_SUCCESS = 'screen/WasteAcceptanceScreen/GET_PENERIMAAN_LIMBAH_SUCCESS';
export const GET_PENERIMAAN_LIMBAH_FAIL = 'screen/WasteAcceptanceScreen/GET_PENERIMAAN_LIMBAH_FAIL';

export const GET_MORE_PENERIMAAN_LIMBAH = 'screen/WasteAcceptanceScreen/GET_MORE_PENERIMAAN_LIMBAH';
export const GET_MORE_PENERIMAAN_LIMBAH_LOADING = 'screen/WasteAcceptanceScreen/GET_MORE_PENERIMAAN_LIMBAH_LOADING';
export const GET_MORE_PENERIMAAN_LIMBAH_SUCCESS = 'screen/WasteAcceptanceScreen/GET_MORE_PENERIMAAN_LIMBAH_SUCCESS';
export const GET_MORE_PENERIMAAN_LIMBAH_FAIL = 'screen/WasteAcceptanceScreen/GET_MORE_PENERIMAAN_LIMBAH_FAIL';

export const DELETE_PENERIMAAN = 'screen/WasteAcceptanceScreen/DELETE_PENERIMAAN';
export const DELETE_PENERIMAAN_LOADING = 'screen/WasteAcceptanceScreen/DELETE_PENERIMAAN_LOADING';
export const DELETE_PENERIMAAN_SUCCESS = 'screen/WasteAcceptanceScreen/DELETE_PENERIMAAN_SUCCESS';
export const DELETE_PENERIMAAN_FAIL = 'screen/WasteAcceptanceScreen/DELETE_PENERIMAAN_FAIL';