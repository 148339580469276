import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import makeSelectNotificationScreen from './selector';
import reducer from './reducer';
import saga from './saga';

// import {Pressable, ScrollView, RefreshControl, TouchableWithoutFeedback, BackHandler, VirtualizedList } from 'react-native';
import {
  AspectRatio,
  Icon,
  HStack, 
  Avatar, 
  Heading, 
  VStack, 
  Box, 
  Center,
  Text,
  Image, 
  Flex,
  Pressable,
  FlatList,
  ScrollView, 
  Button} from 'native-base';
import {FaInfoCircle} from 'react-icons/fa';
import moment from 'moment';
import { ContainerPage } from '../../components/ContainerPage';
import MyAlert from '../../components/MyAlert';
import { commonStyle } from '../../styles';
import { getNotifications, getUserPrivileges, updateInput } from './actions';
import api from '../../util/api';
import history from '../../util/history';

export function NotificationScreen({
  dispatch,
  navigation,
  notificationScreen,
  onUpdateInput,
  onGetNotifications
}){
  const[userData, setUserData] = useState();

  useEffect(() => {
      // onGetNotifications();
      // api.clearUserNotif();
  },[]);

  const renderItem = ({item}) => {
    return (
      <Box px={3} marginY={2}>
        <Pressable onPress={() => history.push('notif-detail', {data_notif: item})}>
          <Box backgroundColor={commonStyle.baseColor} shadow={3} borderRadius="lg" p={3}>
            <HStack space={3} justifyContent="flex-start" alignItems="center">
              <Icon as={<Box><FaInfoCircle/></Box>} size="7" color="primary.700" />
              <VStack flex={1}>
                <Text color="darkText" fontSize="sm">
                  {item.subject}
                </Text>
                <Text color="muted.400" fontWeight="bold" fontSize="xs">
                  {moment(item.date_create, "DD-MM-YYYY").format("ddd, DD MMM YYYY")}
                </Text>
              </VStack>
            </HStack>
          </Box>
        </Pressable>
      </Box>
    );
  }

  const getItem = (_data, index) => ({..._data[index]});
  
  const getItemCount = _data => notificationScreen.notifications.length;

  const getItemLayout = (data, index) => ({length: 90, offset: 90 * index, index});

  return (
        <ContainerPage options={{statusBar: true, prevPage: 'home', title: 'Notifikasi'}}>
          <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
            {!notificationScreen.loading && <Box>
            {notificationScreen.notifications.length == 0 && <MyAlert status="info" title="Belum ada notifikasi."/>}
            {notificationScreen.notifications.length > 0 &&
              <FlatList
                data={notificationScreen.notifications}
                renderItem={renderItem}
              ></FlatList>
            }
          </Box>}
          
          </ScrollView>
          {/* <ScrollView refreshControl={<RefreshControl refreshing={notificationScreen.loading} onRefresh={() => onGetNotifications()}/>} showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
          {
            !notificationScreen.loading &&
            <VStack space={3} p={3}>
                {notificationScreen.notifications.length == 0 && <MyAlert status="info" title="Belum ada notifikasi."/>}
                {notificationScreen.notifications.length > 0 &&
                  notificationScreen.notifications.map((x, i) => (
                  <Pressable key={i} onPress={() => navigation.navigate('notification_detail', {notif: x})}>
                    <Box backgroundColor="common.100" shadow={3} borderRadius="lg" p={3}>
                      <HStack space={3} justifyContent="flex-start" alignItems="center">
                        <Icon as={<FontAwesome5 name='info-circle'/>} size="7" color="primary.700" />
                        <VStack flex={1}>
                          <Text color="darkText" fontSize="sm">
                            {x.subject}
                          </Text>
                          <Text color="muted.400" fontWeight="bold" fontSize="xs">
                            {moment(x.date_create, "DD-MM-YYYY").format("ddd, DD MMM YYYY")}
                          </Text>
                        </VStack>
                      </HStack>
                    </Box>
                  </Pressable>
                  ))}
            </VStack>
            }
          </ScrollView> */}
        </ContainerPage>
      );
    
}

const styles = {
  errorTextStyle: {
    color: 'red',
    fontStyle: 'italic',
    fontWeight: 'bold',
    alignSelf: 'center'
  }
};

const mapStateToProps = createStructuredSelector({
  notificationScreen: makeSelectNotificationScreen(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onGetNotifications: () =>  dispatch(getNotifications()),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(NotificationScreen);