import {all} from 'redux-saga/effects';
// saga
import globalVarSaga from './util/saga';
import loginScreenSaga from './screens/LoginScreen/saga';
import forgetPassScreenSaga from './screens/ForgetPassScreen/saga';
import homeScreenSaga from './screens/HomeScreen/saga';
import profileScreenSaga from './screens/ProfileScreen/saga';
import welcomeScreenSaga from './screens/WelcomeScreen/saga';
import formProfileScreenSaga from './screens/FormProfileScreen/saga';
import formChangePassScreenSaga from './screens/FormChangePassScreen/saga';
import notificationScreenSaga from './screens/NotificationScreen/saga';
import notificationDetailScreenSaga from './screens/NotificationDetailScreen/saga';

import unitRequestScreenSaga from './screens/UnitRequestScreen/saga';
import unitRequestFormScreenSaga from './screens/UnitRequestFormScreen/saga';
import unitRequestFormDetailScreenSaga from './screens/UnitRequestFormDetailScreen/saga';

import wasteTransferScreenSaga from './screens/WasteTransferScreen/saga';
import wasteTransferFormScreenSaga from './screens/WasteTransferFormScreen/saga';
import wasteTransferFormDetailScreenSaga from './screens/WasteTransferFormDetailScreen/saga';

import wasteAcceptanceScreenSaga from './screens/WasteAcceptanceScreen/saga';
import wasteAcceptanceFormScreenSaga from './screens/WasteAcceptanceFormScreen/saga';
import wasteAcceptanceFormDetailScreenSaga from './screens/WasteAcceptanceFormDetailScreen/saga';

import wastePickupScreenSaga from './screens/WastePickupScreen/saga';
import wastePickupFormScreenSaga from './screens/WastePickupFormScreen/saga';
import wastePickupFormDetailScreenSaga from './screens/WastePickupFormDetailScreen/saga';

import postAnnounceScreenSaga from './screens/PostAnnounceScreen/saga';
import postAnnounceFormScreenSaga from './screens/PostAnnounceFormScreen/saga';
import postAnnounceFormDetailScreenSaga from './screens/PostAnnounceFormDetailScreen/saga';
import postAnnounceFormVendorScreenSaga from './screens/PostAnnounceFormVendorScreen/saga';

import approvalScreenSaga from './screens/ApprovalScreen/saga';
import approvalFormScreenSaga from './screens/ApprovalFormScreen/saga';

import unitAnnounceScreenSaga from './screens/UnitAnnounceScreen/saga';
import unitAnnounceFormScreenSaga from './screens/UnitAnnounceFormScreen/saga';
import unitAnnounceFormDetailScreenSaga from './screens/UnitAnnounceFormDetailScreen/saga';

import manifestScreenSaga from './screens/ManifestScreen/saga';
import manifestDetailScreenSaga from './screens/ManifestDetailScreen/saga';

import wasteUnitScreenSaga from './screens/WasteUnitScreen/saga';

// end saga
function* combinedSaga() {
  yield all([
    globalVarSaga(),
    loginScreenSaga(),
    forgetPassScreenSaga(),
    homeScreenSaga(),
    profileScreenSaga(),
    welcomeScreenSaga(),
    formProfileScreenSaga(),
    formChangePassScreenSaga(),
    notificationScreenSaga(),
    notificationDetailScreenSaga(),

    unitRequestScreenSaga(),
    unitRequestFormScreenSaga(),
    unitRequestFormDetailScreenSaga(),

    wasteTransferScreenSaga(),
    wasteTransferFormScreenSaga(),
    wasteTransferFormDetailScreenSaga(),

    wasteAcceptanceScreenSaga(),
    wasteAcceptanceFormScreenSaga(),
    wasteAcceptanceFormDetailScreenSaga(),

    wastePickupScreenSaga(),
    wastePickupFormScreenSaga(),
    wastePickupFormDetailScreenSaga(),

    postAnnounceScreenSaga(),
    postAnnounceFormScreenSaga(),
    postAnnounceFormDetailScreenSaga(),
    postAnnounceFormVendorScreenSaga(),

    approvalScreenSaga(),
    approvalFormScreenSaga(),

    unitAnnounceScreenSaga(),
    unitAnnounceFormScreenSaga(),
    unitAnnounceFormDetailScreenSaga(),

    manifestScreenSaga(),
    manifestDetailScreenSaga(),

    wasteUnitScreenSaga(),
  ]);
}
export default combinedSaga;