export const DEFAULT_ACTION = 'screen/ManifestScreen/DEFAULT_ACTION';
export const UPDATE_INPUT = 'screen/ManifestScreen/UPDATE_INPUT';

export const GET_MANIFEST = 'screen/ManifestScreen/GET_MANIFEST';
export const GET_MANIFEST_LOADING = 'screen/ManifestScreen/GET_MANIFEST_LOADING';
export const GET_MANIFEST_SUCCESS = 'screen/ManifestScreen/GET_MANIFEST_SUCCESS';
export const GET_MANIFEST_FAIL = 'screen/ManifestScreen/GET_MANIFEST_FAIL';

export const GET_MORE_MANIFEST = 'screen/ManifestScreen/GET_MORE_MANIFEST';
export const GET_MORE_MANIFEST_LOADING = 'screen/ManifestScreen/GET_MORE_MANIFEST_LOADING';
export const GET_MORE_MANIFEST_SUCCESS = 'screen/ManifestScreen/GET_MORE_MANIFEST_SUCCESS';
export const GET_MORE_MANIFEST_FAIL = 'screen/ManifestScreen/GET_MORE_MANIFEST_FAIL';