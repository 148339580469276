export const DEFAULT_ACTION = 'screen/UnitAnnounceScreen/DEFAULT_ACTION';
export const UPDATE_INPUT = 'screen/UnitAnnounceScreen/UPDATE_INPUT';

export const GET_UNIT_ANNOUNCE = 'screen/UnitAnnounceScreen/GET_UNIT_ANNOUNCE';
export const GET_UNIT_ANNOUNCE_LOADING = 'screen/UnitAnnounceScreen/GET_UNIT_ANNOUNCE_LOADING';
export const GET_UNIT_ANNOUNCE_SUCCESS = 'screen/UnitAnnounceScreen/GET_UNIT_ANNOUNCE_SUCCESS';
export const GET_UNIT_ANNOUNCE_FAIL = 'screen/UnitAnnounceScreen/GET_UNIT_ANNOUNCE_FAIL';

export const GET_MORE_UNIT_ANNOUNCE = 'screen/UnitAnnounceScreen/GET_MORE_UNIT_ANNOUNCE';
export const GET_MORE_UNIT_ANNOUNCE_LOADING = 'screen/UnitAnnounceScreen/GET_MORE_UNIT_ANNOUNCE_LOADING';
export const GET_MORE_UNIT_ANNOUNCE_SUCCESS = 'screen/UnitAnnounceScreen/GET_MORE_UNIT_ANNOUNCE_SUCCESS';
export const GET_MORE_UNIT_ANNOUNCE_FAIL = 'screen/UnitAnnounceScreen/GET_MORE_UNIT_ANNOUNCE_FAIL';

export const DELETE_UNIT_ANNOUNCE = 'screen/UnitRequestScreen/DELETE_UNIT_ANNOUNCE';
export const DELETE_UNIT_ANNOUNCE_LOADING = 'screen/UnitRequestScreen/DELETE_UNIT_ANNOUNCE_LOADING';
export const DELETE_UNIT_ANNOUNCE_SUCCESS = 'screen/UnitRequestScreen/DELETE_UNIT_ANNOUNCE_SUCCESS';
export const DELETE_UNIT_ANNOUNCE_FAIL = 'screen/UnitRequestScreen/DELETE_UNIT_ANNOUNCE_FAIL';