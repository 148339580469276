export const DEFAULT_ACTION = 'screen/PostAnnounceFormScreen/DEFAULT_ACTION';
export const UPDATE_INPUT = 'screen/PostAnnounceFormScreen/UPDATE_INPUT';

export const GET_PENGUMUMAN = 'screen/PostAnnounceFormScreen/GET_PENGUMUMAN';
export const GET_PENGUMUMAN_LOADING = 'screen/PostAnnounceFormScreen/GET_PENGUMUMAN_LOADING';
export const GET_PENGUMUMAN_SUCCESS = 'screen/PostAnnounceFormScreen/GET_PENGUMUMAN_SUCCESS';
export const GET_PENGUMUMAN_FAIL = 'screen/PostAnnounceFormScreen/GET_PENGUMUMAN_FAIL';

export const SUBMIT_PENGUMUMAN = 'screen/PostAnnounceFormScreen/SUBMIT_PENGUMUMAN';
export const SUBMIT_PENGUMUMAN_LOADING = 'screen/PostAnnounceFormScreen/SUBMIT_PENGUMUMAN_LOADING';
export const SUBMIT_PENGUMUMAN_SUCCESS = 'screen/PostAnnounceFormScreen/SUBMIT_PENGUMUMAN_SUCCESS';
export const SUBMIT_PENGUMUMAN_FAIL = 'screen/PostAnnounceFormScreen/SUBMIT_PENGUMUMAN_FAIL';

export const DEL_SAVED_DETAIL = 'screen/PostAnnounceFormScreen/DEL_SAVED_DETAIL';
export const SET_SAVED_DETAIL = 'screen/PostAnnounceFormScreen/SET_SAVED_DETAIL';