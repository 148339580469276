import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the PostAnnounceFormVendorScreen state domain
 */

const selectPostAnnounceFormVendorScreenDomain = state =>
  state.postAnnounceFormVendorScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by PostAnnounceFormVendorScreen
 */

const makeSelectPostAnnounceFormVendorScreen = () =>
  createSelector(
    selectPostAnnounceFormVendorScreenDomain,
    substate => substate,
  );

export default makeSelectPostAnnounceFormVendorScreen;
export { selectPostAnnounceFormVendorScreenDomain };
