import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
import * as constants from './constants';
import api from './api';
import helper from './helper';

import makeSelectGlobalVar from './selector';
import { getGedungFail, getGedungSuccess, getLantaiFail, getLantaiSuccess, getPegawaiSuccess, getPegawaiFail, getWasteTypeSuccess, getWasteTypeFail, getListUnit, getListUnitFail, getListUnitSuccess, getVendorSuccess, getVendorFail, getLokasiSuccess, getLokasiFail } from './actions';
  
  export function* doGetWasteType(action) {
    const {id_akun} = action;
    const dataset = yield select(makeSelectGlobalVar());
    try {
      const response = yield call(api.master.getWasteTypes);
      const {data} = response.data;
      // console.log('waste_list:', data);
      yield put(
        getWasteTypeSuccess(data),
      );
      
      // RootNavigation.navigate('page', {screen: 'home'});

    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      yield put(getWasteTypeFail(err));
    }
  }

  export function* doGetAllUnit(action) {
    const dataset = yield select(makeSelectGlobalVar());

    try {
      const response = yield call(api.master.getAllUnits);
      const {data} = response.data;
      // console.log('unit_list:', data);
      yield put(
        getListUnitSuccess(data),
      );
      
      // RootNavigation.navigate('page', {screen: 'home'});

    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      yield put(getListUnitFail(err));
    }
  }

  export function* doGetPegawai(action) {
    const {id_gedung} = action;
    const dataset = yield select(makeSelectGlobalVar());
    try {
      const response = yield call(api.getListPegawai);
      const {success} = response.data;
      // console.log('pg_list:', success);
      yield put(
        getPegawaiSuccess(success),
      );
      
      // RootNavigation.navigate('page', {screen: 'home'});

    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      yield put(getPegawaiFail(err));
    }
  }

  export function* doGetAllVendor(action) {
    const {id_gedung} = action;
    const dataset = yield select(makeSelectGlobalVar());
    try {
      const response = yield call(api.master.getAllVendors);
      const {data} = response.data;
      // console.log('vd_list:', data);
      yield put(
        getVendorSuccess(data),
      );
    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      yield put(getVendorFail(err));
    }
  }

  export function* doGetLokasi(action) {
    const {id_gedung} = action;
    const dataset = yield select(makeSelectGlobalVar());
    try {
      const response = yield call(api.master.getLokasi);
      const {data} = response.data;
      // console.log('loc_list:', data);
      yield put(
        getLokasiSuccess(data),
      );
    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      yield put(getLokasiFail(err));
    }
  }

  // Individual exports for testing
  export default function* globalVarSaga() {
    yield all([
      takeLatest(constants.GET_WASTE_TYPE, doGetWasteType),
      takeLatest(constants.GET_LIST_UNIT, doGetAllUnit),
      takeLatest(constants.GET_PEGAWAI, doGetPegawai),
      takeLatest(constants.GET_VENDOR, doGetAllVendor),
      takeLatest(constants.GET_LOKASI, doGetLokasi),
    ]);
  }