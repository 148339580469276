export const DEFAULT_ACTION = 'screen/WastePickupFormScreen/DEFAULT_ACTION';
export const UPDATE_INPUT = 'screen/WastePickupFormScreen/UPDATE_INPUT';

export const SUBMIT_PENGAMBILAN_UNIT = 'screen/WastePickupFormScreen/SUBMIT_PENGAMBILAN_UNIT';
export const SUBMIT_PENGAMBILAN_UNIT_LOADING = 'screen/WastePickupFormScreen/SUBMIT_PENGAMBILAN_UNIT_LOADING';
export const SUBMIT_PENGAMBILAN_UNIT_SUCCESS = 'screen/WastePickupFormScreen/SUBMIT_PENGAMBILAN_UNIT_SUCCESS';
export const SUBMIT_PENGAMBILAN_UNIT_FAIL = 'screen/WastePickupFormScreen/SUBMIT_PENGAMBILAN_UNIT_FAIL';

export const SET_MANIFEST = 'screen/WastePickupFormScreen/SET_MANIFEST';
export const DEL_MANIFEST = 'screen/WastePickupFormScreen/DEL_MANIFEST';

export const GET_PENGAMBILAN_UNIT_DETAIL = 'screen/WastePickupFormScreen/GET_PENGAMBILAN_UNIT_DETAIL';
export const GET_PENGAMBILAN_UNIT_DETAIL_LOADING = 'screen/WastePickupFormScreen/GET_PENGAMBILAN_UNIT_DETAIL_LOADING';
export const GET_PENGAMBILAN_UNIT_DETAIL_SUCCESS = 'screen/WastePickupFormScreen/GET_PENGAMBILAN_UNIT_DETAIL_SUCCESS';
export const GET_PENGAMBILAN_UNIT_DETAIL_FAIL = 'screen/WastePickupFormScreen/GET_PENGAMBILAN_UNIT_DETAIL_FAIL';

export const SAVE_DETAIL = 'screen/WastePickupFormScreen/SAVE_DETAIL';
