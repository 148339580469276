/*
 *
 * WasteAcceptanceScreen reducer
 *
 */
import {produce} from 'immer';
import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';
import moment from 'moment';

const date = new Date();
const from = moment(new Date(Date.now() - 1 * 24 * 60 * 60 * 1000)).toDate(); // one days ago
const to = moment(date).toDate();

export const initialState = {
    error: 0,
    error_msg: '',
    start_dt: from,
    end_dt: to,
    loading: false,
    loading_delete: false,
    results: [],
    q: '',
    filter_status: '',
    loading_more: false,
    page: 1,
    total_page: 1
};

/* eslint-disable default-case, no-param-reassign */
const WasteAcceptanceScreenReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case DEFAULT_ACTION:
        return {...initialState};
      case constants.UPDATE_INPUT:
        draft[action.name] = action.value;
        break;
      case constants.GET_WASTE_PICKUP:
        draft.loading = true;
        break;
      case constants.GET_WASTE_PICKUP_FAIL:
        draft.error = true;
        draft.loading = false;
        draft.error_msg= 'Maaf, Gagal mengambil data.';
        draft.results = [];
        break;
      case constants.GET_WASTE_PICKUP_LOADING:
        draft.loading = true;
        break;
      case constants.GET_WASTE_PICKUP_SUCCESS:
        draft.results = action.results;
        draft.total_page = action.results.last_page;
        draft.loading = false;
        break;
      case constants.GET_MORE_WASTE_PICKUP:
        draft.loading_more = true;
        break;
      case constants.GET_MORE_WASTE_PICKUP_FAIL:
        draft.error = true;
        draft.loading_more = false;
        draft.error_msg= 'Maaf, Gagal mengambil data pelaporan.';
        draft.results = [];
        break;
      case constants.GET_MORE_WASTE_PICKUP_SUCCESS:
        draft.results = {...action.results, data: draft.results.data.concat(action.results.data)};
        draft.total_page = action.results.last_page;
        draft.loading_more = false;
        break;
      case constants.DEL_WASTE_PICKUP:
        draft.loading_delete = true;
        break;
      case constants.DEL_WASTE_PICKUP_FAIL:
        draft.error = true;
        draft.loading_delete = false;
        draft.error_msg= 'Maaf, Gagal menghapus data.';
        break;
      case constants.DEL_WASTE_PICKUP_LOADING:
        draft.loading_delete = true;
        break;
      case constants.DEL_WASTE_PICKUP_SUCCESS:
        draft.del_result = action.results;
        draft.loading_delete = false;
        break;
    }
  });

export default WasteAcceptanceScreenReducer;
