import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the UnitAnnounceFormDetailScreen state domain
 */

const selectUnitAnnounceFormDetailScreenDomain = state =>
  state.unitAnnounceFormDetailScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by UnitAnnounceFormDetailScreen
 */

const makeSelectUnitAnnounceFormDetailScreen = () =>
  createSelector(
    selectUnitAnnounceFormDetailScreenDomain,
    substate => substate,
  );

export default makeSelectUnitAnnounceFormDetailScreen;
export { selectUnitAnnounceFormDetailScreenDomain };
