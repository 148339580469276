import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the PostAnnounceFormDetailScreen state domain
 */

const selectPostAnnounceFormDetailScreenDomain = state =>
  state.postAnnounceFormDetailScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by PostAnnounceFormDetailScreen
 */

const makeSelectPostAnnounceFormDetailScreen = () =>
  createSelector(
    selectPostAnnounceFormDetailScreenDomain,
    substate => substate,
  );

export default makeSelectPostAnnounceFormDetailScreen;
export { selectPostAnnounceFormDetailScreenDomain };
