export const DEFAULT_ACTION = 'screen/WasteTrasferScreen/DEFAULT_ACTION';
export const UPDATE_INPUT = 'screen/WasteTrasferScreen/UPDATE_INPUT';

export const GET_TRANSFER_WASTE = 'screen/WasteTrasferScreen/GET_TRANSFER_WASTE';
export const GET_TRANSFER_WASTE_LOADING = 'screen/WasteTrasferScreen/GET_TRANSFER_WASTE_LOADING';
export const GET_TRANSFER_WASTE_SUCCESS = 'screen/WasteTrasferScreen/GET_TRANSFER_WASTE_SUCCESS';
export const GET_TRANSFER_WASTE_FAIL = 'screen/WasteTrasferScreen/GET_TRANSFER_WASTE_FAIL';

export const GET_MORE_TRANSFER_WASTE = 'screen/WasteTrasferScreen/GET_MORE_TRANSFER_WASTE';
export const GET_MORE_TRANSFER_WASTE_LOADING = 'screen/WasteTrasferScreen/GET_MORE_TRANSFER_WASTE_LOADING';
export const GET_MORE_TRANSFER_WASTE_SUCCESS = 'screen/WasteTrasferScreen/GET_MORE_TRANSFER_WASTE_SUCCESS';
export const GET_MORE_TRANSFER_WASTE_FAIL = 'screen/WasteTrasferScreen/GET_MORE_TRANSFER_WASTE_FAIL';

export const DELETE_WASTE_TRANSFER = 'screen/WasteTrasferScreen/DELETE_WASTE_TRANSFER';
export const DELETE_WASTE_TRANSFER_LOADING = 'screen/WasteTrasferScreen/DELETE_WASTE_TRANSFER_LOADING';
export const DELETE_WASTE_TRANSFER_SUCCESS = 'screen/WasteTrasferScreen/DELETE_WASTE_TRANSFER_SUCCESS';
export const DELETE_WASTE_TRANSFER_FAIL = 'screen/WasteTrasferScreen/DELETE_WASTE_TRANSFER_FAIL';