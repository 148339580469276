import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
 
  import * as constants from './constants';
  import moment from 'moment';
  import api from '../../util/api';
  import helper from '../../util/helper';
  
  import makeSelectWastePickupScreen from './selector';
  import { delWastePickupFail, delWastePickupSuccess, getMoreWastePickupFail, getMoreWastePickupSuccess, getWastePickupFail, getWastePickupSuccess } from './actions';
  
  export function* doGetListWastePickup(action) {
    const dataset = yield select(makeSelectWastePickupScreen());
    try {
      const tglAwal = moment(dataset.start_dt).format("YYYY-MM-DD");
      const tglAkhir = moment(dataset.end_dt).format("YYYY-MM-DD HH:mm:ss");
      
      const params = {
        tgl_awal: tglAwal+' 00:00:00',
        tgl_akhir: tglAkhir,
        search: dataset.q,
        status: dataset.filter_status
      };

      if(helper.isEmpty(dataset.start_dt)) delete params.tgl_awal;
      if(helper.isEmpty(dataset.end_dt)) delete params.tgl_akhir;
      if(helper.isEmpty(dataset.q)) delete params.search;
      if(helper.isEmpty(dataset.filter_status)) delete params.status;

      const response = yield call(api.waste.getWastePickupList, params, dataset.page);
      const {success} = response.data;
      console.log("res:", success);
      if(dataset.page == 1){
        yield put(
          getWastePickupSuccess((helper.isEmpty(success) ? [] : success)),
        );
      } else {
        yield put(
          getMoreWastePickupSuccess((helper.isEmpty(success) ? [] : success)),
        );
      }
    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      if(dataset.page == 1){
        yield put(getWastePickupFail(err));
      } else {
        yield put(getMoreWastePickupFail(err));
      }
    }
  }

  export function* doDeleteListWastePickup(action) {
    const {id_pengambilan_limbah} = action;
    const dataset = yield select(makeSelectWastePickupScreen());
    try {

      const params = {
        id_pengambilan: id_pengambilan_limbah,
      };

      const response = yield call(api.waste.deleteWastePickup, params);
      const {success} = response.data;
      console.log("res:", success);
      helper.successToast("Pengambilan Limbah berhasil dihapus");
      yield put(
        delWastePickupSuccess((helper.isEmpty(success) ? [] : success)),
      );
    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      yield put(delWastePickupFail(err));
    }
  }

  // Individual exports for testing
  export default function* wastePickupScreenSaga() {
    yield all([
      takeLatest(constants.GET_WASTE_PICKUP, doGetListWastePickup),
      takeLatest(constants.GET_MORE_WASTE_PICKUP, doGetListWastePickup),
      takeLatest(constants.DEL_WASTE_PICKUP, doDeleteListWastePickup),
      takeLatest(constants.DEL_WASTE_PICKUP_SUCCESS, doGetListWastePickup)
    ]);
  }
  