import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the ManifestScreen state domain
 */

const selectManifestScreenDomain = state =>
  state.manifestScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by ManifestScreen
 */

const makeSelectManifestScreen = () =>
  createSelector(
    selectManifestScreenDomain,
    substate => substate,
  );

export default makeSelectManifestScreen;
export { selectManifestScreenDomain };
