import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the WasteTransferScreen state domain
 */

const selectWasteTransferScreenDomain = state =>
  state.wasteTransferScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by WasteTransferScreen
 */

const makeSelectWasteTransferScreen = () =>
  createSelector(
    selectWasteTransferScreenDomain,
    substate => substate,
  );

export default makeSelectWasteTransferScreen;
export { selectWasteTransferScreenDomain };
