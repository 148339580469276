import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the UnitRequestFormDetailScreen state domain
 */

const selectUnitRequestFormDetailScreenDomain = state =>
  state.unitRequestFormDetailScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by UnitRequestFormDetailScreen
 */

const makeSelectUnitRequestFormDetailScreen = () =>
  createSelector(
    selectUnitRequestFormDetailScreenDomain,
    substate => substate,
  );

export default makeSelectUnitRequestFormDetailScreen;
export { selectUnitRequestFormDetailScreenDomain };
