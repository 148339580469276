export const DEFAULT_ACTION = 'screen/PostAnnounceScreen/DEFAULT_ACTION';
export const UPDATE_INPUT = 'screen/PostAnnounceScreen/UPDATE_INPUT';

export const GET_POST_ANNOUNCE = 'screen/PostAnnounceScreen/GET_POST_ANNOUNCE';
export const GET_POST_ANNOUNCE_LOADING = 'screen/PostAnnounceScreen/GET_POST_ANNOUNCE_LOADING';
export const GET_POST_ANNOUNCE_SUCCESS = 'screen/PostAnnounceScreen/GET_POST_ANNOUNCE_SUCCESS';
export const GET_POST_ANNOUNCE_FAIL = 'screen/PostAnnounceScreen/GET_POST_ANNOUNCE_FAIL';

export const GET_MORE_POST_ANNOUNCE = 'screen/PostAnnounceScreen/GET_MORE_POST_ANNOUNCE';
export const GET_MORE_POST_ANNOUNCE_LOADING = 'screen/PostAnnounceScreen/GET_MORE_POST_ANNOUNCE_LOADING';
export const GET_MORE_POST_ANNOUNCE_SUCCESS = 'screen/PostAnnounceScreen/GET_MORE_POST_ANNOUNCE_SUCCESS';
export const GET_MORE_POST_ANNOUNCE_FAIL = 'screen/PostAnnounceScreen/GET_MORE_POST_ANNOUNCE_FAIL';

export const DELETE_PENGUMUMAN = 'screen/PostAnnounceScreen/DELETE_PENGUMUMAN';
export const DELETE_PENGUMUMAN_LOADING = 'screen/PostAnnounceScreen/DELETE_PENGUMUMAN_LOADING';
export const DELETE_PENGUMUMAN_SUCCESS = 'screen/PostAnnounceScreen/DELETE_PENGUMUMAN_SUCCESS';
export const DELETE_PENGUMUMAN_FAIL = 'screen/PostAnnounceScreen/DELETE_PENGUMUMAN_FAIL';