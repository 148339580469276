import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function updateInput({ name, value }) {
  return {
    type: constants.UPDATE_INPUT,
    name,
    value
  };
}

export function getWastePickup() {
  return {
    type: constants.GET_WASTE_PICKUP
  };
}

export function getWastePickupLoading() {
  return {
    type: constants.GET_WASTE_PICKUP_LOADING
  };
}

export function getWastePickupFail(error) {
  return {
    type: constants.GET_WASTE_PICKUP_FAIL,
    error
  };
}

export function getWastePickupSuccess(results) {
  return {
    type: constants.GET_WASTE_PICKUP_SUCCESS,
    results
  };
}

export function getMoreWastePickup() {
  return {
    type: constants.GET_MORE_WASTE_PICKUP
  };
}

export function getMoreWastePickupLoading() {
  return {
    type: constants.GET_MORE_WASTE_PICKUP_LOADING
  };
}

export function getMoreWastePickupFail(error) {
  return {
    type: constants.GET_MORE_WASTE_PICKUP_FAIL,
    error
  };
}

export function getMoreWastePickupSuccess(results) {
  return {
    type: constants.GET_MORE_WASTE_PICKUP_SUCCESS,
    results
  };
}

export function delWastePickup(id_pengambilan_limbah) {
  return {
    type: constants.DEL_WASTE_PICKUP,
    id_pengambilan_limbah
  };
}

export function delWastePickupLoading() {
  return {
    type: constants.DEL_WASTE_PICKUP_LOADING
  };
}

export function delWastePickupFail(error) {
  return {
    type: constants.DEL_WASTE_PICKUP_FAIL,
    error
  };
}

export function delWastePickupSuccess(results) {
  return {
    type: constants.DEL_WASTE_PICKUP_SUCCESS,
    results
  };
}