import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function updateInput({ name, value }) {
  return {
    type: constants.UPDATE_INPUT,
    name,
    value
  };
}

export function getManifest() {
  return {
    type: constants.GET_MANIFEST
  };
}

export function getManifestLoading() {
  return {
    type: constants.GET_MANIFEST_LOADING
  };
}

export function getManifestFail(error) {
  return {
    type: constants.GET_MANIFEST_FAIL,
    error
  };
}

export function getManifestSuccess(results) {
  return {
    type: constants.GET_MANIFEST_SUCCESS,
    results
  };
}

export function getMoreManifest() {
  return {
    type: constants.GET_MORE_MANIFEST
  };
}

export function getMoreManifestLoading() {
  return {
    type: constants.GET_MORE_MANIFEST_LOADING
  };
}

export function getMoreManifestFail(error) {
  return {
    type: constants.GET_MORE_MANIFEST_FAIL,
    error
  };
}

export function getMoreManifestSuccess(results) {
  return {
    type: constants.GET_MORE_MANIFEST_SUCCESS,
    results
  };
}