import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import makeSelectProfileScreen from './selector';
import reducer from './reducer';
import saga from './saga';

// import {ScrollView, TouchableWithoutFeedback, Linking, BackHandler } from 'react-native';
import {
  Icon,
  HStack, 
  Avatar, 
  Heading, 
  VStack, 
  Image,
  Box,
  ScrollView, 
  Center,
  Text, 
  Flex, 
  Button,
  Pressable,
  Divider} from 'native-base';
import { MdNotifications, MdModeEdit } from 'react-icons/md';
import { FaUserCircle, FaKey, FaLock, FaChevronRight } from 'react-icons/fa';
import { ContainerPage } from '../../components/ContainerPage';
import MyAlertDialog from '../../components/MyAlertDialog';
import { commonStyle } from '../../styles';
import { getPegawai, updateInput } from './actions';

import api from '../../util/api';
import helper from '../../util/helper';
import history from '../../util/history';
import { deleteFCMToken } from '../../firebase';
import BadgeStatus from '../../components/BadgeStatus';

export function ProfileScreen({
  dispatch,
  navigation,
  profileScreen,
  onUpdateInput,
  onGetPegawai
}){
  const [userData, setUserData] = useState();
  const [screenAccess, setScreenAccess] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  const[backClickCount, setBackClickCount] = useState(0);

  useEffect(() => {
    const user = api.getUserData();
    setUserData(user);
    // get user privileges
    const menus = api.getUserPrivileges();
    const allAccess = helper.generatePrivileges(menus);
    setScreenAccess(allAccess);
    // console.log(allAccess);
    onGetPegawai();
},[]);

  const onHandleLogout = () => {
    // delete token firebase
    deleteFCMToken();

    api.clearUserData();
    window.location.href = "/login";
  }

  return (
        <ContainerPage>
          <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
              <VStack space={4} mt={7} mx={3}>
                <Box mt={4}>
                  <Flex flexDirection="row" justifyContent="start" alignItems="center">
                    <Box>
                      <Avatar size="lg" bg="amber.900" mr="1">
                        {userData && helper.getInitials(userData.user_name)}
                      </Avatar>
                    </Box>
                    <VStack ml={4} width={210}>
                      <Text mt={1} fontSize="lg" color={commonStyle.primaryColor} isTruncated width="80%" fontWeight="bold">
                        {userData && userData.user_name}
                      </Text>
                      <Text mt={1} fontSize="md" color="muted.700" fontWeight={500} isTruncated width="80%">
                        {userData && userData.hp}
                      </Text>
                    </VStack>
                    {/* {screenAccess.includes("09:EDIT") && */}
                    <Box position="absolute" shadow={3} borderRadius="full" justifyContent="center" backgroundColor="white" width={7} height={7} right={0} top={0}>
                      <Pressable onPress={() => history.push("update-profile")}>
                        <Center>
                          <Icon as={<Box><MdModeEdit/></Box>} size="5" color="primary.900"/>
                        </Center>
                      </Pressable>
                    </Box>
                    {/* } */}
                  </Flex>
                </Box>
                <Box mt={4} backgroundColor="white" p={4} borderRadius="lg" shadow={3}>
                  <HStack space={2} pb={3} mb={3} justifyContent="space-between" borderBottomWidth="2" borderBottomColor="dark.500" borderBottomStyle="dashed">
                    <HStack space={2}>
                      <Icon as={<Box><FaUserCircle/></Box>} size="5" mt="0.5" color="muted.400" />
                      <Text color="darkText" fontSize="md">
                        Profil Akun
                      </Text>
                    </HStack>
                    <Box>
                      <BadgeStatus status='Aktif'/>
                    </Box>
                  </HStack>
                  <Box>
                    <div className='row'>
                      <div className='col-6'>
                        <Box>
                          <Text color="muted.400" fontSize="sm">Nama</Text>
                          <Text color="darkText" fontSize="md">
                            {profileScreen.name}
                          </Text>
                        </Box>
                      </div>
                      <div className='col-6'>
                        <Box>
                          <Text color="muted.400" fontSize="sm">No Hp</Text>
                          <Text color="darkText" fontSize="md">
                            {profileScreen.no_hp}
                          </Text>
                        </Box>
                      </div>
                      {/* <div className='col-6'>
                        <Box>
                          <Text color="muted.400" fontSize="sm">Kota Kelahiran</Text>
                          <Text color="darkText" fontSize="md">
                            Surabaya
                          </Text>
                        </Box>
                      </div>
                      <div className='col-6'>
                        <Box>
                          <Text color="muted.400" fontSize="sm">Tanggal Lahir</Text>
                          <Text color="darkText" fontSize="md">
                            11 November 1989
                          </Text>
                        </Box>
                      </div> */}
                      <div className='col-6'>
                        <Box>
                          <Text color="muted.400" fontSize="sm">No Telp</Text>
                          <Text color="darkText" fontSize="md">
                            {profileScreen.no_phone ? profileScreen.no_phone : '-'}
                          </Text>
                        </Box>
                      </div>
                      <div className='col--12'>
                        <Box>
                          <Text color="muted.400" fontSize="sm">Alamat</Text>
                          <Text color="darkText" fontSize="md">
                            {profileScreen.address}
                          </Text>
                        </Box>
                      </div>
                    </div>
                  </Box>
                </Box>
                <Box mb={20} mt={3} backgroundColor="white" borderRadius="lg" shadow={3}>
                  <VStack m={4}>
                    {/* {screenAccess.includes("09:EDIT") &&  */}
                    <Pressable onPress={() => history.push("changepass")}>
                      <HStack justifyContent="center" alignItems="center">
                        <Icon as={<Box><FaKey/></Box>} size="sm" color="muted.400"/>
                        <Flex flex={1} mx={3}>
                          <Text color="darkText">Ubah Password</Text>
                        </Flex>
                        <Icon as={<Box><FaChevronRight/></Box>} size="sm" color="muted.400"/>
                      </HStack>
                      <Divider my={3}/>
                    </Pressable>
                    {/* } */}
                    <Pressable onPress={() => setIsOpen(true)}>
                      <HStack justifyContent="center" alignItems="center">
                        <Icon as={<Box><FaLock/></Box>} size="sm" color="muted.400"/>
                        <Flex flex={1} mx={3}>
                          <Text color="darkText">Keluar</Text>
                        </Flex>
                        <Icon as={<Box><FaChevronRight/></Box>} size="sm" color="muted.400"/>
                      </HStack>
                    </Pressable>
                  </VStack>
                </Box>
                <MyAlertDialog 
                  alertTitle="Keluar Aplikasi" 
                  alertDesc="Apakah anda ingin keluar aplikasi ?"
                  alertNoLabel="Tidak"
                  alertYesLabel="Ya"
                  alertIsOpen={isOpen}
                  alertOnClose={onClose}
                  alertOnConfirm={onHandleLogout}
                />
              </VStack>
          </ScrollView>
        </ContainerPage>
      );
    
}

const styles = {
  errorTextStyle: {
    color: 'red',
    fontStyle: 'italic',
    fontWeight: 'bold',
    alignSelf: 'center'
  }
};

const mapStateToProps = createStructuredSelector({
  profileScreen: makeSelectProfileScreen(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onGetPegawai: () => dispatch(getPegawai())
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(ProfileScreen);