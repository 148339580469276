import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function updateInput({ name, value }) {
  return {
    type: constants.UPDATE_INPUT,
    name,
    value
  };
}

export function getUnitRequest() {
  return {
    type: constants.GET_UNIT_REQUEST
  };
}

export function getUnitRequestLoading() {
  return {
    type: constants.GET_UNIT_REQUEST_LOADING
  };
}

export function getUnitRequestFail(error) {
  return {
    type: constants.GET_UNIT_REQUEST_FAIL,
    error
  };
}

export function getUnitRequestSuccess(results) {
  return {
    type: constants.GET_UNIT_REQUEST_SUCCESS,
    results
  };
}

export function getMoreUnitRequest() {
  return {
    type: constants.GET_MORE_UNIT_REQUEST
  };
}

export function getMoreUnitRequestLoading() {
  return {
    type: constants.GET_MORE_UNIT_REQUEST_LOADING
  };
}

export function getMoreUnitRequestFail(error) {
  return {
    type: constants.GET_MORE_UNIT_REQUEST_FAIL,
    error
  };
}

export function getMoreUnitRequestSuccess(results) {
  return {
    type: constants.GET_MORE_UNIT_REQUEST_SUCCESS,
    results
  };
}

export function delUnitRequest(id_permintaan) {
  return {
    type: constants.DELETE_UNIT_REQUEST,
    id_permintaan
  };
}

export function delUnitRequestLoading() {
  return {
    type: constants.DELETE_UNIT_REQUEST_LOADING
  };
}

export function delUnitRequestFail(error) {
  return {
    type: constants.DELETE_UNIT_REQUEST_FAIL,
    error
  };
}

export function delUnitRequestSuccess(results) {
  return {
    type: constants.DELETE_UNIT_REQUEST_SUCCESS,
    results
  };
}