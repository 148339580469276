import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
 
  import * as constants from './constants';
  import moment from 'moment';
  import api from '../../util/api';
  import helper from '../../util/helper';
  
  import makeSelectWasteTransferScreen from './selector';
  import { delWasteTransferFail, delWasteTransferSuccess, getMoreTransferWasteFail, getMoreTransferWasteSuccess, getTransferWasteFail, getTransferWasteSuccess } from './actions';
  
  export function* doGetListTransferWaste(action) {
    const dataset = yield select(makeSelectWasteTransferScreen());
    try {
      const tglAwal = moment(dataset.start_dt).format("YYYY-MM-DD");
      const tglAkhir = moment(dataset.end_dt).format("YYYY-MM-DD HH:mm:ss");
      
      const params = {
        tgl_awal: tglAwal+' 00:00:00',
        tgl_akhir: tglAkhir,
        search: dataset.q,
        status: dataset.filter_status
      };

      if(helper.isEmpty(dataset.start_dt)) delete params.tgl_awal;
      if(helper.isEmpty(dataset.end_dt)) delete params.tgl_akhir;
      if(helper.isEmpty(dataset.q)) delete params.search;
      if(helper.isEmpty(dataset.filter_status)) delete params.status;

      const response = yield call(api.unit.getTransferList, params, dataset.page);
      const {success} = response.data;
      console.log("res:", success);
      if(dataset.page == 1){
        yield put(
          getTransferWasteSuccess((helper.isEmpty(success) ? [] : success)),
        );
      } else {
        yield put(
          getMoreTransferWasteSuccess((helper.isEmpty(success) ? [] : success)),
        );
      }
    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      if(dataset.page == 1){
        yield put(getTransferWasteFail(err));
      } else {
        yield put(getMoreTransferWasteFail(err));
      }
    }
  }

  export function* doDeleteListWasteTransfer(action) {
    const {id_transfer} = action;
    const dataset = yield select(makeSelectWasteTransferScreen());
    try {

      const params = {
        id_transfer_limbah: id_transfer,
      };

      const response = yield call(api.unit.deleteTransfer, params);
      const {success} = response.data;
      console.log("res:", success);
      helper.successToast("Permintaan berhasil dihapus");
      yield put(
        delWasteTransferSuccess((helper.isEmpty(success) ? [] : success)),
      );
    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      yield put(delWasteTransferFail(err));
    }
  }

  // Individual exports for testing
  export default function* wasteTransferScreenSaga() {
    yield all([
      takeLatest(constants.GET_TRANSFER_WASTE, doGetListTransferWaste),
      takeLatest(constants.GET_MORE_TRANSFER_WASTE, doGetListTransferWaste),
      takeLatest(constants.DELETE_WASTE_TRANSFER, doDeleteListWasteTransfer),
      takeLatest(constants.DELETE_WASTE_TRANSFER_SUCCESS, doGetListTransferWaste)
    ]);
  }
  