import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
 
  import * as constants from './constants';
  
  import makeSelectProfileScreen from './selector';
import { getPegawaiFail, getPegawaiSuccess } from './actions';
import api from '../../util/api';
import helper from '../../util/helper';

  export function* doGetPegawai(action) {
    const dataset = yield select(makeSelectProfileScreen());
    try {
      const response = yield call(api.user.getData);
      const {success} = response.data;
  
      yield put(
        getPegawaiSuccess(success),
      );
    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      yield put(getPegawaiFail(err));
    }
  }
  
  // Individual exports for testing
  export default function* profileScreenSaga() {
    yield all([
      takeLatest(constants.GET_PEGAWAI, doGetPegawai)
    ]);
  }
  