import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the ApprovalFormScreen state domain
 */

const selectApprovalFormScreenDomain = state =>
  state.approvalFormScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by ApprovalFormScreen
 */

const makeSelectApprovalFormScreen = () =>
  createSelector(
    selectApprovalFormScreenDomain,
    substate => substate,
  );

export default makeSelectApprovalFormScreen;
export { selectApprovalFormScreenDomain };
