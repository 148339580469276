export const DEFAULT_ACTION = 'screen/UnitRequestScreen/DEFAULT_ACTION';
export const UPDATE_INPUT = 'screen/UnitRequestScreen/UPDATE_INPUT';

export const GET_UNIT_REQUEST = 'screen/UnitRequestScreen/GET_UNIT_REQUEST';
export const GET_UNIT_REQUEST_LOADING = 'screen/UnitRequestScreen/GET_UNIT_REQUEST_LOADING';
export const GET_UNIT_REQUEST_SUCCESS = 'screen/UnitRequestScreen/GET_UNIT_REQUEST_SUCCESS';
export const GET_UNIT_REQUEST_FAIL = 'screen/UnitRequestScreen/GET_UNIT_REQUEST_FAIL';

export const GET_MORE_UNIT_REQUEST = 'screen/UnitRequestScreen/GET_MORE_UNIT_REQUEST';
export const GET_MORE_UNIT_REQUEST_LOADING = 'screen/UnitRequestScreen/GET_MORE_UNIT_REQUEST_LOADING';
export const GET_MORE_UNIT_REQUEST_SUCCESS = 'screen/UnitRequestScreen/GET_MORE_UNIT_REQUEST_SUCCESS';
export const GET_MORE_UNIT_REQUEST_FAIL = 'screen/UnitRequestScreen/GET_MORE_UNIT_REQUEST_FAIL';

export const DELETE_UNIT_REQUEST = 'screen/UnitRequestScreen/DELETE_UNIT_REQUEST';
export const DELETE_UNIT_REQUEST_LOADING = 'screen/UnitRequestScreen/DELETE_UNIT_REQUEST_LOADING';
export const DELETE_UNIT_REQUEST_SUCCESS = 'screen/UnitRequestScreen/DELETE_UNIT_REQUEST_SUCCESS';
export const DELETE_UNIT_REQUEST_FAIL = 'screen/UnitRequestScreen/DELETE_UNIT_REQUEST_FAIL';