import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function updateInput({ name, value }) {
  return {
    type: constants.UPDATE_INPUT,
    name,
    value
  };
}

export function getPenerimaanLimbah() {
  return {
    type: constants.GET_PENERIMAAN_LIMBAH
  };
}

export function getPenerimaanLimbahLoading() {
  return {
    type: constants.GET_PENERIMAAN_LIMBAH_LOADING
  };
}

export function getPenerimaanLimbahFail(error) {
  return {
    type: constants.GET_PENERIMAAN_LIMBAH_FAIL,
    error
  };
}

export function getPenerimaanLimbahSuccess(results) {
  return {
    type: constants.GET_PENERIMAAN_LIMBAH_SUCCESS,
    results
  };
}

export function getMorePenerimaanLimbah() {
  return {
    type: constants.GET_MORE_PENERIMAAN_LIMBAH
  };
}

export function getMorePenerimaanLimbahLoading() {
  return {
    type: constants.GET_MORE_PENERIMAAN_LIMBAH_LOADING
  };
}

export function getMorePenerimaanLimbahFail(error) {
  return {
    type: constants.GET_MORE_PENERIMAAN_LIMBAH_FAIL,
    error
  };
}

export function getMorePenerimaanLimbahSuccess(results) {
  return {
    type: constants.GET_MORE_PENERIMAAN_LIMBAH_SUCCESS,
    results
  };
}

export function delWasteAccept(id_penerimaan) {
  return {
    type: constants.DELETE_PENERIMAAN,
    id_penerimaan
  };
}

export function delWasteAcceptLoading() {
  return {
    type: constants.DELETE_PENERIMAAN_LOADING
  };
}

export function delWasteAcceptFail(error) {
  return {
    type: constants.DELETE_PENERIMAAN_FAIL,
    error
  };
}

export function delWasteAcceptSuccess(results) {
  return {
    type: constants.DELETE_PENERIMAAN_SUCCESS,
    results
  };
}