import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export function defaultAction() {
  console.log("reset state action");
  return {
    type: DEFAULT_ACTION,
  };
}

export function updateInput({ name, value }) {
  return {
    type: constants.UPDATE_INPUT,
    name,
    value
  };
}

export function submitPermintaan() {
  return {
    type: constants.SUBMIT_PERMINTAAN,
  };
}

export function submitPermintaanFail(error) {
  return {
    type: constants.SUBMIT_PERMINTAAN_FAIL,
    error
  };
}

export function submitPermintaanSuccess(results) {
  return {
    type: constants.SUBMIT_PERMINTAAN_SUCCESS,
    results
  };
}

export function addImage(asset) {
  return {
    type: constants.ADD_IMAGE,
    asset
  };
}

export function delImage(idx) {
  return {
    type: constants.DEL_IMAGE,
    idx
  };
}

export function delSavedDetail(idx) {
  return {
    type: constants.DEL_SAVED_DETAIL,
    idx
  };
}

export function getPermintaan(id_permintaan) {
  return {
    type: constants.GET_PERMINTAAN,
    id_permintaan
  };
}

export function getPermintaanFail(error) {
  return {
    type: constants.GET_PERMINTAAN_FAIL,
    error
  };
}

export function getPermintaanSuccess(results) {
  return {
    type: constants.GET_PERMINTAAN_SUCCESS,
    results
  };
}

export function setSavedDetail() {
  return {
    type: constants.SET_SAVED_DETAIL
  };
}