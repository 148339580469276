import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the PostAnnounceScreen state domain
 */

const selectPostAnnounceScreenDomain = state =>
  state.postAnnounceScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by PostAnnounceScreen
 */

const makeSelectPostAnnounceScreen = () =>
  createSelector(
    selectPostAnnounceScreenDomain,
    substate => substate,
  );

export default makeSelectPostAnnounceScreen;
export { selectPostAnnounceScreenDomain };
