import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export function defaultAction() {
  console.log("reset state action");
  return {
    type: DEFAULT_ACTION,
  };
}

export function updateInput({ name, value }) {
  return {
    type: constants.UPDATE_INPUT,
    name,
    value
  };
}

export function submitApproval() {
  return {
    type: constants.SUBMIT_APPROVAL
  };
}

export function submitApprovalFail(error) {
  return {
    type: constants.SUBMIT_APPROVAL_FAIL,
    error
  };
}

export function submitApprovalSuccess(results, id_aset) {
  return {
    type: constants.SUBMIT_APPROVAL_SUCCESS,
    results
  };
}

export function addImage(asset) {
  return {
    type: constants.ADD_IMAGE,
    asset
  };
}

export function delImage(idx) {
  return {
    type: constants.DEL_IMAGE,
    idx
  };
}

export function getApprovalDetail() {
  return {
    type: constants.GET_APPROVAL_DETAIL
  };
}

export function getApprovalDetailFail(error) {
  return {
    type: constants.GET_APPROVAL_DETAIL_FAIL,
    error
  };
}

export function getApprovalDetailSuccess(dtApproval, dtRequest) {
  return {
    type: constants.GET_APPROVAL_DETAIL_SUCCESS,
    dtApproval,
    dtRequest
  };
}