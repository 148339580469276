import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import makeSelectWasteTransferFormScreen from './selector';
import reducer from './reducer';
import saga from './saga';

// import {ScrollView,RefreshControl, BackHandler, PermissionsAndroid, StyleSheet, View, Dimensions } from 'react-native';
import {
  AspectRatio,
  Icon,
  HStack, 
  Avatar, 
  Heading, 
  VStack, 
  Box, 
  Center,
  Text,
  Pressable,
  TextArea,
  FormControl,
  Divider,
  Image,
  Modal,
  Flex,
  ScrollView, 
  Button,
  Select,
  CheckIcon,
  Stack,
  Menu,
  Fab} from 'native-base';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import Files from 'react-files';
import 'react-html5-camera-photo/build/css/index.css';
import {FaBuilding, FaCalendarAlt, FaCheckCircle, FaHourglassEnd, FaTimesCircle} from 'react-icons/fa';
import {MdAddAPhoto, MdDelete, MdDownload, MdFileCopy, MdModeEdit, MdMoreVert, MdRemoveRedEye} from 'react-icons/md';
import { ContainerPage } from '../../components/ContainerPage';
import { commonStyle } from '../../styles';
import { addImage, defaultAction, delImage, getPelaporan, getTransferDetail, submitPelaporan, updateInput } from './actions';
import api from '../../util/api';
import helper from '../../util/helper';
import BadgeStatus from '../../components/BadgeStatus';
import ImageSlider from '../../components/ImageSlider';
import { AiOutlinePlus } from 'react-icons/ai';
import history from '../../util/history';
import moment from 'moment';
import MyAlert from '../../components/MyAlert';
import LoadingOverlay from 'react-loading-overlay';

export function WasteTransferFormScreen({
  dispatch,
  location,
  navigation,
  wasteTransferFormScreen,
  onUpdateInput,
  onAddImage,
  onDelImage,
  onSubmitTransfer,
  onGetTransfer
}){
  const {
    id_transfer, 
    id_approval, 
    approval_code, 
    code_asal, 
    tgl_pengajuan, 
    origin_screen, 
    viewOnly
  } = location.state;
  const[userData, setUserData] = useState();
  const [photo, setPhoto] = useState(null);
  const [openCamera, setOpenCamera] = useState(false);
  const [openModalCamera, setOpenModalCamera] = useState(false);

  const imageList = [
    {path: '../../images/pintu.jpg'},
    {path: '../../images/pintu.jpg'},
    {path: '../../images/pintu.jpg'},
  ];

  useEffect(() => {
    // reset params
    dispatch(defaultAction());

    // console.log('index-first', id_approval);

    if(!helper.isEmpty(id_approval)){
      // console.log('index-first');
      onGetTransfer(id_transfer, id_approval, approval_code);
    }
    
  },[]);

  const renderAccordionDetail = (row, idx) => {
    // console.log('kepanggil:', wasteTransferFormScreen.transfer_detail);
    
    let showApprovalKasie = false;
    if(wasteTransferFormScreen.transfer_detail.length == 0){
      showApprovalKasie = true;
    } else {
      const idxTrfLimbah = wasteTransferFormScreen.transfer_detail?.transfer_limbah_detail.findIndex(
        result => result.id_approval_detail === row.id,
      );
      if(idxTrfLimbah < 0) showApprovalKasie = true;
    } 

    return (
        <>
        {showApprovalKasie &&
          <AccordionItem key={idx} header={
            <Box px={5} py={3} backgroundColor={commonStyle.cardBaseHeaderBg}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Box>
                <Icon color="darkText" as={<Box><MdFileCopy/></Box>} size={5}></Icon>
              </Box>
              <Box flex={1} ml={1} alignItems="start">
                <Text color="darkText" fontWeight={500} >
                  {/* {moment(data.date_report, "YYYY-MM-DD HH:mm:ss").format("DD MMM YYYY HH:mm")} */}
                  Detail Permintaan
                </Text>
              </Box>
            </Stack>
          </Box>
          } initialEntered>
            {/* <Flex m={3}>
              <ImageSlider key={i} images={row.tf_det_attach}/>
            </Flex> */}
            <VStack backgroundColor="white" p={3}>
            <Box mb={2}>
              <Text fontWeight="bold">
                {wasteTransferFormScreen.approval_detail?.ms_unit_tujuan.nama}
              </Text>
            </Box>
            <HStack flex={1} mb={2}>
              <Stack flex={1} direction="row" justifyContent="space-between" alignItems="center">
                <Box>
                  <Icon color="muted.400" as={<Box><FaCalendarAlt/></Box>} size={3}></Icon>
                </Box>
                <Box flex={1} ml={1}>
                  <Text color="muted.400" fontWeight={500} fontSize="xs">
                    {moment(row.date_action, "YYYY-MM-DD HH:mm:ss").format("DD MMM YYYY HH:mm")}
                  </Text>
                </Box>
              </Stack>
              <Stack flex={1} direction="row" justifyContent="space-between" alignItems="center">
                <Box>
                  <Icon color="muted.400" as={<Box><FaHourglassEnd/></Box>} size={3}></Icon>
                </Box>
                <Box flex={1} ml={1}>
                  <Text color="muted.400" fontWeight={500} fontSize="xs">
                    {row.status != '30' ? 'Created' : 'Done'}
                  </Text>
                </Box>
              </Stack>
            </HStack>
            <HStack flex={1} mb={2}>
              <Stack flex={1} direction="row" justifyContent="space-between" alignItems="center">
                <Box>
                  <Icon color="muted.400" as={<Box><FaBuilding/></Box>} size={3}></Icon>
                </Box>
                <Box flex={1} ml={1}>
                  <Text color="muted.400" fontWeight={500} fontSize="xs">
                    {wasteTransferFormScreen.approval_detail?.ms_unit_t_p_s.nama}
                  </Text>
                </Box>
              </Stack>
            </HStack>
              <FormControl mb="1">
                <FormControl.Label>Jenis Limbah</FormControl.Label>
                <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                  <Text textAlign="left">
                    {row.approval_request?.ms_jns_limbah?.nama}
                  </Text>
                </Box>
              </FormControl>
              <FormControl mb="1">
                <FormControl.Label>Volume (L)</FormControl.Label>
                <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                  <Text textAlign="right">{row.approval_request?.volume}</Text>
                </Box>
              </FormControl>
              <FormControl mb="1">
                <FormControl.Label>Berat (kg)</FormControl.Label>
                <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                  <Text textAlign="right">{row.approval_request?.berat}</Text>
                </Box>
              </FormControl>
              <FormControl mb="3">
                <FormControl.Label>Noted</FormControl.Label>
                <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                  <Text>
                    {row.noted}
                  </Text>
                </Box>
              </FormControl>
              {!viewOnly &&
                <Button w="full" isLoading={wasteTransferFormScreen.loading} spinnerPlacement="end" isLoadingText="Submitting..." colorScheme="primary" onPress={() => history.push('waste-transfer-form-detail', {...location.state, id_detail: row.id})}>Update Progres</Button>
              }
            </VStack>
          </AccordionItem>
        }
        {!showApprovalKasie && wasteTransferFormScreen.transfer_detail?.transfer_limbah_detail.filter((el) => { return el.id_approval_detail === row.id }).map((row, i) => (
          <AccordionItem key={idx} header={
            <Box px={5} py={3} backgroundColor={commonStyle.cardBaseHeaderBg}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Box>
                <Icon color="darkText" as={<Box><MdFileCopy/></Box>} size={5}></Icon>
              </Box>
              <Box flex={1} ml={1} alignItems="start">
                <Text color="darkText" fontWeight={500} >
                  {/* {moment(data.date_report, "YYYY-MM-DD HH:mm:ss").format("DD MMM YYYY HH:mm")} */}
                  Detail Permintaan
                </Text>
              </Box>
            </Stack>
          </Box>
          } initialEntered>
            <Flex m={3}>
              <ImageSlider key={i} images={row.tf_det_attach}/>
            </Flex>
            <VStack backgroundColor="white" p={3}>
            <Box mb={2}>
              <Text fontWeight="bold">
                {wasteTransferFormScreen.approval_detail?.ms_unit_tujuan.nama}
              </Text>
            </Box>
            <HStack flex={1} mb={2}>
              <Stack flex={1} direction="row" justifyContent="space-between" alignItems="center">
                <Box>
                  <Icon color="muted.400" as={<Box><FaCalendarAlt/></Box>} size={3}></Icon>
                </Box>
                <Box flex={1} ml={1}>
                  <Text color="muted.400" fontWeight={500} fontSize="xs">
                    {moment(wasteTransferFormScreen.approval_detail?.approvalkasie_detail.filter((el) => {return  el.id === row.id_approval_detail})[0].date_action, "YYYY-MM-DD HH:mm:ss").format("DD MMM YYYY HH:mm")}
                  </Text>
                </Box>
              </Stack>
              <Stack flex={1} direction="row" justifyContent="space-between" alignItems="center">
                <Box>
                  <Icon color="muted.400" as={<Box><FaHourglassEnd/></Box>} size={3}></Icon>
                </Box>
                <Box flex={1} ml={1}>
                  <Text color="muted.400" fontWeight={500} fontSize="xs">
                    {wasteTransferFormScreen.approval_detail?.approvalkasie_detail.filter((el) => {return  el.id === row.id_approval_detail})[0].status != '30' ? 'Created' : 'Done'}
                    
                  </Text>
                </Box>
              </Stack>
            </HStack>
            <HStack flex={1} mb={2}>
              <Stack flex={1} direction="row" justifyContent="space-between" alignItems="center">
                <Box>
                  <Icon color="muted.400" as={<Box><FaBuilding/></Box>} size={3}></Icon>
                </Box>
                <Box flex={1} ml={1}>
                  <Text color="muted.400" fontWeight={500} fontSize="xs">
                    {wasteTransferFormScreen.approval_detail?.ms_unit_t_p_s.nama}
                  </Text>
                </Box>
              </Stack>
            </HStack>
              <FormControl mb="1">
                <FormControl.Label>Jenis Limbah</FormControl.Label>
                <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                  <Text textAlign="left">
                    {row.ms_jns_limbah?.nama}
                  </Text>
                </Box>
              </FormControl>
              <FormControl mb="1">
                <FormControl.Label>Volume (L)</FormControl.Label>
                <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                  <Text textAlign="right">{row.volume}</Text>
                </Box>
              </FormControl>
              <FormControl mb="1">
                <FormControl.Label>Berat (kg)</FormControl.Label>
                <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                  <Text textAlign="right">{row.berat}</Text>
                </Box>
              </FormControl>
              <FormControl mb="3">
                <FormControl.Label>Noted</FormControl.Label>
                <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                  <Text>
                    {row.noted}
                  </Text>
                </Box>
              </FormControl>
              {!viewOnly &&
                <Button w="full" isLoading={wasteTransferFormScreen.loading} spinnerPlacement="end" isLoadingText="Submitting..." colorScheme="primary" onPress={() => history.push('waste-transfer-form-detail', {...location.state, id_detail: row.id})}>Update Progres</Button>
              }
            </VStack>
          </AccordionItem>
        ))}
        </>
    );
  }

  return (
        <ContainerPage options={{statusBar: true, prevPage: 'waste-transfer', title: 'Transfer Limbah', params:{}}}>
          <Flex flex={1} minW="300px">
            <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
              <LoadingOverlay
                active={wasteTransferFormScreen.loading}
                spinner
                text='Loading...'
                >
              <Box p={5} borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor} mx={3} mt={7}>
                <VStack>
                  <Center>
                    <Avatar size="lg" bg="primary.900" mb={4}>
                        {helper.getInitials(wasteTransferFormScreen.approval_detail.ms_vendor?.nama)}
                    </Avatar>
                  </Center>
                  <Center>
                    <Text color="darkText" isTruncated maxWidth="90%" fontSize="md" fontWeight="bold">
                      {wasteTransferFormScreen.approval_detail.ms_vendor?.nama}
                    </Text>
                  </Center>
                  <Center>
                    <Text color="muted.400" isTruncated maxWidth="90%" fontSize="sm">
                      {wasteTransferFormScreen.approval_detail.ms_vendor?.hp}
                    </Text>
                  </Center>

                  <HStack flex={1} space={4} mb={2}>
                    <VStack flex={1}>
                      <Text fontSize="xs" color="muted.600">No Permintaan Unit / Fakultas</Text>
                      <Text color="darkText" bold>
                        {code_asal}
                      </Text>
                    </VStack>
                    <VStack flex={1}>
                      <Text fontSize="xs" color="muted.600">Tanggal Permintaan</Text>
                      <Text color="darkText" bold>
                        {moment(tgl_pengajuan, "YYYY-MM-DD HH:mm:ss").format("DD MMMM YYYY")}
                      </Text>
                    </VStack>
                  </HStack>
                  <HStack flex={1} space={4} mb={2}>
                    <VStack flex={1}>
                      <Text fontSize="xs" color="muted.600">Unit Tujuan</Text>
                      <Text color="darkText" bold>
                        {wasteTransferFormScreen.approval_detail?.ms_unit_t_p_s?.nama}
                      </Text>
                    </VStack>
                    <VStack flex={1}>
                      <Text fontSize="xs" color="muted.600">Lokasi Tujuan</Text>
                      <Text color="darkText" bold>
                        {wasteTransferFormScreen.approval_detail?.lokasi_tujuan?.nama}
                      </Text>
                    </VStack>
                  </HStack>
                </VStack>
              </Box>
              <VStack space={7} mt={3} mb={35} mx={3}>
                <Box>
                  <Accordion>
                    {wasteTransferFormScreen.approval_detail?.approvalkasie_detail.map((row, i) => renderAccordionDetail(row, i))}
                  </Accordion>
                </Box>
                {/* {wasteTransferFormScreen.transfer_detail?.transfer_limbah_detail?.length > 0 && 
                  <Box>
                    <Accordion>
                    {wasteTransferFormScreen.transfer_detail?.transfer_limbah_detail.map((row, i) => (
                      <AccordionItem key={i} header={
                        <Box px={5} py={3} backgroundColor={commonStyle.cardBaseHeaderBg}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                          <Box>
                            <Icon color="darkText" as={<Box><MdFileCopy/></Box>} size={5}></Icon>
                          </Box>
                          <Box flex={1} ml={1} alignItems="start">
                            <Text color="darkText" fontWeight={500} >
                              Detail Permintaan
                            </Text>
                          </Box>
                        </Stack>
                      </Box>
                      } initialEntered>
                        <Flex m={3}>
                          <ImageSlider key={i} images={row.tf_det_attach}/>
                        </Flex>
                        <VStack backgroundColor="white" p={3}>
                        <Box mb={2}>
                          <Text fontWeight="bold">
                            {wasteTransferFormScreen.approval_detail?.ms_unit_tujuan.nama}
                          </Text>
                        </Box>
                        <HStack flex={1} mb={2}>
                          <Stack flex={1} direction="row" justifyContent="space-between" alignItems="center">
                            <Box>
                              <Icon color="muted.400" as={<Box><FaCalendarAlt/></Box>} size={3}></Icon>
                            </Box>
                            <Box flex={1} ml={1}>
                              <Text color="muted.400" fontWeight={500} fontSize="xs">
                                {moment(wasteTransferFormScreen.approval_detail?.approvalkasie_detail.filter((el) => {return  el.id === row.id_approval_detail})[0].date_action, "YYYY-MM-DD HH:mm:ss").format("DD MMM YYYY HH:mm")}
                              </Text>
                            </Box>
                          </Stack>
                          <Stack flex={1} direction="row" justifyContent="space-between" alignItems="center">
                            <Box>
                              <Icon color="muted.400" as={<Box><FaHourglassEnd/></Box>} size={3}></Icon>
                            </Box>
                            <Box flex={1} ml={1}>
                              <Text color="muted.400" fontWeight={500} fontSize="xs">
                                {wasteTransferFormScreen.approval_detail?.approvalkasie_detail.filter((el) => {return  el.id === row.id_approval_detail})[0].status != '30' ? 'Created' : 'Done'}
                                
                              </Text>
                            </Box>
                          </Stack>
                        </HStack>
                        <HStack flex={1} mb={2}>
                          <Stack flex={1} direction="row" justifyContent="space-between" alignItems="center">
                            <Box>
                              <Icon color="muted.400" as={<Box><FaBuilding/></Box>} size={3}></Icon>
                            </Box>
                            <Box flex={1} ml={1}>
                              <Text color="muted.400" fontWeight={500} fontSize="xs">
                                {wasteTransferFormScreen.approval_detail?.ms_unit_t_p_s.nama}
                              </Text>
                            </Box>
                          </Stack>
                        </HStack>
                          <FormControl mb="1">
                            <FormControl.Label>Jenis Limbah</FormControl.Label>
                            <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                              <Text textAlign="left">
                                {row.ms_jns_limbah?.nama}
                              </Text>
                            </Box>
                          </FormControl>
                          <FormControl mb="1">
                            <FormControl.Label>Volume (L)</FormControl.Label>
                            <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                              <Text textAlign="right">{row.volume}</Text>
                            </Box>
                          </FormControl>
                          <FormControl mb="1">
                            <FormControl.Label>Berat (kg)</FormControl.Label>
                            <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                              <Text textAlign="right">{row.berat}</Text>
                            </Box>
                          </FormControl>
                          <FormControl mb="3">
                            <FormControl.Label>Noted</FormControl.Label>
                            <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                              <Text>
                                {row.noted}
                              </Text>
                            </Box>
                          </FormControl>
                          {!viewOnly &&
                            <Button w="full" isLoading={wasteTransferFormScreen.loading} spinnerPlacement="end" isLoadingText="Submitting..." colorScheme="primary" onPress={() => history.push('waste-transfer-form-detail', {...location.state, id_detail: row.id})}>Update Progres</Button>
                          }
                        </VStack>
                      </AccordionItem>
                    ))}
                    </Accordion>
                  </Box>
                } */}
              </VStack>
              </LoadingOverlay>
            </ScrollView>
          </Flex>
        </ContainerPage>
      );
    
}

const mapStateToProps = createStructuredSelector({
  wasteTransferFormScreen: makeSelectWasteTransferFormScreen(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onAddImage: asset => dispatch(addImage(asset)),
    onDelImage: idx => dispatch(delImage(idx)),
    onSubmitTransfer: (id_branch, origin_screen) => dispatch(submitPelaporan(id_branch, origin_screen)),
    onGetTransfer: (id_transfer, id_approval, approval_code) => dispatch(getTransferDetail(id_transfer, id_approval, approval_code)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(WasteTransferFormScreen);