import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
 
  import * as constants from './constants';
  import moment from 'moment';
  import helper from '../../util/helper';
  import api from '../../util/api';
  import makeSelectApprovalScreen from './selector';
  import makeSelectGlobalVar from '../../util/selector';
import { getApprovalDetailFail, getApprovalDetailSuccess, getListApprovalFail, getListApprovalSuccess, getMoreListApprovalFail, getMoreListApprovalSuccess, getTotalApprovalFail, getTotalApprovalSuccess, submitApprovalFail, submitApprovalSuccess, updateInput } from './actions';
  
  export function* doGetListApproval(action) {
    const dataset = yield select(makeSelectApprovalScreen());
    try {
      const tglAwal = moment(dataset.start_dt).format("YYYY-MM-DD");
      const tglAkhir = moment(dataset.end_dt).format("YYYY-MM-DD HH:mm:ss");
      
      const params = {
        tgl_awal: tglAwal+' 00:00:00',
        tgl_akhir: tglAkhir,
        search: dataset.q,
        status: dataset.status
      };

      if(params.status == 0) delete params.status;

      const response = yield call(api.approval.getApprovalList, params, dataset.page);
      const {success} = response.data;
      if(dataset.page == 1){
        yield put(
          getListApprovalSuccess((helper.isEmpty(success) ? [] : success)),
        );
      } else {
        yield put(
          getMoreListApprovalSuccess((helper.isEmpty(success) ? [] : success)),
        );
      }
      console.log("res:", success);
    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      if(dataset.page == 1){
        yield put(getListApprovalFail(err));
      } else {
        yield put(getMoreListApprovalFail(err));
      }
    }
  }

  export function* doGetTotalApproval(action) {
    const dataset = yield select(makeSelectApprovalScreen());
    try {
      const tglAwal = moment(dataset.start_dt).format("YYYY-MM-DD");
      const tglAkhir = moment(dataset.end_dt).format("YYYY-MM-DD HH:mm:ss");
      
      const params = {
        tgl_awal: tglAwal+' 00:00:00',
        tgl_akhir: tglAkhir,
        search: dataset.q,
        status: dataset.filter_status
      };

      const response = yield call(api.approval.getJmlApprovalList, params);
      const {success} = response.data;
      console.log("total:", success);
      yield put(
        getTotalApprovalSuccess((helper.isEmpty(success) ? [] : success.data)),
      );
      console.log("res:", success.data);
    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      yield put(getTotalApprovalFail(err));
    }
  }

  export function* doGetApprovalDetail(action) {
    const {rawApv} = action;
    const dataset = yield select(makeSelectApprovalScreen());

    try {
      const dtRealisasi = moment(rawApv.date_realisasi).format("YYYY-MM-DD");
      let response = [];
      
      if(rawApv.code_proses == 1){
        const params = {
          id_gedung: rawApv.id_gedung,
          schedule_type: rawApv.id,
          date_realisasi: dtRealisasi,
        };
        response = yield call(api.getApprovalMaintainPreventifDetail, params);
      } else {
        response = yield call(api.getApprovalMaintainDetail, rawApv.id, (parseInt(rawApv.code_proses) || 0), dtRealisasi);
      }
      
      const {data} = response.data;
      console.log("res-detail-apv:", response.data);
      yield put(
        getApprovalDetailSuccess((helper.isEmpty(data) ? null : data), rawApv)
      );
    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      yield put(getApprovalDetailFail(err));
    }
  }
  export function* doSubmitApproval(action) {
    // const {code_proses, action_type} = action;
    const globalVar = yield select(makeSelectGlobalVar());
    const dataset = yield select(makeSelectApprovalScreen());
    const {raw_approval, detail_approval} = dataset;
    try {
      let params = {};
      let response = {};
      switch (raw_approval.code_proses) {
        case 1:
          params = {
              id_gedung: raw_approval.id_gedung,
              schedule_type: raw_approval.id,
              noted: 'Segera Laksanakan',
              date_realisasi: moment(raw_approval.date_realisasi).format('DD-MM-YYYY'),
              date_approve: moment().format('DD-MM-YYYY'),
              jadwal_detail: JSON.stringify(detail_approval),
              jadwal_detail_delete: '[]'
          };
          response = yield call(api.submitApprovalPreventif, params);
          break;
        case 2:
          params = {
              id: detail_approval.id,
              id_item: detail_approval.id_item,
              id_pelapor: detail_approval.id_pegawai_report,
              id_petugas: (globalVar.list_pegawai ? globalVar.list_pegawai[0].id : 0),
              id_inventaris: detail_approval.id_inventaris,
              code_proses: raw_approval.code_proses,
              description: 'Segera Laksanakan'
          };
          response = yield call(api.submitApproval, params, raw_approval.code_proses);
          break;
        case 3:
          if(detail_approval.submit_penawaran.length == 0){
            // helper.warningToast('Data Penawaran belum ditemukan');
            yield put(submitApprovalFail());
            // yield put(updateInput('loading_approval', false));
            return;
          }
          params = {
            id: detail_approval.id,
            id_wo: (detail_approval.w_o ? detail_approval.w_o.id : 0),
            id_vendor: detail_approval.submit_penawaran[0].id_vendor,
            id_pegawai_cp: detail_approval.submit_penawaran[0].ms_vendor.id_pegawai_cp,
            id_submit_penawaran: detail_approval.submit_penawaran[0].id,
            id_penawaran_request: detail_approval.submit_penawaran[0].id_penawaran_request,
            tgl_dateLine: moment(detail_approval.submit_penawaran[0].waktu_pengerjaan).format('YYYY-MM-DD'),
            amount_pagu: detail_approval.submit_penawaran[0].harga,
            description: 'Segera Laksanakan',
          };
          response = yield call(api.submitApprovalVendor, params, raw_approval.code_proses);
          break;
        case 4:
          params = {
              id: detail_approval.id,
              id_pem_korektif: detail_approval.wo_detail.id_pemeliharaan_korektif,
              id_vendor: detail_approval.id_vendor,
              id_item: detail_approval.wo_detail.id_item,
              id_inventaris: detail_approval.wo_detail.id_inventaris,
              id_petugas: (globalVar.list_pegawai ? globalVar.list_pegawai[0].id : 0),
              description: 'Segera Laksanakan',
              txtNmInven: detail_approval.wo_detail.ms_inventaris.nama,
              txtNmVendor: detail_approval.ms_vendor.nama,
          };
          response = yield call(api.submitApproval, params, raw_approval.code_proses);
          break;
        default:
          break;
      }
 
      console.log(response);
      yield put(
        submitApprovalSuccess(response.data),
      );

    } catch (err) {
      console.log(err);
      yield put(submitApprovalFail(err));
    }
  }
  // Individual exports for testing
  export default function* approvalScreenSaga() {
    yield all([
      takeLatest(constants.GET_LIST_APPROVAL, doGetListApproval),
      takeLatest(constants.GET_MORE_LIST_APPROVAL, doGetListApproval),
      takeLatest(constants.GET_LIST_APPROVAL, doGetTotalApproval),
      takeLatest(constants.GET_APPROVAL_DETAIL, doGetApprovalDetail),
      takeLatest(constants.GET_APPROVAL_DETAIL_SUCCESS, doSubmitApproval),
    ]);
  }
  