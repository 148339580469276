import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import makeSelectWasteAcceptanceFormScreen from './selector';
import reducer from './reducer';
import saga from './saga';

// import {ScrollView,RefreshControl, BackHandler, PermissionsAndroid, StyleSheet, View, Dimensions } from 'react-native';
import {
  AspectRatio,
  Icon,
  HStack, 
  Avatar, 
  Heading, 
  VStack, 
  Box, 
  Center,
  Text,
  Pressable,
  TextArea,
  FormControl,
  Divider,
  Image,
  Modal,
  Flex,
  ScrollView, 
  Button,
  Select,
  CheckIcon,
  Stack,
  Menu,
  Fab} from 'native-base';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import Files from 'react-files';
import 'react-html5-camera-photo/build/css/index.css';
import {FaBuilding, FaCalendarAlt, FaCheckCircle, FaHourglassEnd, FaTimesCircle} from 'react-icons/fa';
import {MdAddAPhoto, MdDelete, MdDownload, MdFileCopy, MdModeEdit, MdMoreVert, MdRemoveRedEye} from 'react-icons/md';
import { ContainerPage } from '../../components/ContainerPage';
import { commonStyle } from '../../styles';
import { addImage, defaultAction, delImage, getPelaporan, getPenerimaanDetail, submitPelaporan, submitPenerimaan, updateInput } from './actions';
import api from '../../util/api';
import helper from '../../util/helper';
import BadgeStatus from '../../components/BadgeStatus';
import ImageSlider from '../../components/ImageSlider';
import { AiOutlinePlus } from 'react-icons/ai';
import history from '../../util/history';
import moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';

export function WasteAcceptanceFormScreen({
  dispatch,
  location,
  navigation,
  wasteAcceptanceFormScreen,
  onUpdateInput,
  onAddImage,
  onDelImage,
  onSubmitPenerimaan,
  onGetPenerimaan
}){
  const {id_transfer, id_penerimaan, origin_screen, viewOnly} = location.state;
  const[userData, setUserData] = useState();
  const [photo, setPhoto] = useState(null);
  const [openCamera, setOpenCamera] = useState(false);
  const [openModalCamera, setOpenModalCamera] = useState(false);

  const imageList = [
    {path: '../../images/pintu.jpg'},
    {path: '../../images/pintu.jpg'},
    {path: '../../images/pintu.jpg'},
  ];

  const status_detail = {
    '10': 'created',
    '20': 'batal',
    '30': 'approve',
    '40': 'done'
  };

  useEffect(() => {
    // reset params
    dispatch(defaultAction());

    // onUpdateInput('id_transfer', id_transfer);
    // onUpdateInput('nm_aset', nm_aset);
    // onUpdateInput('id_item', id_item);
    // onUpdateInput('id_aset', id_aset);
    // if(!helper.isEmpty(dtGedung)){
    //   onUpdateInput('nm_ruangan', dtGedung.nm_ruangan);
    // }
    if(id_transfer > 0){
      onGetPenerimaan(id_transfer, id_penerimaan);
    }
    console.log("id_l", id_transfer);
  },[]);
  

  const handleChoosePhoto = async (data) => {
    setOpenCamera(false);
    console.log("img:", data);
    setTimeout(() => {
      setOpenModalCamera(false);
    }, 300);
    if(wasteAcceptanceFormScreen.assets.length == 4){
      helper.infoToast("Anda melebihi batas maksimum yakni 4 foto.");
      return;
    }

    try {
      if(!helper.isEmpty(data)) onAddImage(data);
      // const granted = await PermissionsAndroid.request(
      //   PermissionsAndroid.PERMISSIONS.CAMERA,
      //   {
      //     title: "App Camera Permission",
      //     message:"App needs access to your camera",
      //     buttonNeutral: "Ask Me Later",
      //     buttonNegative: "Cancel",
      //     buttonPositive: "OK"
      //   }
      // );
      // if (granted === PermissionsAndroid.RESULTS.GRANTED) {
      //   console.log("Camera permission given");
      //   launchCamera({ 
      //     mediaType: 'photo',
      //     maxWidth: 800,
      //     maxHeight: 800,
      //     includeBase64: true,
      //     cameraType: 'back'
      //   }, (response) => {
      //     console.log(response);
      //     if (response) {
      //       if(!helper.isEmpty(response.assets)) onAddImage(response.assets[0]);
      //     }
      //   });
      // } else {
      //   console.log("Camera permission denied");
      // }
    } catch (err) {
      console.warn(err);
    }
  };

  const handleGetData = () => {
    if(id_transfer > 0){
      onGetPenerimaan(id_transfer, id_penerimaan);
    }
  }

  const handleCameraOpen = () => {
    setOpenModalCamera(true);
    setTimeout(() => {
      setOpenCamera(true);
    }, 300);
  }

  function handleTakePhotoAnimationDone (dataUri) {
    // Do stuff with the photo...
    // console.log('takePhoto');
  }

  function handleCameraError (error) {
    // console.log('handleCameraError', error);
  }

  function handleCameraStart (stream) {
    // console.log('handleCameraStart : ', stream);
  }

  function handleCameraStop () {
    // console.log('handleCameraStop');
  }

  const getBase64 = file => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };

  const handleFileChange = (files) => {
    console.log(files);
    const file = files[0];
    getBase64(file)
      .then(result => {
        file["base64"] = result;
        console.log("File Is", file);
        const params = {
          base64: result,
          type: file.type,
          name: file.name,
        }

        onAddImage(params.base64);
      })
      .catch(err => {
        console.log(err);
      });
  }

  const handleFileError = (error, file) => {
    console.log('error code ' + error.code + ': ' + error.message)
    helper.errorToast(error.message);
  }

  const renderAccordionDetail = (row, idx) => {
    let showDataTransfer = false;
    if(wasteAcceptanceFormScreen.detailPenerimaan.length == 0){
      showDataTransfer = true;
    } else {
      const idxTrfLimbah = wasteAcceptanceFormScreen.detailPenerimaan?.penerimaan_limbah_detail.findIndex(
        result => result.id_transfer_limbah_detail === row.id,
      );
      if(idxTrfLimbah < 0) showDataTransfer = true;

      // console.log('first-idxlimbah', idxTrfLimbah);
    }

    return (<>
      {showDataTransfer &&
        <AccordionItem key={idx} header={
        <Box px={5} py={3} backgroundColor={commonStyle.cardBaseHeaderBg}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Box>
            <Icon color="darkText" as={<Box><MdFileCopy/></Box>} size={5}></Icon>
          </Box>
          <Box flex={1} ml={1} alignItems="start">
            <Text color="darkText" fontWeight={500} >
              Detail Penerimaan
            </Text>
          </Box>
        </Stack>
      </Box>
      } initialEntered>
        {/* <Flex m={3}>
          <ImageSlider key={`img${idx}`} images={imageList}/>
        </Flex> */}
        <VStack backgroundColor="white" p={3}>
        <HStack flex={1} mb={2}>
          <Stack flex={1} direction="row" justifyContent="space-between" alignItems="center">
            <Box>
              <Icon color="muted.400" as={<Box><FaCalendarAlt/></Box>} size={3}></Icon>
            </Box>
            <Box flex={1} ml={1}>
              <Text color="muted.400" fontWeight={500} fontSize="xs">
                {moment(row.date_action, "YYYY-MM-DD HH:mm:ss").format("DD MMM YYYY HH:mm")}
              </Text>
            </Box>
          </Stack>
          <Stack flex={1} direction="row" justifyContent="space-between" alignItems="center">
            <Box>
              <Icon color="muted.400" as={<Box><FaHourglassEnd/></Box>} size={3}></Icon>
            </Box>
            <Box flex={1} ml={1}>
              <Text color="muted.400" fontWeight={500} fontSize="xs">
                {row.status ? status_detail[row.status]: status_detail['10']}
              </Text>
            </Box>
          </Stack>
        </HStack>
        <HStack flex={1} mb={2}>
          <Stack flex={1} direction="row" justifyContent="space-between" alignItems="center">
            <Box>
              <Icon color="muted.400" as={<Box><FaBuilding/></Box>} size={3}></Icon>
            </Box>
            <Box flex={1} ml={1}>
              <Text color="muted.400" fontWeight={500} fontSize="xs">
                  {wasteAcceptanceFormScreen.detailTransfer?.ms_unit_tujuan?.nama}
              </Text>
            </Box>
          </Stack>
        </HStack>
          <FormControl mb="1">
            <FormControl.Label>Jenis Limbah</FormControl.Label>
            <Box borderRadius="md" backgroundColor="gray.100" p={3}>
              <Text textAlign="left">{row.ms_jns_limbah?.nama}</Text>
            </Box>
          </FormControl>
          <FormControl mb="1">
            <FormControl.Label>Volume (L)</FormControl.Label>
            <Box borderRadius="md" backgroundColor="gray.100" p={3}>
              <Text textAlign="right">{row.volume}</Text>
            </Box>
          </FormControl>
          <FormControl mb="1">
            <FormControl.Label>Berat (kg)</FormControl.Label>
            <Box borderRadius="md" backgroundColor="gray.100" p={3}>
              <Text textAlign="right">{row.berat}</Text>
            </Box>
          </FormControl>
          <FormControl mb="3">
            <FormControl.Label>Noted</FormControl.Label>
            <Box borderRadius="md" backgroundColor="gray.100" p={3}>
              <Text>{row.noted}</Text>
            </Box>
          </FormControl>
          {!viewOnly &&
            <Button w="full" isLoading={wasteAcceptanceFormScreen.loading} spinnerPlacement="end" isLoadingText="Submitting..." colorScheme="primary" onPress={() => history.push('waste-accept-form-detail', {...location.state, id_detail: row.id})}>Update Penerimaan</Button>
          }
          </VStack>
        </AccordionItem>
      }
      {!showDataTransfer && wasteAcceptanceFormScreen.detailPenerimaan?.penerimaan_limbah_detail.filter((el) => { return el.id_transfer_limbah_detail === row.id }).map((row, i) => (
        <AccordionItem key={idx} header={
          <Box px={5} py={3} backgroundColor={commonStyle.cardBaseHeaderBg}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box>
              <Icon color="darkText" as={<Box><MdFileCopy/></Box>} size={5}></Icon>
            </Box>
            <Box flex={1} ml={1} alignItems="start">
              <Text color="darkText" fontWeight={500} >
                Detail Penerimaan
              </Text>
            </Box>
          </Stack>
        </Box>
        } initialEntered>
          <Flex m={3}>
            <ImageSlider key={`img${idx}`} images={row.pen_det_attach}/>
          </Flex>
          <VStack backgroundColor="white" p={3}>
          <HStack flex={1} mb={2}>
            <Stack flex={1} direction="row" justifyContent="space-between" alignItems="center">
              <Box>
                <Icon color="muted.400" as={<Box><FaCalendarAlt/></Box>} size={3}></Icon>
              </Box>
              <Box flex={1} ml={1}>
                <Text color="muted.400" fontWeight={500} fontSize="xs">
                  {moment(row.date_action, "YYYY-MM-DD HH:mm:ss").format("DD MMM YYYY HH:mm")}
                </Text>
              </Box>
            </Stack>
            <Stack flex={1} direction="row" justifyContent="space-between" alignItems="center">
              <Box>
                <Icon color="muted.400" as={<Box><FaHourglassEnd/></Box>} size={3}></Icon>
              </Box>
              <Box flex={1} ml={1}>
                <Text color="muted.400" fontWeight={500} fontSize="xs">
                  {row.status ? status_detail[row.status]: status_detail['10']}
                </Text>
              </Box>
            </Stack>
          </HStack>
          <HStack flex={1} mb={2}>
            <Stack flex={1} direction="row" justifyContent="space-between" alignItems="center">
              <Box>
                <Icon color="muted.400" as={<Box><FaBuilding/></Box>} size={3}></Icon>
              </Box>
              <Box flex={1} ml={1}>
                <Text color="muted.400" fontWeight={500} fontSize="xs">
                    {wasteAcceptanceFormScreen.detailTransfer?.ms_unit_tujuan?.nama}
                </Text>
              </Box>
            </Stack>
          </HStack>
            <FormControl mb="1">
              <FormControl.Label>Jenis Limbah</FormControl.Label>
              <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                <Text textAlign="left">{row.ms_jns_limbah?.nama}</Text>
              </Box>
            </FormControl>
            <FormControl mb="1">
              <FormControl.Label>Volume (L)</FormControl.Label>
              <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                <Text textAlign="right">{row.volume}</Text>
              </Box>
            </FormControl>
            <FormControl mb="1">
              <FormControl.Label>Berat (kg)</FormControl.Label>
              <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                <Text textAlign="right">{row.berat}</Text>
              </Box>
            </FormControl>
            <FormControl mb="3">
              <FormControl.Label>Noted</FormControl.Label>
              <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                <Text>{row.noted}</Text>
              </Box>
            </FormControl>
            {!viewOnly &&
              <Button w="full" isLoading={wasteAcceptanceFormScreen.loading} spinnerPlacement="end" isLoadingText="Submitting..." colorScheme="primary" onPress={() => history.push('waste-accept-form-detail', {...location.state, id_detail: row.id_transfer_limbah_detail})}>Update Penerimaan</Button>
            }
            </VStack>
          </AccordionItem>
      ))}
    </>);


  };

  return (
        <ContainerPage options={{statusBar: true, prevPage: 'waste-accept', title: 'Penerimaan TPS', params:{}}}>
          <Flex flex={1} minW="300px">
            <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
              <LoadingOverlay
                active={wasteAcceptanceFormScreen.loading}
                spinner
                text='Loading...'
                >
              <Box p={5} borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor} mx={3} mt={7}>
                <VStack>
                  <Center>
                    <Avatar size="lg" bg="primary.900" mb={4}>
                        {helper.getInitials(wasteAcceptanceFormScreen.detailTransfer.ms_vendor?.nama)}
                    </Avatar>
                  </Center>
                  <Center>
                    <Text color="darkText" isTruncated maxWidth="90%" fontSize="md" fontWeight="bold">
                      {wasteAcceptanceFormScreen.detailTransfer.ms_vendor?.nama}
                    </Text>
                  </Center>
                  <Center>
                    <Text color="muted.400" isTruncated maxWidth="90%" fontSize="sm">
                      {wasteAcceptanceFormScreen.detailTransfer.ms_vendor?.hp}
                    </Text>
                  </Center>
                  <Box mt={2}>
                    <VStack>
                      <Text fontSize="xs" color="muted.600">No Permintaan Unit / Fakultas</Text>
                      <Text color="darkText" bold>
                        {wasteAcceptanceFormScreen.detailTransfer.code}
                      </Text>
                    </VStack>
                  </Box>
                  <Box mt={2}>
                    <VStack>
                      <Text fontSize="xs" color="muted.600">Tanggal Permintaan</Text>
                      <Text color="darkText" bold>
                        {moment(wasteAcceptanceFormScreen.detailTransfer.tgl_transfer, "YYYY-MM-DD HH:mm:ss").format("DD MMMM YYYY")}
                      </Text>
                    </VStack>
                  </Box>
                </VStack>
              </Box>
              <VStack space={7} mt={3} mb={35} mx={3}>
                <Box>
                  <Accordion>
                    {wasteAcceptanceFormScreen.detailTransfer?.transfer_limbah_detail.map((x, i) => renderAccordionDetail(x, i))}
                  {/* {[...Array(3)].map((x, i) => renderAccordionDetail(x, i))} */}
                  </Accordion>
                </Box>
              </VStack>
              </LoadingOverlay>
            </ScrollView>
          </Flex>
        </ContainerPage>
      );
    
}

const mapStateToProps = createStructuredSelector({
  wasteAcceptanceFormScreen: makeSelectWasteAcceptanceFormScreen(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onAddImage: asset => dispatch(addImage(asset)),
    onDelImage: idx => dispatch(delImage(idx)),
    onGetPenerimaan: (id_transfer, id_penerimaan) => dispatch(getPenerimaanDetail(id_transfer, id_penerimaan)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(WasteAcceptanceFormScreen);