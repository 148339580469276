import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// Main CSS
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-multi-carousel/lib/styles.css";
// Main Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";
import 'react-loading-skeleton/dist/skeleton.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
// import {BrowserRouter} from 'react-router-dom';
import history from './util/history';
import { ConnectedRouter } from 'connected-react-router';
import { NativeBaseProvider, extendTheme } from "native-base";
import { Provider } from 'react-redux';
import configureStore from './configureStore';
import { requestForToken } from './firebase';
const initialState = {};
const store = configureStore(initialState, history);

const theme = extendTheme({
  components: {
    Button: {
      // Can simply pass default props to change default behaviour of components.
      baseStyle: {
        rounded: 'md',
        _disabled:{
          opacity: 0.8,
        },
        _loading:{
          opacity: 0.8,
        }
      },
      defaultProps: {
        colorScheme: 'primary',
      }
    }
  },
  colors: {
    // Add new color
    primary: {
      50: '#e6eef5',
      100: '#cdddea',
      200: '#b3cbe0',
      300: '#9abad5',
      400: '#81a9cb',
      500: '#6898c1',
      600: '#4f87b6',
      700: '#3575ac',
      800: '#1c64a1',
      900: '#035397',
    },
    // Redefining only one shade, rest of the color will remain same.
    amber: {
      400: '#FFC50B',
    },
    common: {
      100: '#F9FAFB',
      200: '#959697',
    },
  },
  config: {
    // Changing initialColorMode to 'dark'
    // initialColorMode: 'light',
  },
});

// // check notification
// if (!("Notification" in window)) {
//   // Check if the browser supports notifications
//   alert("This browser does not support desktop notification");
// } else if (Notification.permission === "granted") {
//   requestForToken();
//   // Check whether notification permissions have already been granted;
  
// } else if (Notification.permission !== "denied") {
//   // We need to ask the user for permission
//   Notification.requestPermission().then((permission) => {
//     // If the user accepts, let's create a notification
//     if (permission === "granted") {
//       requestForToken();
//       // const notification = new Notification("Hi there!");
//     }
//   });
// }

requestForToken();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <NativeBaseProvider extendTheme={theme}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <React.StrictMode>
            <App />
        </React.StrictMode>
      </ConnectedRouter>
    </Provider>
  </NativeBaseProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
