import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
 
  import * as constants from './constants';
  import api from '../../util/api';
  import helper from '../../util/helper';
  
  import makeSelectPostAnnounceFormScreen from './selector';
import { getPegawaiFail, getPegawaiSuccess, getPengumumanFail, getPengumumanSuccess, submitPegawaiFail, submitPegawaiSuccess, submitPengumumanFail, submitPengumumanSuccess } from './actions';
import moment from 'moment';
import history from '../../util/history';

  export function* doGetPegumuman(action) {
    const dataset = yield select(makeSelectPostAnnounceFormScreen());
    try {

      const params = {
        id_kirim_pengumuman: action.id_pengumuman
      };

      const response = yield call(api.announce.getRequestDetail, params);
      const {success} = response.data;
  
      yield put(
        getPengumumanSuccess(success),
      );
    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      yield put(getPengumumanFail(err));
    }
  }

  export function* doSubmitPegumuman(action) {
    const dataset = yield select(makeSelectPostAnnounceFormScreen());
    try {

      const tgl_pengumuman = moment(dataset.post_date).format("YYYY-MM-DD HH:mm:ss");
      const tgl_expired = moment(dataset.expired_date).format("YYYY-MM-DD HH:mm:ss");
      let detail_ = [];

      dataset.saved_details.map((raw, i) => {
        detail_.push({
          id_unit: raw.id_unit,
          id_lokasi: raw.id_lokasi,
          noted: raw.noted
        });
      });

      const data_detail = {
        data: detail_,
      }
      
      const params = {
        id: dataset.id_pengumuman,
        tgl_pengumuman: tgl_pengumuman,
        tgl_expired: tgl_expired,
        noted: dataset.notes,
        det_unit_pengumuman: JSON.stringify(detail_)
      };

      if(helper.isEmpty(params.noted)) delete params.noted;

      const response = yield call(api.announce.submitRequest, params);
      const {success} = response.data;
      
      // if(success){
      //   const user = api.getUserData();
      //   const updated_user = {...user, email: dataset.email, hp: dataset.no_hp, user_name: dataset.username}
      //   api.setUserData(updated_user);
      // }

      yield put(
        submitPengumumanSuccess(success),
      );
      helper.successToast("Data berhasil disimpan");

      api.clearItemLocalDB('postAnnounceDetail');
      api.clearItemLocalDB('postAnnounce');
      history.replace('post-announce');

    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      yield put(submitPengumumanFail(err));
    }
  }

  export function* doSaveDetail(action) {
    const {idx_detail} = action;
    const dataset = yield select(makeSelectPostAnnounceFormScreen());
    try {
      const cur_details = dataset.saved_details;
      console.log('saga_cur_details', cur_details)
      api.saveToLocalDB('postAnnounceDetail', [...cur_details]);
  
    } catch (err) {
      console.log(err);
      helper.errorToast("Gagal Menyimpan data, silahkan hub admin");
    }
  }

  export function* doSetEditData(action) {
    const {idx_detail} = action;
    const dataset = yield select(makeSelectPostAnnounceFormScreen());
    try {
      const cur_details = dataset.saved_details;
      console.log('saga_cur_details', cur_details)
      api.saveToLocalDB('postAnnounceDetail', [...cur_details]);
      api.saveToLocalDB('postAnnounce', {
        id_pengumuman: dataset.id_pengumuman,
        expired_date: dataset.expired_date,
        post_date: dataset.post_date,
        notes: dataset.notes,
      });
      // helper.successToast("Data berhasil disimpan");
      // console.log("res:", );
  
    } catch (err) {
      console.log(err);
      helper.errorToast("Gagal Menyimpan data, silahkan hub admin");
    }
  }
  
  // Individual exports for testing
  export default function* postAnnounceFormScreenSaga() {
    yield all([
      takeLatest(constants.GET_PENGUMUMAN, doGetPegumuman),
      takeLatest(constants.SUBMIT_PENGUMUMAN, doSubmitPegumuman),
      takeLatest(constants.DEL_SAVED_DETAIL, doSaveDetail),
      takeLatest(constants.GET_PENGUMUMAN_SUCCESS, doSetEditData),
      takeLatest(constants.SET_SAVED_DETAIL, doSetEditData),
    ]);
  }
  