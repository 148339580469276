import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the WasteTransferFormDetailScreen state domain
 */

const selectWasteTransferFormDetailScreenDomain = state =>
  state.wasteTransferFormDetailScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by WasteTransferFormDetailScreen
 */

const makeSelectWasteTransferFormDetailScreen = () =>
  createSelector(
    selectWasteTransferFormDetailScreenDomain,
    substate => substate,
  );

export default makeSelectWasteTransferFormDetailScreen;
export { selectWasteTransferFormDetailScreenDomain };
