import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the UnitRequestFormScreen state domain
 */

const selectUnitRequestFormScreenDomain = state =>
  state.unitRequestFormScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by UnitRequestFormScreen
 */

const makeSelectUnitRequestFormScreen = () =>
  createSelector(
    selectUnitRequestFormScreenDomain,
    substate => substate,
  );

export default makeSelectUnitRequestFormScreen;
export { selectUnitRequestFormScreenDomain };
