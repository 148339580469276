export const DEFAULT_ACTION = 'screen/WasteTransferFormScreen/DEFAULT_ACTION';
export const UPDATE_INPUT = 'screen/WasteTransferFormScreen/UPDATE_INPUT';

export const SUBMIT_PELAPORAN = 'screen/WasteTransferFormScreen/SUBMIT_PELAPORAN';
export const SUBMIT_PELAPORAN_LOADING = 'screen/WasteTransferFormScreen/SUBMIT_PELAPORAN_LOADING';
export const SUBMIT_PELAPORAN_SUCCESS = 'screen/WasteTransferFormScreen/SUBMIT_PELAPORAN_SUCCESS';
export const SUBMIT_PELAPORAN_FAIL = 'screen/WasteTransferFormScreen/SUBMIT_PELAPORAN_FAIL';

export const ADD_IMAGE = 'screen/WasteTransferFormScreen/ADD_IMAGE';
export const DEL_IMAGE = 'screen/WasteTransferFormScreen/DEL_IMAGE';

export const GET_TRANSFER_DETAIL = 'screen/WasteTransferFormScreen/GET_TRANSFER_DETAIL';
export const GET_TRANSFER_DETAIL_LOADING = 'screen/WasteTransferFormScreen/GET_TRANSFER_DETAIL_LOADING';
export const GET_TRANSFER_DETAIL_SUCCESS = 'screen/WasteTransferFormScreen/GET_TRANSFER_DETAIL_SUCCESS';
export const GET_TRANSFER_DETAIL_FAIL = 'screen/WasteTransferFormScreen/GET_TRANSFER_FAIL';