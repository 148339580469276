export const DEFAULT_ACTION = 'screen/WasteTransferFormDetailScreen/DEFAULT_ACTION';
export const UPDATE_INPUT = 'screen/WasteTransferFormDetailScreen/UPDATE_INPUT';

export const GET_DETAIL = 'screen/WasteTransferFormDetailScreen/GET_DETAIL';
export const GET_DETAIL_LOADING = 'screen/WasteTransferFormDetailScreen/GET_DETAIL_LOADING';
export const GET_DETAIL_SUCCESS = 'screen/WasteTransferFormDetailScreen/GET_DETAIL_SUCCESS';
export const GET_DETAIL_FAIL = 'screen/WasteTransferFormDetailScreen/GET_DETAIL_FAIL';

export const ADD_IMAGE = 'screen/WasteTransferFormDetailScreen/ADD_IMAGE';
export const DEL_IMAGE = 'screen/WasteTransferFormDetailScreen/DEL_IMAGE';

export const SUBMIT_TRANSFER = 'screen/WasteTransferFormDetailScreen/SUBMIT_TRANSFER';
export const SUBMIT_TRANSFER_LOADING = 'screen/WasteTransferFormDetailScreen/SUBMIT_TRANSFER_LOADING';
export const SUBMIT_TRANSFER_SUCCESS = 'screen/WasteTransferFormDetailScreen/SUBMIT_TRANSFER_SUCCESS';
export const SUBMIT_TRANSFER_FAIL = 'screen/WasteTransferFormDetailScreen/SUBMIT_TRANSFER_FAIL';