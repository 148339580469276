export const DEFAULT_ACTION = 'screen/UnitAnnounceFormScreen/DEFAULT_ACTION';
export const UPDATE_INPUT = 'screen/UnitAnnounceFormScreen/UPDATE_INPUT';

export const SUBMIT_UNIT_ANNOUNCE = 'screen/UnitAnnounceFormScreen/SUBMIT_UNIT_ANNOUNCE';
export const SUBMIT_UNIT_ANNOUNCE_LOADING = 'screen/UnitAnnounceFormScreen/SUBMIT_UNIT_ANNOUNCE_LOADING';
export const SUBMIT_UNIT_ANNOUNCE_SUCCESS = 'screen/UnitAnnounceFormScreen/SUBMIT_UNIT_ANNOUNCE_SUCCESS';
export const SUBMIT_UNIT_ANNOUNCE_FAIL = 'screen/UnitAnnounceFormScreen/SUBMIT_UNIT_ANNOUNCE_FAIL';

export const ADD_IMAGE = 'screen/UnitAnnounceFormScreen/ADD_IMAGE';
export const DEL_IMAGE = 'screen/UnitAnnounceFormScreen/DEL_IMAGE';

export const GET_PENGUMUMAN_DETAIL = 'screen/UnitAnnounceFormScreen/GET_PENGUMUMAN_DETAIL';
export const GET_PENGUMUMAN_DETAIL_LOADING = 'screen/UnitAnnounceFormScreen/GET_PENGUMUMAN_DETAIL_LOADING';
export const GET_PENGUMUMAN_DETAIL_SUCCESS = 'screen/UnitAnnounceFormScreen/GET_PENGUMUMAN_DETAIL_SUCCESS';
export const GET_PENGUMUMAN_DETAIL_FAIL = 'screen/UnitAnnounceFormScreen/GET_PENGUMUMAN_DETAIL_FAIL';

export const DEL_SAVED_DETAIL = 'screen/UnitAnnounceFormScreen/DEL_SAVED_DETAIL';
export const SET_SAVED_DETAIL = 'screen/UnitAnnounceFormScreen/SET_SAVED_DETAIL';