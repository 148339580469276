import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the ManifestDetailScreen state domain
 */

const selectManifestDetailScreenDomain = state =>
  state.manifestDetailScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by ManifestDetailScreen
 */

const makeSelectManifestDetailScreen = () =>
  createSelector(
    selectManifestDetailScreenDomain,
    substate => substate,
  );

export default makeSelectManifestDetailScreen;
export { selectManifestDetailScreenDomain };
