import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function updateInput({ name, value }) {
  return {
    type: constants.UPDATE_INPUT,
    name,
    value
  };
}

export function getDashboard() {
  return {
    type: constants.GET_DASHBOARD,
  };
}

export function getDashboardFail(error) {
  return {
    type: constants.GET_DASHBOARD_FAIL,
    error
  };
}

export function getDashboardSuccess(results) {
  return {
    type: constants.GET_DASHBOARD_SUCCESS,
    results
  };
}