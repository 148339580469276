export const DEFAULT_ACTION = 'screen/ApprovalScreen/DEFAULT_ACTION';
export const UPDATE_INPUT = 'screen/ApprovalScreen/UPDATE_INPUT';

export const GET_LIST_APPROVAL = 'screen/ApprovalScreen/GET_LIST_APPROVAL';
export const GET_LIST_APPROVAL_LOADING = 'screen/ApprovalScreen/GET_LIST_APPROVAL_LOADING';
export const GET_LIST_APPROVAL_SUCCESS = 'screen/ApprovalScreen/GET_LIST_APPROVAL_SUCCESS';
export const GET_LIST_APPROVAL_FAIL = 'screen/ApprovalScreen/GET_LIST_APPROVAL_FAIL';

export const GET_MORE_LIST_APPROVAL = 'screen/ApprovalScreen/GET_MORE_LIST_APPROVAL';
export const GET_MORE_LIST_APPROVAL_LOADING = 'screen/ApprovalScreen/GET_MORE_LIST_APPROVAL_LOADING';
export const GET_MORE_LIST_APPROVAL_SUCCESS = 'screen/ApprovalScreen/GET_MORE_LIST_APPROVAL_SUCCESS';
export const GET_MORE_LIST_APPROVAL_FAIL = 'screen/ApprovalScreen/GET_MORE_LIST_APPROVAL_FAIL';

export const GET_TOTAL_APPROVAL = 'screen/ApprovalScreen/GET_TOTAL_APPROVAL';
export const GET_TOTAL_APPROVAL_LOADING = 'screen/ApprovalScreen/GET_TOTAL_APPROVAL_LOADING';
export const GET_TOTAL_APPROVAL_SUCCESS = 'screen/ApprovalScreen/GET_TOTAL_APPROVAL_SUCCESS';
export const GET_TOTAL_APPROVAL_FAIL = 'screen/ApprovalScreen/GET_TOTAL_APPROVAL_FAIL';

export const SUBMIT_APPROVAL = 'screen/ApprovalScreen/SUBMIT_APPROVAL';
export const SUBMIT_APPROVAL_LOADING = 'screen/ApprovalScreen/SUBMIT_APPROVAL_LOADING';
export const SUBMIT_APPROVAL_SUCCESS = 'screen/ApprovalScreen/SUBMIT_APPROVAL_SUCCESS';
export const SUBMIT_APPROVAL_FAIL = 'screen/ApprovalScreen/SUBMIT_APPROVAL_FAIL';

export const GET_APPROVAL_DETAIL = 'screen/ApprovalScreen/GET_APPROVAL_DETAIL';
export const GET_APPROVAL_DETAIL_LOADING = 'screen/ApprovalScreen/GET_APPROVAL_DETAIL_LOADING';
export const GET_APPROVAL_DETAIL_SUCCESS = 'screen/ApprovalScreen/GET_APPROVAL_DETAIL_SUCCESS';
export const GET_APPROVAL_DETAIL_FAIL = 'screen/ApprovalScreen/GET_APPROVAL_DETAIL_FAIL';