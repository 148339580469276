import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function updateInput({ name, value }) {
  return {
    type: constants.UPDATE_INPUT,
    name,
    value
  };
}

export function getDetailPenerimaan(id_transfer, id_penerimaan) {
  return {
    type: constants.GET_DETAIL,
    id_transfer,
    id_penerimaan
  };
}

export function getDetailPenerimaanLoading() {
  return {
    type: constants.GET_DETAIL_LOADING
  };
}

export function getDetailPenerimaanFail(error) {
  return {
    type: constants.GET_DETAIL_FAIL,
    error
  };
}

export function getDetailPenerimaanSuccess(results) {
  return {
    type: constants.GET_DETAIL_SUCCESS,
    results
  };
}

export function addImage(asset) {
  return {
    type: constants.ADD_IMAGE,
    asset
  };
}

export function delImage(idx) {
  return {
    type: constants.DEL_IMAGE,
    idx
  };
}

export function submitPenerimaan() {
  return {
    type: constants.SUBMIT_PENERIMAAN,
  };
}

export function submitPenerimaanFail(error) {
  return {
    type: constants.SUBMIT_PENERIMAAN_FAIL,
    error
  };
}

export function submitPenerimaanSuccess(results) {
  return {
    type: constants.SUBMIT_PENERIMAAN_SUCCESS,
    results
  };
}