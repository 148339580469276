import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function updateInput({ name, value }) {
  return {
    type: constants.UPDATE_INPUT,
    name,
    value
  };
}

export function getPegawai() {
  return {
    type: constants.GET_PEGAWAI
  };
}

export function getPegawaiFail(error) {
  return {
    type: constants.GET_PEGAWAI_FAIL,
    error
  };
}

export function getPegawaiSuccess(results) {
  // console.log("peg:", results);
  return {
    type: constants.GET_PEGAWAI_SUCCESS,
    results
  };
}