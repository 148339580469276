import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
import api from '../../util/api';
import helper from '../../util/helper';
import history from '../../util/history';
// import * as RootNavigation from '../../../RootNavigation';
import { getPelaporanFail, getPelaporanSuccess, getPengambilanUnitDetailFail, getPengambilanUnitDetailSuccess, submitPelaporanFail, submitPelaporanSuccess, submitPengambilanUnitFail, submitPengambilanUnitSuccess } from './actions';

  import * as constants from './constants';
  
  import makeSelectWastePickupFormScreen from './selector';
  
  export function* doSubmitPengambilanUnit(action) {
    const {id_branch, origin_screen} = action;
    const dataset = yield select(makeSelectWastePickupFormScreen());
    try {
      const params = {
          id_pelaporan: dataset.id_pelaporan,
          id_ruang: dataset.id_item,
          id_inventaris: dataset.id_aset,
          description: dataset.description,
          notes: dataset.notes,
          attachment: dataset.assets,
      };

      // console.log(params);

      const response = yield call(api.pelaporan.submitPelaporan, params);
      yield put(
        submitPengambilanUnitSuccess(response.data, dataset.id_aset),
      );
      helper.successToast("Data berhasil disimpan");
      console.log("res:", response.data);

      // save current pelaporan
      // if(origin_screen == 'pelaporan-detail'){
      //   api.saveCurrInputPelaporan(dataset.id_aset, response.data.id_pelaporan);
      //   history.push(origin_screen, {id_branch: id_branch, id_item: dataset.id_item, new_data: false});
      // }else{
      //   history.push(origin_screen);
      // }
      
  
    } catch (err) {
      console.log(err);
      helper.errorToast("Gagal Menyimpan data, silahkan hub admin");
      yield put(submitPengambilanUnitFail(err));
    }
  }

  export function* doGetPengambilanUnit(action) {
    const dataset = yield select(makeSelectWastePickupFormScreen());
    try {
      const params = {
        id_approval: dataset.id_approval,
        id_pengambilan_limbah: dataset.id_pengambilan_limbah,
        jns_approval_code: dataset.jns_approval_code
      };

      const response = yield call(api.waste.getWastePickupDetail, params);
      const {success} = response.data;
  
      yield put(
        getPengambilanUnitDetailSuccess(success.dtAmbilLimbah, success.dtApproval),
      );
    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      yield put(getPengambilanUnitDetailFail(err));
    }
  }

  export function* doSaveDetail(action) {
    const dataset = yield select(makeSelectWastePickupFormScreen());
    try {
      api.saveToLocalDB('wastePickup', {
        no_kendaraan: dataset.no_kendaraan,
        tujuan: dataset.tujuan,
        code_manifest: dataset.code_manifest,
        notes: dataset.notes,
        manifest_name: dataset.manifest_name,
        manifest_file: dataset.manifest_file,
        dtApproval: dataset.dtApproval,
        dtAmbilLimbah: dataset.dtAmbilLimbah,
      });
  
    } catch (err) {
      console.log(err);
      helper.errorToast("Gagal Menyimpan data, silahkan hub admin");
    }
  }

  // Individual exports for testing
  export default function* wastePickupFormScreenSaga() {
    yield all([
      takeLatest(constants.SUBMIT_PENGAMBILAN_UNIT, doSubmitPengambilanUnit),
      takeLatest(constants.GET_PENGAMBILAN_UNIT_DETAIL, doGetPengambilanUnit),
      takeLatest(constants.SAVE_DETAIL, doSaveDetail),
    ]);
  }
  